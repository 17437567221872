import React from 'react';
import { BOOKING_TYPES } from 'constants/constants';
import PropTypes from 'prop-types';
import { InputNumber } from 'antd';

const Duration = ({
  booking,
  bookingType,
  setBookingProp,
  index = null,
  min = 0,
  max = 8,
  disabled = false
}) => {
  const getHours = () => {
    if (bookingType === BOOKING_TYPES.EQUAL_DAILY) {
      return booking.durationEqualDaily.hours;
    }

    if (bookingType === BOOKING_TYPES.TOTAL_OVER_PERIOD) {
      return booking.durationTotalOverPeriod.hours;
    }

    return booking.datesAndDurationsVariableDaily[index].duration.hours;
  };

  const getMinutes = () => {
    if (bookingType === BOOKING_TYPES.EQUAL_DAILY) {
      return booking.durationEqualDaily.minutes;
    }

    if (bookingType === BOOKING_TYPES.TOTAL_OVER_PERIOD) {
      return booking.durationTotalOverPeriod.minutes;
    }

    return booking.datesAndDurationsVariableDaily[index].duration.minutes;
  };

  const handleChangeHours = (value) => {
    if (bookingType === BOOKING_TYPES.EQUAL_DAILY) {
      return setBookingProp('durationEqualDaily', {
        hours: value,
        minutes: booking.durationEqualDaily.minutes
      });
    }

    if (bookingType === BOOKING_TYPES.TOTAL_OVER_PERIOD) {
      return setBookingProp('durationTotalOverPeriod', {
        hours: value,
        minutes: booking.durationTotalOverPeriod.minutes
      });
    }

    return setBookingProp(
      'datesAndDurationsVariableDaily',
      booking.datesAndDurationsVariableDaily.map((data, i) => {
        if (i === index) {
          return {
            date: booking.datesAndDurationsVariableDaily[index].date,
            duration: {
              hours: value,
              minutes:
                booking.datesAndDurationsVariableDaily[index].duration.minutes
            }
          };
        }

        return data;
      })
    );
  };

  const handleChangeMinutes = (value) => {
    if (bookingType === BOOKING_TYPES.EQUAL_DAILY) {
      return setBookingProp('durationEqualDaily', {
        hours: booking.durationEqualDaily.hours,
        minutes: value
      });
    }

    if (bookingType === BOOKING_TYPES.TOTAL_OVER_PERIOD) {
      return setBookingProp('durationTotalOverPeriod', {
        hours: booking.durationTotalOverPeriod.hours,
        minutes: value
      });
    }

    return setBookingProp(
      'datesAndDurationsVariableDaily',
      booking.datesAndDurationsVariableDaily.map((obj, i) => {
        if (i === index) {
          return {
            date: booking.datesAndDurationsVariableDaily[index].date,
            duration: {
              hours:
                booking.datesAndDurationsVariableDaily[index].duration.hours,
              minutes: value
            }
          };
        }

        return obj;
      })
    );
  };

  return (
    <div className="duration">
      <InputNumber
        defaultValue={0}
        min={min}
        max={max}
        value={getHours()}
        formatter={(value) => `${value}h`}
        parser={(value) => value.replace('h', '')}
        onChange={handleChangeHours}
        disabled={disabled || booking.bookingType !== bookingType}
      />

      <InputNumber
        defaultValue={0}
        min={0}
        max={59}
        value={getMinutes()}
        formatter={(value) => `${value}m`}
        parser={(value) => value.replace('m', '')}
        onChange={handleChangeMinutes}
        disabled={disabled || booking.bookingType !== bookingType}
      />
    </div>
  );
};

Duration.propTypes = {
  booking: PropTypes.shape({
    selectedResources: PropTypes.instanceOf(Array),
    selectedProject: PropTypes.number,
    date: PropTypes.oneOfType([
      PropTypes.shape({}),
      PropTypes.instanceOf(Array)
    ]),
    description: PropTypes.string,
    durationEqualDaily: PropTypes.shape({
      hours: PropTypes.number,
      minutes: PropTypes.number
    }),
    durationTotalOverPeriod: PropTypes.shape({
      hours: PropTypes.number,
      minutes: PropTypes.number
    }),
    datesAndDurationsVariableDaily: PropTypes.arrayOf(
      PropTypes.oneOfType([
        PropTypes.number,
        PropTypes.shape({
          date: PropTypes.oneOfType([PropTypes.shape({}), PropTypes.string]),
          duration: PropTypes.shape({
            hours: PropTypes.number,
            minutes: PropTypes.number
          }),
          map: PropTypes.func
        })
      ])
    ),
    bookingType: PropTypes.string.isRequired,
    days: PropTypes.instanceOf(Array)
  }).isRequired,
  bookingType: PropTypes.string.isRequired,
  index: PropTypes.number,
  setBookingProp: PropTypes.func.isRequired,
  min: PropTypes.number,
  max: PropTypes.number,
  disabled: PropTypes.bool
};

Duration.defaultProps = {
  index: null,
  min: 0,
  max: 8,
  disabled: false
};

export default Duration;
