import { useQuery } from '@tanstack/react-query';
import { useApi } from './useApi';

export const useProjectsApi = () => {
  const { getProjects, getProjectTypes } = useApi();

  const projectsQuery = useQuery({
    queryKey: ['projects'],
    queryFn: getProjects,
    keepPreviousData: true
  });

  const projectTypesQuery = useQuery({
    queryKey: ['projectTypes'],
    queryFn: getProjectTypes,
    keepPreviousData: true
  });

  return {
    projectsQuery,
    projectTypesQuery
  };
};
