import { combineReducers } from 'redux';
import userReducer from './user/userReducer';
import filterReducer from './filter/filterReducer';
import reservationReducer from './reservation/reservationReducer';
import modalReducer from './modal/modalReducer';

const rootReducer = combineReducers({
  user: userReducer,
  filterReducer,
  reservation: reservationReducer,
  modals: modalReducer
});

export default rootReducer;
