const initialState = {
  isAuthenticating: false,
  isAuthenticated: false,
  hasAuthenticatingError: false,
  errorMessage: '',
  user: null,
  userId: null,
  userData: null,
  isLoading: false,
  hasLoadingError: false,
  activeView: 'resource'
};

export default initialState;
