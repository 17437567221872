import React from 'react';
import PropTypes from 'prop-types';
import { Modal } from 'antd';
import 'antd/dist/antd.min.css';
import './modal.scss';

const ModalComponent = ({
  children,
  visible,
  title,
  afterClose,
  className,
  close,
  closable
}) => (
  <div>
    <Modal
      title={title}
      visible={visible}
      className={className}
      closable={closable}
      afterClose={afterClose}
      onCancel={() => {
        close();
      }}
      footer={null}
    >
      {children}
    </Modal>
  </div>
);

ModalComponent.propTypes = {
  children: PropTypes.node.isRequired,
  visible: PropTypes.bool,
  title: PropTypes.string.isRequired,
  close: PropTypes.func.isRequired,
  closable: PropTypes.bool,
  afterClose: PropTypes.func,
  className: PropTypes.string
};

ModalComponent.defaultProps = {
  className: '',
  closable: false,
  visible: false,
  afterClose: () => {}
};

export default ModalComponent;
