/* eslint-disable consistent-return */
import axios from 'axios';
import { host } from 'services/config';
import moment from 'moment';
import { useRecoilValue } from 'recoil';
import { toast } from 'react-toastify';
import { DATE_FORMATS } from '../../constants/constants';
import { accessTokenState } from '../../store/atoms';

export const useApi = () => {
  const accessToken = useRecoilValue(accessTokenState);
  const api = axios.create({
    baseURL: host,
    headers: {
      'Content-Type': 'application/json; charset=UTF-8',
      Accept: 'application/json'
    }
  });

  api.defaults.headers.Authorization = `Bearer ${accessToken}`;

  const getProjectsTableData = async ({
    from,
    selectedWeekView,
    emptyProjects,
    resourceGroups,
    projectTypes,
    projects
  }) => {
    try {
      const response = await api.get('/reservation/project-view', {
        params: {
          date_from: moment(from).format(DATE_FORMATS.DEFAULT),
          date_to: moment(from)
            .add(selectedWeekView * 6, 'd')
            .format(DATE_FORMATS.DEFAULT),
          projects: projects.join(','),
          groups: resourceGroups.join(','),

          project_types: projectTypes.join(','),
          empty_projects: emptyProjects.toString()
        }
      });
      return response.data.data;
    } catch (err) {
      if (err.response.status === 401) {
        toast.error('JWT Token expired. Please refresh the page.');
        return;
      }
      toast.error(`${err.response.status} - ${err.response.statusText}`);
    }
  };

  const getProjectTypes = async () => {
    try {
      const response = await api.get('/projects/types');
      return response.data.data;
    } catch (err) {
      if (err.response.status === 401) {
        toast.error('JWT Token expired. Please refresh the page.');
        return;
      }
      toast.error(`${err.response.status} - ${err.response.statusText}`);
    }
  };

  const patchReservation = async ({ reservationId, updatedFields: data }) => {
    try {
      return await api.patch(`/reservation/${reservationId}`, {
        data
      });
    } catch (err) {
      if (err.response.status === 401) {
        toast.error('JWT Token expired. Please refresh the page.');
        return;
      }
      toast.error(`${err.response.status} - ${err.response.statusText}`);
    }
  };

  const createReservation = async ({ data }) => {
    try {
      return await api.post('/reservation', {
        data
      });
    } catch (err) {
      if (err.response.status === 401) {
        toast.error('JWT Token expired. Please refresh the page.');
        return;
      }
      toast.error(`${err.response.status} - ${err.response.statusText}`);
    }
  };
  const getProjects = async () => {
    try {
      const response = await api.get('/projects/hierarchy');
      return response.data.data;
    } catch (err) {
      if (err.response.status === 401) {
        toast.error('JWT Token expired. Please refresh the page.');
        return;
      }
      toast.error(`${err.response.status} - ${err.response.statusText}`);
    }
  };

  const getResourceGroups = async () => {
    try {
      const response = await api.get('/resource-groups');
      return response.data.data.items;
    } catch (err) {
      if (err.response.status === 401) {
        toast.error('JWT Token expired. Please refresh the page.');
        return;
      }
      toast.error(`${err.response.status} - ${err.response.statusText}`);
    }
  };
  const getResources = async () => {
    try {
      const response = await api.get('/resource-tree');
      return response.data.data.map((e) => e.items).flat();
    } catch (err) {
      if (err.response.status === 401) {
        toast.error('JWT Token expired. Please refresh the page.');
        return;
      }
      toast.error(`${err.response.status} - ${err.response.statusText}`);
    }
  };

  const deleteReservation = (id) => api.delete(`/reservations/${id}`);

  const getNationalHolidays = async ({ from, selectedWeekView }) => {
    try {
      const response = await api.get('/national-holidays', {
        params: {
          date_from: from.format(DATE_FORMATS.DEFAULT),
          date_to: moment(from)
            .add(selectedWeekView * 6, 'd')
            .format(DATE_FORMATS.DEFAULT)
        }
      });
      return response.data.data;
    } catch (err) {
      if (err.response.status === 401) {
        toast.error('JWT Token expired. Please refresh the page.');
        return;
      }
      toast.error(`${err.response.status} - ${err.response.statusText}`);
    }
  };

  const getTickets = async ({ projectId, searchKey }) => {
    try {
      return await api.get(`/issues/${projectId}/${searchKey}`);
    } catch (err) {
      if (err.response.status === 401) {
        toast.error('JWT Token expired. Please refresh the page.');
        return;
      }
      toast.error(`${err.response.status} - ${err.response.statusText}`);
    }
  };

  return {
    patchReservation,
    deleteReservation,
    createReservation,
    getProjectsTableData,
    getProjectTypes,
    getProjects,
    getResourceGroups,
    getResources,
    getNationalHolidays,
    getTickets
  };
};
