import {
  USER_LOGIN_R,
  USER_LOGOUT_R,
  RESTORE_USER_BY_TOKEN_R,
  USER_COGNITO_LOGIN_R,
  CHANGE_VIEW,
  MODAL_CHANGE
} from 'constants/actionTypes';

export const changeModal = (modalName, data) => ({
  type: MODAL_CHANGE,
  payload: {
    modalName,
    data
  }
});

export const login = (code) => ({
  type: USER_LOGIN_R,
  payload: {
    code
  }
});

export const cognitoLogin = () => ({
  type: USER_COGNITO_LOGIN_R
});

export const logout = () => ({
  type: USER_LOGOUT_R
});

export const restoreUserByToken = (next) => ({
  type: RESTORE_USER_BY_TOKEN_R,
  payload: {
    next
  }
});

export const changeView = (view) => ({
  type: CHANGE_VIEW,
  payload: {
    view
  }
});
