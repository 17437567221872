import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { DatePicker as AntDDatePicker } from 'antd';
import cc from 'classcat';
import 'antd/dist/antd.min.css';
import './datepicker.scss';

const { RangePicker } = AntDDatePicker;

const DatePicker = ({
  value,
  placeholder,
  className,
  dropdownClassName,
  allowClear,
  onChange,
  disabled
}) => {
  const [open, setOpen] = useState(false);

  const toggle = () => {
    setOpen((prevState) => !prevState);
  };

  return (
    <RangePicker
      disabled={disabled}
      onChange={onChange}
      value={value}
      placeholder={[placeholder]}
      className={cc({
        'main-class': true,
        [className]: true
      })}
      dropdownClassName={cc({
        [dropdownClassName]: true,
        open,
        'dropdown-class': true
      })}
      allowClear={allowClear}
      onOpenChange={toggle}
    />
  );
};

DatePicker.propTypes = {
  onChange: PropTypes.func.isRequired,
  placeholder: PropTypes.string,
  className: PropTypes.string,
  dropdownClassName: PropTypes.string,
  allowClear: PropTypes.bool,
  value: PropTypes.arrayOf(PropTypes.shape({})),
  disabled: PropTypes.bool
};

DatePicker.defaultProps = {
  className: '',
  dropdownClassName: '',
  placeholder: 'Choose date',
  allowClear: true,
  value: undefined,
  disabled: false
};

export default DatePicker;
