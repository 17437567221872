import {
  USER_LOGIN_R,
  USER_LOGIN_S,
  USER_LOGIN_F,
  USER_LOGOUT_S,
  RESTORE_USER_BY_TOKEN_S,
  RESTORE_USER_BY_TOKEN_R,
  USER_COGNITO_LOGIN_R,
  CHANGE_VIEW
} from 'constants/actionTypes';
import initialState from 'store/reducers/user/initialState';

const userReducer = (state = initialState, action) => {
  switch (action.type) {
    case USER_LOGIN_R:
      return {
        ...state,
        isAuthenticating: true
      };

    case USER_COGNITO_LOGIN_R:
      return {
        ...state,
        isAuthenticating: true
      };

    case USER_LOGIN_S: {
      return {
        ...state,
        isAuthenticating: true,
        isAuthenticated: false,
        hasAuthenticatingError: false,
        errorMessage: ''
      };
    }

    case USER_LOGIN_F:
      return {
        ...state,
        isAuthenticating: false,
        hasAuthenticatingError: true,
        errorMessage: action.payload
      };

    case USER_LOGOUT_S:
      return initialState;

    case RESTORE_USER_BY_TOKEN_R: {
      return {
        ...state,
        isAuthenticating: true,
        isAuthenticated: false
      };
    }

    case RESTORE_USER_BY_TOKEN_S: {
      return {
        ...state,
        isAuthenticating: false,
        isAuthenticated: true,
        token: action.payload.token,
        user: action.payload.user,
        userId: action.payload.userId,
        next: action.payload.next
      };
    }

    case CHANGE_VIEW: {
      const { view } = action.payload;
      return {
        ...state,
        activeView: view
      };
    }

    default:
      return state;
  }
};

export default userReducer;
