// USER ACTIONS
export const USER_LOGIN_R = 'USER_LOGIN_R';
export const USER_LOGIN_S = 'USER_LOGIN_S';
export const USER_LOGIN_F = 'USER_LOGIN_F';

export const RESTORE_USER_BY_TOKEN_R = 'RESTORE_USER_BY_TOKEN_R';
export const RESTORE_USER_BY_TOKEN_S = 'RESTORE_USER_BY_TOKEN_S';
export const RESTORE_USER_BY_TOKEN_F = 'RESTORE_USER_BY_TOKEN_F';

export const USER_LOGOUT_R = 'USER_LOGOUT_R';
export const USER_LOGOUT_S = 'USER_LOGOUT_S';
export const USER_LOGOUT_F = 'USER_LOGOUT_S';
export const USER_COGNITO_LOGIN_R = 'USER_COGNITO_LOGIN_R';

// FILTER ACTIONS
export const FILTER_SET_CHECKBOX_VALUE = 'FILTER_SET_CHECKBOX_VALUE';
export const FILTER_SET_SELECTED_DIVISIONS = 'FILTER_SET_SELECTED_DIVISIONS';
export const FILTER_SET_SELECTED_RESOURCES = 'FILTER_SET_SELECTED_RESOURCES';
export const FILTER_SET_SELECTED_MANAGERS = 'FILTER_SET_SELECTED_MANAGERS';
export const FILTER_SET_SELECTED_PROJECTS = 'FILTER_SET_SELECTED_PROJECTS';
export const FILTER_REMOVE_SELECTED_PROJECTS =
  'FILTER_REMOVE_SELECTED_PROJECTS';
export const FILTER_SET_WEEK_VIEW = 'FILTER_SET_WEEK_VIEW';
export const FILTER_SET_SELECTED_PERIOD = 'FILTER_SET_SELECTED_PERIOD';
export const CHANGE_VIEW = 'CHANGE_VIEW';

// PROJECT ACTIONS
export const PROJECT_HIERACRCHY_R = 'PROJECT_HIERACRCHY_R';
export const PROJECT_HIERACRCHY_S = 'PROJECT_HIERACRCHY_S';
export const PROJECT_HIERACRCHY_F = 'PROJECT_HIERACRCHY_F';

// RESOURCE ACTIONS
export const RESOURCE_GROUPS_R = 'RESOURCE_GROUPS_R';
export const RESOURCE_GROUPS_S = 'RESOURCE_GROUPS_S';
export const RESOURCE_GROUPS_F = 'RESOURCE_GROUPS_F';
export const RESOURCE_TREE_R = 'RESOURCE_TREE_R';
export const RESOURCE_TREE_S = 'RESOURCE_TREE_S';
export const RESOURCE_TREE_F = 'RESOURCE_TREE_F';

// RESERVATION ACTIONS
export const LIST_RESERVATIONS_R = 'LIST_RESERVATIONS_R';
export const LIST_RESERVATIONS_S = 'LIST_RESERVATIONS_S';
export const LIST_RESERVATIONS_F = 'LIST_RESERVATIONS_F';

export const CREATE_RESERVATION_R = 'CREATE_RESERVATION_R';
export const CREATE_RESERVATION_S = 'CREATE_RESERVATION_S';
export const CREATE_RESERVATION_F = 'CREATE_RESERVATION_F';

export const UPDATE_RESERVATION_R = 'UPDATE_RESERVATION_R';
export const UPDATE_RESERVATION_S = 'UPDATE_RESERVATION_S';
export const UPDATE_RESERVATION_F = 'UPDATE_RESERVATION_F';

export const SPLIT_RESERVATION_R = 'SPLIT_RESERVATION_R';
export const SPLIT_RESERVATION_S = 'SPLIT_RESERVATION_S';
export const SPLIT_RESERVATION_F = 'SPLIT_RESERVATION_F';

export const DELETE_RESERVATION_R = 'DELETE_RESERVATION_R';
export const DELETE_RESERVATION_S = 'DELETE_RESERVATION_S';
export const DELETE_RESERVATION_F = 'DELETE_RESERVATION_F';

export const LIST_FILTERED_RESERVATIONS_R = 'LIST_FILTERED_RESERVATIONS_R';

export const RESERVATION_DIVISION_VISIBILITY_CHANGE =
  'RESERVATION/DIVISION_VISIBILITY_CHANGE';

// MODAL ACTIONS
export const MODAL_CHANGE = 'MODAL/MODAL_CHANGE';
