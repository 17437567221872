import {
  FILTER_REMOVE_SELECTED_PROJECTS,
  FILTER_SET_CHECKBOX_VALUE,
  FILTER_SET_SELECTED_DIVISIONS,
  FILTER_SET_SELECTED_MANAGERS,
  FILTER_SET_SELECTED_PERIOD,
  FILTER_SET_SELECTED_PROJECTS,
  FILTER_SET_SELECTED_RESOURCES,
  FILTER_SET_WEEK_VIEW
} from 'constants/actionTypes';

export const setCheckBoxValue = (type) => ({
  type: FILTER_SET_CHECKBOX_VALUE,
  payload: {
    type
  }
});

export const setSelectedDivisions = (incomingDivision) => ({
  type: FILTER_SET_SELECTED_DIVISIONS,
  payload: {
    incomingDivision
  }
});

export const setSelectedResources = (incomingResource) => ({
  type: FILTER_SET_SELECTED_RESOURCES,
  payload: {
    incomingResource
  }
});

export const setSelectedManagers = (incomingManager) => ({
  type: FILTER_SET_SELECTED_MANAGERS,
  payload: {
    incomingManager
  }
});

export const setSelectedProjects = (changedProject) => ({
  type: FILTER_SET_SELECTED_PROJECTS,
  payload: {
    changedProject
  }
});

export const removeSelectedProjects = (removableProject) => ({
  type: FILTER_REMOVE_SELECTED_PROJECTS,
  payload: {
    removableProject
  }
});

export const setSelectedPeriod = (period) => ({
  type: FILTER_SET_SELECTED_PERIOD,
  payload: {
    period
  }
});

export const setWeekView = (weekNumber) => ({
  type: FILTER_SET_WEEK_VIEW,
  payload: {
    weekNumber
  }
});
