import { takeEvery, call, put } from 'redux-saga/effects';
import {
  USER_LOGIN_R,
  USER_LOGIN_S,
  USER_LOGIN_F,
  USER_LOGOUT_R,
  USER_LOGOUT_S,
  RESTORE_USER_BY_TOKEN_R,
  RESTORE_USER_BY_TOKEN_S,
  RESTORE_USER_BY_TOKEN_F
} from 'constants/actionTypes';
import * as config from 'services/config';
import * as API from 'services/api';
import { ROUTER_PATHS } from 'routes/routerConstants';
import { LOCAL_STORAGE_ITEMS } from 'constants/constants';

export function* login(action) {
  try {
    const { code } = action.payload;

    const data = {
      code
    };

    const token = yield call(
      API.postLoginData,
      `${config.host}/user/login/cognito/callback`,
      data
    );

    localStorage.setItem(LOCAL_STORAGE_ITEMS.ACCESS_TOKEN, token.access_token);

    yield put({
      type: USER_LOGIN_S
    });

    yield put({
      type: RESTORE_USER_BY_TOKEN_R
    });
  } catch (error) {
    console.error('Failed to login', error);

    yield put({
      type: USER_LOGIN_F,
      payload: {
        errorMessage: error.message
      }
    });

    config.history.push(ROUTER_PATHS.LOGIN);
  }
}

export function* logout() {
  localStorage.removeItem(LOCAL_STORAGE_ITEMS.ACCESS_TOKEN);

  yield put({
    type: USER_LOGOUT_S
  });

  config.history.push(ROUTER_PATHS.LOGIN);
}

export function* restoreUserByToken(action) {
  try {
    const token = localStorage.getItem(LOCAL_STORAGE_ITEMS.ACCESS_TOKEN);
    const user = yield call(API.getData, `${config.host}/user/me`);
    if (user) {
      const next = action.payload
        ? action.payload.next
        : ROUTER_PATHS.RESOURCES;
      yield put({
        type: RESTORE_USER_BY_TOKEN_S,
        payload: {
          token,
          user,
          next
        }
      });

      config.history.push(`${next}`);
    }
  } catch (error) {
    console.error('Failed to restore user by token', error);

    yield put({
      type: RESTORE_USER_BY_TOKEN_F
    });
  }
}

export const userSagas = [
  takeEvery(USER_LOGIN_R, login),
  takeEvery(RESTORE_USER_BY_TOKEN_R, restoreUserByToken),
  takeEvery(USER_LOGOUT_R, logout)
];
