import * as consts from 'services/config';
import * as API from 'services/api';
import store from 'store/store';
import { logout } from 'actions/userActions';
import { LOCAL_STORAGE_ITEMS } from 'constants/constants';

let isRefreshingAuthToken = false;
let failedRequestQueue = [];

export const requestInterceptor = {
  setupInterceptors: (api) => {
    api.interceptors.request.use(
      (request) => (request.url.includes('/user/null') ? null : request),
      (error) => console.error(error)
    );
  }
};

export const responseInterceptor = {
  setupInterceptors: (api, setHeader) => {
    api.interceptors.response.use(
      (response) => Promise.resolve(response),
      (error) => {
        const originalRequest = error.config;
        if (error.response) {
          const { status } = error.response;
          if (status === 401 || status === 403) {
            if (isRefreshingAuthToken) {
              return new Promise((resolve, reject) => {
                failedRequestQueue.push({
                  resolve,
                  reject,
                  failedRequest: originalRequest
                });
              })
                .then(({ token, failedRequest }) => {
                  failedRequest.headers.Authorization = `Bearer ${token}`; // eslint-disable-line no-param-reassign
                  return api(failedRequest);
                })
                .catch((err) => Promise.reject(err));
            }

            isRefreshingAuthToken = true;
            if (!originalRequest._retry) {
              originalRequest._retry = true;
              return API.postData(`${consts.host}/user/refresh-token`)
                .then((response) => {
                  const newToken = response.access_token;
                  localStorage.setItem(
                    LOCAL_STORAGE_ITEMS.ACCESS_TOKEN,
                    newToken
                  );
                  setHeader(newToken);
                  originalRequest.headers.Authorization = `Bearer ${newToken}`;
                  isRefreshingAuthToken = false;
                  failedRequestQueue.forEach(({ resolve, failedRequest }) => {
                    resolve({ failedRequest, newToken });
                  });
                  if (originalRequest.data) {
                    const requestWithJsonParsedData = {
                      ...originalRequest,
                      data: JSON.parse(originalRequest.data)
                    };
                    return api(requestWithJsonParsedData);
                  }
                  return api(originalRequest);
                })
                .catch((refreshError) => {
                  isRefreshingAuthToken = false;
                  failedRequestQueue = [];
                  store.dispatch(logout());
                  return Promise.reject(refreshError);
                });
            }
          }
        }
        return Promise.reject(error);
      }
    );
  }
};
