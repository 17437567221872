import cloneDeep from 'lodash/cloneDeep';
import mapValues from 'lodash/mapValues';
import * as TYPES from 'constants/actionTypes';
import initialState from './initialState';

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case TYPES.LIST_RESERVATIONS_S: {
      const { data } = action.payload;
      const divisionVisibilities = mapValues(data, (division) => {
        const isDivisionHasVisibility =
          state.divisionVisibilities[division.group_name] !== undefined;
        return isDivisionHasVisibility
          ? state.divisionVisibilities[division.group_name]
          : true;
      });
      return {
        ...state,
        divisionsOfUsersOfReservations: cloneDeep(data),
        divisionVisibilities
      };
    }
    case TYPES.RESERVATION_DIVISION_VISIBILITY_CHANGE: {
      const { divisionName } = action.payload;
      return {
        ...state,
        divisionVisibilities: {
          ...state.divisionVisibilities,
          [divisionName]: !state.divisionVisibilities[divisionName]
        }
      };
    }

    default:
      return state;
  }
};

export default reducer;
