import moment from 'moment';
import { ProjectsTable } from 'components/ProjectsTable/ProjectsTable';
import { ProjectsFilter } from 'components/ProjectsFilter/ProjectsFilter';
import { useSetRecoilState } from 'recoil';
import { useQueryParams, useQueryParamsEffect } from 'hooks/useQueryParams';
import { LoadingIndicator } from 'components/common/LoadingIndicator/LoadingIndicator';
import { projectsTableFiltersState } from 'store/atoms';
import { toast } from 'react-toastify';
import { useEffect } from 'react';
import { useProjectsTableApi } from '../../hooks/api/useProjectsTableApi';

export const ProjectPage = () => {
  const { projectsTableDataQuery } = useProjectsTableApi();
  const { getArrayQueryParam, getQueryParam } = useQueryParams();

  const setProjectsPageFilters = useSetRecoilState(projectsTableFiltersState);

  const { data, error, status } = projectsTableDataQuery;

  const hasProjects = data?.find((e) => !!e.projects?.length);

  useEffect(() => {
    if (status === 'error') {
      toast.error('Could not get projects table data.');
      console.error(error);
    }
  }, [status]);

  useQueryParamsEffect(() => {
    setProjectsPageFilters({
      emptyProjects: getQueryParam('emptyProjects') || false,
      from: moment(getQueryParam('from') || undefined).startOf('isoWeek'),
      selectedWeekView: Number(getQueryParam('view')) || 1,
      resourceGroups: getArrayQueryParam('resourceGroups') || [],
      projectTypes: getArrayQueryParam('projectTypes') || [],
      projects: getArrayQueryParam('projects')?.map((e) => Number(e)) || []
    });
  }, []);

  return (
    <div>
      <ProjectsFilter />
      {/* eslint-disable-next-line no-nested-ternary */}
      {status === 'pending' ? (
        <div className="text-center block mt-24">
          <LoadingIndicator size="large" />
        </div>
      ) : hasProjects ? (
        <ProjectsTable groups={data} />
      ) : (
        <div className="text-lg font-semibold text-center mt-12">
          No results for the selected filters
        </div>
      )}
    </div>
  );
};
