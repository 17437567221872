import { memo, useState, useLayoutEffect } from 'react';
import PropTypes from 'prop-types';
import { Router } from 'react-router-dom';

export const BrowserRouter = memo((props) => {
  const { history, ...restProps } = props;

  const [state, setState] = useState({
    action: history.action,
    location: history.location
  });

  useLayoutEffect(() => history.listen(setState), [history]);

  return (
    <Router
      {...restProps}
      location={state.location}
      navigationType={state.action}
      navigator={history}
    />
  );
});

BrowserRouter.displayName = 'BrowserRouter';

BrowserRouter.propTypes = {
  history: PropTypes.shape({}).isRequired
};
