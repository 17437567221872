import React from 'react';
import { createRoot } from 'react-dom/client';
import { Root } from 'components/App';
import { history } from 'services/config';
import store from 'store/store';
import './styles/ant.less';
import './index.scss';

const container = document.getElementById('root');
const root = createRoot(container);
root.render(<Root store={store} history={history} />);
