import { useSearchParams } from 'react-router-dom';
import { useLayoutEffect } from 'react';

export const useQueryParams = () => {
  const [queryParams, setQueryParams] = useSearchParams();

  const removeQueryParam = (key) => {
    const newQueryParams = new URLSearchParams(queryParams);
    newQueryParams.delete(key);
    setQueryParams(newQueryParams);
  };

  const setQueryParam = ({ key, value }) => {
    const newQueryParams = new URLSearchParams(queryParams);
    newQueryParams.set(key, value);
    setQueryParams(newQueryParams);
  };

  const getQueryParam = (param) => queryParams.get(param);

  const setArrayQueryParam = ({ key, value }) => {
    if (!value.length) {
      removeQueryParam(key);
      return;
    }
    const stringValue = value.filter((e) => e !== '').join(',');
    const newQueryParams = new URLSearchParams(queryParams);
    newQueryParams.set(key, stringValue);
    setQueryParams(newQueryParams);
  };

  const getArrayQueryParam = (param) =>
    queryParams.get(param)?.split(',') || [];

  return {
    getQueryParam,
    setQueryParam,
    setArrayQueryParam,
    getArrayQueryParam,
    removeQueryParam
  };
};

export const useQueryParamsEffect = (effect, deps) => {
  const [queryParams] = useSearchParams();

  useLayoutEffect(() => {
    effect();
  }, [queryParams, ...deps]);
};
