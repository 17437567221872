import { useEffect } from 'react';

export const useOutsideClick = (ref, callback) => {
  const handleOutsideClick = (event) => {
    const isOutsideClick = ref.current && !ref.current.contains(event.target);
    if (!isOutsideClick) return;
    callback();
  };

  useEffect(() => {
    document.addEventListener('click', handleOutsideClick);

    return () => {
      document.removeEventListener('click', handleOutsideClick);
    };
  }, []);
};
