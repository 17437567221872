import { atom } from 'recoil';

export const accessTokenState = atom({
  key: 'accessToken',
  default: '',
  effects: [
    ({ setSelf, onSet }) => {
      const sessionToken = localStorage.getItem('accessToken');
      if (sessionToken !== null) {
        setSelf(sessionToken);
      }

      onSet((newValue) => {
        localStorage.setItem('session_token', newValue);
      });
    }
  ]
});

export const codeState = atom({
  key: 'code',
  default: undefined
});
