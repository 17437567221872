import { all } from 'redux-saga/effects';
import { userSagas } from './userSaga';
import { resourceSagas } from './resourceSaga';
import { projectSagas } from './projectSaga';
import { reservationSagas } from './reservationSaga';

export default function* rootSaga() {
  yield all([
    ...userSagas,
    ...resourceSagas,
    ...projectSagas,
    ...reservationSagas
  ]);
}
