import { configureStore } from '@reduxjs/toolkit';
import createSagaMiddleware from 'redux-saga';
import rootReducer from './reducers/rootReducer';
import rootSaga from './sagas/rootSaga';
import { loadState, saveState } from './sessionStorage';

const persistedState = loadState();

const configureAppStore = () => {
  const sagaMiddleware = createSagaMiddleware();

  const store = configureStore({
    reducer: rootReducer,
    middleware: (getDefaultMiddleware) =>
      getDefaultMiddleware().concat(sagaMiddleware),
    preloadedState: persistedState
  });

  if (process.env.NODE_ENV !== 'production' && import.meta.webpackHot) {
    import.meta.webpackHot.accept('./reducers/rootReducer', () =>
      store.replaceReducer(rootReducer)
    );
  }

  sagaMiddleware.run(rootSaga);

  return store;
};

const store = configureAppStore();

store.subscribe(() => {
  saveState(store.getState());
});

export default store;
