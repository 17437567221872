import React from 'react';
import PropTypes from 'prop-types';
import { AppHeader } from '../../components/AppHeader/AppHeader';

const AppLayout = ({ children }) => (
  <div className="layout">
    <AppHeader />
    {children}
  </div>
);

AppLayout.propTypes = {
  children: PropTypes.element
};

AppLayout.defaultProps = {
  children: null
};

export default AppLayout;
