import React from 'react';
import PropTypes from 'prop-types';
import DropdownTreeSelect from 'react-dropdown-tree-select';
import cc from 'classcat';
import 'react-dropdown-tree-select/dist/styles.css';
import 'antd/dist/antd.min.css';
import './drop-down-checkbox.scss';

const DropDownCheckboxes = ({
  filterable,
  selectName,
  selectOptions,
  onChange
}) => (
  <div
    className={cc({
      'dropdown-tree-container': true,
      'non-filterable': !filterable
    })}
  >
    <DropdownTreeSelect
      inlineSearchInput
      keepTreeOnSearch
      keepChildrenOnSearch
      clearSearchOnChange
      data={selectOptions}
      texts={{ placeholder: selectName }}
      onChange={onChange}
    />
  </div>
);

DropDownCheckboxes.propTypes = {
  selectOptions: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  onChange: PropTypes.func.isRequired,
  selectName: PropTypes.string.isRequired,
  filterable: PropTypes.bool
};

DropDownCheckboxes.defaultProps = {
  filterable: false
};

export default DropDownCheckboxes;
