import moment from 'moment/moment';

export const getInitialReservationData = ({ reservation }) => {
  return {
    description: reservation.description,
    ends_at: moment(reservation.ends_at),
    project_id: reservation.project_id,
    resource_ids: [reservation.resource?.id || undefined],
    starts_at: moment(reservation.starts_at),
    time_per_day: reservation.time_per_day || 0,
    issue_id: reservation.issue_id || null,
    issue_summary: reservation.issue_summary || null,
    distribution: {
      distribution_base: reservation.distribution_base || 'hours_per_day',
      total_time: reservation.total_time || 0,
      time_per_day: reservation.time_per_day || 0,
      days: reservation.days
        ? reservation.days.map((d) => ({
            day: d.day,
            total_time: d.total_time,
            absence_day: d.absence_day
          }))
        : [{ day: moment(reservation.starts_at), total_time: 0 }]
    }
  };
};

export const isValidDuration = (reservation) =>
  !(reservation.distribution.distribution_base === 'custom'
    ? reservation.distribution.days.find(
        (e) => e.total_time === 0 && !e.absence_day
      )
    : false) &&
  (reservation.distribution.distribution_base === 'total_time'
    ? reservation.distribution.total_time !== 0
    : true) &&
  (reservation.distribution.distribution_base === 'hours_per_day'
    ? reservation.distribution.time_per_day !== 0
    : true);

export const getDistributionKeyValue = (obj) => {
  switch (obj.distribution.distribution_base) {
    case 'custom':
      return { key: 'days', value: obj.distribution.days };

    case 'total_time':
      return { key: 'total_time', value: obj.distribution.total_time };

    case 'hours_per_day':
      return { key: 'time_per_day', value: obj.distribution.time_per_day };

    default:
      throw Error('Unknown distribution base');
  }
};
