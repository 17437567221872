import { Checkbox } from 'antd';
import moment from 'moment/moment';
import { useRecoilValue } from 'recoil';
import { DATE_FORMATS, WEEK_SELECTOR_OPTIONS } from 'constants/constants';
import { projectsTableFiltersState } from 'store/atoms';
import { getDivisionClass } from 'utils';
import { CloseOutlined, UndoOutlined } from '@ant-design/icons';
import { useLocation, useNavigate } from 'react-router-dom';
import { useProjectsApi } from 'hooks/api/useProjectsApi';
import { useResourcesApi } from 'hooks/api/useResourcesApi';
import { useQueryParams } from 'hooks/useQueryParams';
import DropDownCheckBoxes from '../DropDownWithCheckBoxes/DropDown';
import Select from '../Select/Select';
import { WeekStepper } from '../WeekStepper/WeekStepper';
import Tag from '../Tag';

export const ProjectsFilter = () => {
  const {
    getArrayQueryParam,
    setQueryParam,
    setArrayQueryParam,
    removeQueryParam
  } = useQueryParams();
  const { projectsQuery, projectTypesQuery } = useProjectsApi();
  const { resourceGroupsQuery } = useResourcesApi();
  const { data: projects } = projectsQuery;
  const { data: projectTypes } = projectTypesQuery;
  const { data: resourceGroups } = resourceGroupsQuery;
  const location = useLocation();
  const navigate = useNavigate();
  const filters = useRecoilValue(projectsTableFiltersState);

  const hasActiveFilters = location.search !== '';

  const handleFiltersReset = () => {
    navigate('/projects');
  };

  const handleCheckboxValueChange = (filter) => {
    const currentValue = filters[filter];
    if (currentValue) {
      removeQueryParam(filter);
    } else {
      setQueryParam({ key: filter, value: true });
    }
  };

  const handleDropdownValueChange = (filter, value) => {
    const currentValue = getArrayQueryParam(filter);
    const newFilters = currentValue.includes(value.toString())
      ? currentValue.filter((e) => e !== value.toString())
      : [...currentValue, value];
    setArrayQueryParam({ key: filter, value: newFilters });
  };

  const handleWeekViewChange = (value) => {
    setQueryParam({
      key: 'view',
      value
    });
  };

  const handleStartDateChange = (date) => {
    setQueryParam({
      key: 'from',
      value: date.format(DATE_FORMATS.DEFAULT)
    });
  };

  return (
    <div className="p-4 text-gray-400 font-semibold uppercase">
      <p>Filter</p>
      <div className="flex flex-row justify-between">
        <div className="flex flex-col gap-6">
          <div className="flex flex-row flex-wrap gap-6 h-fit">
            <Checkbox
              className="mt-[-2px]"
              checked={filters.emptyProjects}
              onChange={() => handleCheckboxValueChange('emptyProjects')}
            >
              Empty projects
            </Checkbox>
            <DropDownCheckBoxes
              selectName="division"
              onChange={(e) =>
                handleDropdownValueChange('resourceGroups', e.value)
              }
              selectOptions={(resourceGroups || []).map((e) => ({
                label: e.name,
                value: e.name,
                checked: filters.resourceGroups.includes(e.name)
              }))}
            />
            <DropDownCheckBoxes
              selectName="project type"
              onChange={(e) =>
                handleDropdownValueChange('projectTypes', e.value)
              }
              selectOptions={(projectTypes || []).map((e) => ({
                label: e.value,
                value: e.id,
                checked: filters.projectTypes.includes(e.id)
              }))}
            />
            <DropDownCheckBoxes
              selectName="project"
              onChange={(e) => handleDropdownValueChange('projects', e.value)}
              selectOptions={(projects || []).map((e) => ({
                label: e.name,
                value: e.id,
                checked: filters.projects.includes(e.id)
              }))}
            />
          </div>
          <div>
            <div>
              <button
                type="button"
                className={`bg-red-500 text-white font-semibold px-3 py-1 rounded-lg w-fit mb-2 flex justify-center items-center gap-1 ${
                  hasActiveFilters ? '' : 'opacity-60 cursor-not-allowed'
                }`}
                onClick={handleFiltersReset}
                disabled={!hasActiveFilters}
              >
                <UndoOutlined /> Reset filters
              </button>
            </div>
            {filters.resourceGroups.map((r) => (
              <Tag
                key={r}
                title={resourceGroups?.find((e) => e.name === r)?.name}
                closable
                visible
                onClose={() => handleDropdownValueChange('resourceGroups', r)}
                icon={<CloseOutlined />}
              />
            ))}
            {filters.projects.map((p) => (
              <Tag
                key={p}
                title={projects?.find((e) => e.id === p)?.name}
                closable
                visible
                onClose={() => handleDropdownValueChange('projects', p)}
                icon={<CloseOutlined />}
              />
            ))}
            {filters.projectTypes.map((t) => (
              <Tag
                key={t}
                title={projectTypes?.find((e) => e.id === t)?.value}
                closable
                visible
                onClose={() => handleDropdownValueChange('projectTypes', t)}
                icon={<CloseOutlined />}
              />
            ))}
          </div>
        </div>
        <div className="flex flex-col gap-4">
          <div className="flex flex-row gap-4 uppercase font-bold">
            <div>
              <p className="text-gray-400 mb-1">View</p>
              <Select
                dataSource={WEEK_SELECTOR_OPTIONS}
                allowClear={false}
                showSearch={false}
                onChange={(weekNumber) => handleWeekViewChange(weekNumber)}
                width="160px"
                isSmall
                value={filters.selectedWeekView}
              />
            </div>
            <div>
              <p className="text-gray-400 mb-1">Period</p>
              <WeekStepper
                firstDay={filters.from}
                lastDay={moment(filters.from).add(
                  filters.selectedWeekView * 6,
                  'd'
                )}
                weekNumber={filters.selectedWeekView}
                onDateChange={handleStartDateChange}
              />
            </div>
          </div>
          <div className="font-bold uppercase">
            <p className="text-gray-400">Indicators</p>
            <div className="flex flex-row gap-4 mt-1">
              <div>
                <span
                  className={`${
                    getDivisionClass('app development').bg
                  } h-2.5 w-2.5 inline-block rounded-full`}
                />{' '}
                app development
              </div>
              <div>
                <span
                  className={`${
                    getDivisionClass('web development').bg
                  } h-2.5 w-2.5 inline-block rounded-full`}
                />{' '}
                web development
              </div>
              <div>
                <span
                  className={`${
                    getDivisionClass('testing').bg
                  } h-2.5 w-2.5 inline-block rounded-full`}
                />{' '}
                testing
              </div>
            </div>
            <div className="flex flex-row gap-4 mt-2">
              <div>
                <span
                  className={`${
                    getDivisionClass('pm').bg
                  } h-2.5 w-2.5 inline-block rounded-full`}
                />{' '}
                project management
              </div>
              <div>
                <span
                  className={`${
                    getDivisionClass('cloud').bg
                  } h-2.5 w-2.5 inline-block rounded-full`}
                />{' '}
                cloud engineering
              </div>
              <div>
                <span
                  className={`${
                    getDivisionClass('ux/ui').bg
                  } h-2.5 w-2.5 inline-block rounded-full`}
                />{' '}
                ux/ui
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
