import { takeEvery, call, put } from 'redux-saga/effects';
import {
  RESOURCE_GROUPS_R,
  RESOURCE_GROUPS_S,
  RESOURCE_GROUPS_F,
  RESOURCE_TREE_R,
  RESOURCE_TREE_S,
  RESOURCE_TREE_F
} from 'constants/actionTypes';
import * as config from 'services/config';
import * as API from 'services/api';

export function* getResourceGroups() {
  try {
    const {
      data: { items }
    } = yield call(API.getData, `${config.host}/resource-groups`);
    yield put({
      type: RESOURCE_GROUPS_S,
      payload: {
        data: items
      }
    });
  } catch (error) {
    console.error(error);
    yield put({
      type: RESOURCE_GROUPS_F
    });
  }
}

export function* getResourceTree() {
  try {
    const { data } = yield call(API.getData, `${config.host}/resource-tree`);
    yield put({
      type: RESOURCE_TREE_S,
      payload: {
        data
      }
    });
  } catch (error) {
    console.error(error);
    yield put({
      type: RESOURCE_TREE_F
    });
  }
}

export const resourceSagas = [
  takeEvery(RESOURCE_GROUPS_R, getResourceGroups),
  takeEvery(RESOURCE_TREE_R, getResourceTree)
];
