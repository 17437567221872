import moment from 'moment';
import { DATE_FORMATS } from 'constants/constants';

export const findFirstDay = (currentWeekNumber, format = 'YYYY.MM.DD.') =>
  moment(moment().startOf('isoWeek').add(currentWeekNumber, 'week')._d).format(
    format
  );

export const findLastDay = (
  currentFirstDay,
  currentDaysUntilLastDay,
  format = 'YYYY.MM.DD.'
) =>
  moment(currentFirstDay).add(currentDaysUntilLastDay, 'days').format(format);

export const getStartOfPeriod = () =>
  moment().startOf('isoWeek').format(DATE_FORMATS.DEFAULT);

export const getEndOfPeriod = (weekNumber) =>
  moment()
    .startOf('isoWeek')
    .add(weekNumber - 1, 'weeks')
    .add(4, 'days')
    .format(DATE_FORMATS.DEFAULT);

export const isToday = (date) => date.isSame(new Date(), 'day');

export const getHours = (length) => Math.floor(length / 60);

export const getMinutes = (length) => length - getHours(length) * 60;

export const minutesToHoursAndMinutes = (minutes) => {
  const hours = Math.floor(minutes / 60);
  return {
    hours,
    minutes: minutes - hours * 60
  };
};

export const hoursAndMinutesToMinutes = (timeObject) =>
  timeObject.hours * 60 + timeObject.minutes;

export const getDatesOfTimeFrame = (startDate, endDate) => {
  const referenceDate = startDate.clone();
  const dates = [];

  while (referenceDate.isSameOrBefore(endDate)) {
    if (referenceDate.day() !== 0 && referenceDate.day() !== 6) {
      dates.push(referenceDate.format());
    }
    referenceDate.add(1, 'days');
  }

  return dates;
};

export const formatTime = (time) =>
  `${getHours(time)}h ${getMinutes(time) !== 0 ? ` ${getMinutes(time)}m` : ''}`;
export const minutesToDecimalHours = (minutes) => {
  const decimalHours = minutes / 60;

  return parseFloat(decimalHours.toFixed(2));
};

export const minutesToFormattedDecimalHours = (minutes) =>
  `${minutesToDecimalHours(minutes)}h`;
