import React from 'react';
import PropTypes from 'prop-types';
import { Spin } from 'antd';
import { LoadingOutlined } from '@ant-design/icons';
import cc from 'classcat';
import './button.scss';

const antIcon = (size) => (
  <LoadingOutlined style={{ fontSize: size === 'lg' ? 14 : 12 }} spin />
);

const Button = ({
  title,
  disabled,
  onClick,
  minWidth,
  backgroundColor,
  color,
  size,
  icon,
  loading,
  customStyle,
  className
}) => (
  <div
    role="button"
    className={cc({
      'button-wrapper': true,
      [size]: true,
      disabled,
      [className]: !!className
    })}
    tabIndex={0}
    style={{
      minWidth,
      backgroundColor,
      color,
      ...customStyle
    }}
    onClick={disabled ? () => {} : onClick}
  >
    {loading && <Spin indicator={antIcon(size)} size="small" />}
    {icon && <span style={{ paddingRight: 12 }}>{icon}</span>}
    {title}
  </div>
);

Button.propTypes = {
  title: PropTypes.string.isRequired,
  onClick: PropTypes.func,
  disabled: PropTypes.bool,
  loading: PropTypes.bool,
  backgroundColor: PropTypes.string,
  color: PropTypes.string,
  size: PropTypes.string,
  minWidth: PropTypes.number,
  icon: PropTypes.element,
  customStyle: PropTypes.shape({})
};

Button.defaultProps = {
  disabled: false,
  loading: false,
  onClick: () => {},
  backgroundColor: undefined,
  color: undefined,
  minWidth: 56,
  size: 'sm',
  icon: null,
  customStyle: {}
};

export default Button;
