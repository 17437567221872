import React, { Children, useCallback, useEffect, useState } from 'react';
import { Select, DatePicker, Input, Divider } from 'antd';
import { DeleteFilled, PlusCircleFilled } from '@ant-design/icons';
import { isEqual } from 'lodash';
import { DATE_FORMATS, timetableConfig } from 'constants/constants';
import moment from 'moment/moment';
import { LoadingIndicator } from '../common/LoadingIndicator/LoadingIndicator';
import { fillDateRangeWithDaysOf } from '../Timetable/utils';
import {
  getDistributionKeyValue,
  getInitialReservationData,
  isValidDuration
} from './helpers';
import { DistributionEditFields } from './DistributionEditFields';
import Button from '../Button';
import { useProjectsApi } from '../../hooks/api/useProjectsApi';
import { useResourcesApi } from '../../hooks/api/useResourcesApi';
import { useProjectsTableApi } from '../../hooks/api/useProjectsTableApi';
import { TicketSelect } from '../TicketSelect/TicketSelect';

const { RangePicker } = DatePicker;
const { TextArea } = Input;

export const ReservationModal = ({ reservation, onClose, isCreateModal }) => {
  const {
    addReservationMutation,
    updateReservationMutation,
    deleteReservationMutation
  } = useProjectsTableApi();
  const { projectsQuery } = useProjectsApi();
  const { resourcesQuery } = useResourcesApi();
  const { data: projects } = projectsQuery;
  const { data: resources } = resourcesQuery;

  const initialReservationData = getInitialReservationData({
    reservation
  });

  const [formValues, setFormValues] = useState(initialReservationData);
  const [isSubmitting, setIsSubmitting] = useState(false);

  const firstDayOfActualWeek = moment().weekday(1);
  const isFirstDayOfWeekSameOrAfterStartDate = moment(
    firstDayOfActualWeek.format('YYYY-MM-DD')
  ).isSameOrBefore(moment(formValues.starts_at.format('YYYY-MM-DD')));

  const isDirty = !isEqual(initialReservationData, formValues);
  const isValid =
    isFirstDayOfWeekSameOrAfterStartDate &&
    isDirty &&
    (isCreateModal ? true : reservation?.writeable) &&
    !!formValues.resource_ids.filter((e) => e !== undefined).length &&
    !!formValues.project_id &&
    isValidDuration(formValues);

  const closeOnEscKey = useCallback(
    (e) => {
      if (e.key === 'Escape') onClose();
    },
    [onClose]
  );

  const handleDateChange = useCallback((val) => {
    const workdaysOfSelectedPeriod = fillDateRangeWithDaysOf(
      [val[0], val[1]],
      timetableConfig.days
    );

    setFormValues((prev) => ({
      ...prev,
      starts_at: val[0],
      ends_at: val[1],
      distribution: {
        ...prev.distribution,
        days: workdaysOfSelectedPeriod.map((workday) => {
          return {
            day: workday.format(DATE_FORMATS.DEFAULT),
            total_time:
              prev.distribution.days.find(
                (day) => day.day === workday.format(DATE_FORMATS.DEFAULT)
              )?.total_time || 0
          };
        })
      }
    }));
  }, []);

  const handleAddResource = useCallback(
    () =>
      setFormValues((prev) => ({
        ...prev,
        resource_ids: [...prev.resource_ids, undefined]
      })),
    [setFormValues]
  );

  const onSubmit = async () => {
    setIsSubmitting(true);
    try {
      formValues.resource_ids.map(async (id) => {
        const updatedFields = {
          description: formValues.description,
          starts_at: formValues.starts_at.format(DATE_FORMATS.DEFAULT),
          ends_at: formValues.ends_at.format(DATE_FORMATS.DEFAULT),
          project_id: formValues.project_id,
          resource_id: id,
          distribution_base: formValues.distribution.distribution_base,
          issue_id: formValues.issue_id,
          issue_summary: formValues.issue_summary,
          [getDistributionKeyValue(formValues).key]:
            getDistributionKeyValue(formValues).value
        };
        if (isCreateModal) {
          addReservationMutation.mutate({ updatedFields });
          onClose();
          return;
        }
        await updateReservationMutation.mutate({
          reservationId: reservation.id,
          updatedFields
        });
      });
      onClose();
    } catch (err) {
      console.error(err);
    } finally {
      setIsSubmitting(false);
    }
  };

  useEffect(() => {
    document.body.classList.add('overflow-hidden');
    document.addEventListener('keydown', closeOnEscKey);

    return () => {
      document.body.classList.remove('overflow-hidden');
      document.removeEventListener('keydown', closeOnEscKey);
    };
  }, []);

  return (
    <div className="fixed top-0 left-0 h-screen w-screen z-10 overflow-scroll py-12">
      <div
        className="min-h-screen w-screen bg-black/40 z-10 fixed top-0 left-0 overflow-scroll flex flex-col justify-center"
        onClick={onClose}
      />
      <div className="h-fit z-20 relative top-0 left-1/2 -translate-x-1/2 w-fit">
        <div className="bg-white w-[700px] my-12 rounded-lg overflow-hidden text-base">
          <p className="bg-primary/30 font-black text-center py-3">
            Edit booking
          </p>
          <div className="px-28 py-4 flex flex-col gap-3">
            <p className="uppercase text-gray-400/60 font-semibold text-sm">
              Resource
            </p>
            {Children.toArray(
              formValues.resource_ids.map((id, index) => (
                <Select
                  disabled={isCreateModal ? false : !reservation?.writeable}
                  showSearch
                  allowClear
                  placeholder="Select a resource"
                  className="w-full"
                  onChange={(resourceId) =>
                    setFormValues((prev) => {
                      const updatedResources = [...prev.resource_ids];
                      updatedResources[index] = resourceId;
                      return {
                        ...prev,
                        resource_ids: updatedResources
                      };
                    })
                  }
                  filterOption={(input, option) =>
                    (option?.label ?? '')
                      .toLowerCase()
                      .includes(input.toLowerCase())
                  }
                  options={(resources || []).map((e) => ({
                    value: e.id,
                    label: e.name
                  }))}
                  value={
                    resources?.length
                      ? id
                      : {
                          value: 0,
                          label: <LoadingIndicator className="-mt-0.5 block" />
                        }
                  }
                />
              ))
            )}
            {isCreateModal && (
              <Button
                title="ADD MORE RESOURCES"
                className="self-start"
                color="#4a33d9"
                icon={
                  <PlusCircleFilled
                    style={{ color: '#4a33d9', fontSize: 24 }}
                  />
                }
                minWidth={194}
                onClick={handleAddResource}
              />
            )}
            <p className="uppercase text-gray-400/60 font-semibold text-sm">
              Choose project
            </p>
            <Select
              disabled={isCreateModal ? false : !reservation?.writeable}
              showSearch
              allowClear
              placeholder="Select a project"
              className="w-full"
              onChange={(projectId) =>
                setFormValues((prev) => ({ ...prev, project_id: projectId }))
              }
              filterOption={(input, option) =>
                (option?.label ?? '')
                  .toLowerCase()
                  .includes(input.toLowerCase())
              }
              options={(projects || [])
                .filter((val) => val?.status === true)
                .map((e) => ({
                  value: e.id,
                  label: e.name
                }))}
              value={
                projects?.length
                  ? formValues.project_id
                  : {
                      value: 0,
                      label: <LoadingIndicator className="-mt-0.5 block" />
                    }
              }
            />
            <p className="uppercase text-gray-400/60 font-semibold text-sm">
              Choose ticket
            </p>
            <TicketSelect
              disabled={
                !formValues.project_id || isCreateModal
                  ? false
                  : !reservation?.writeable
              }
              projectId={formValues.project_id}
              currentValue={
                formValues.issue_id && formValues.issue_summary
                  ? {
                      value: formValues.issue_id,
                      label: formValues.issue_summary
                    }
                  : undefined
              }
              onSelect={(ticket) =>
                setFormValues((prev) => ({
                  ...prev,
                  issue_id: ticket.value,
                  issue_summary: ticket.label
                }))
              }
            />
            <p className="uppercase text-gray-400/60 font-semibold text-sm">
              Start Date & Due Date
            </p>
            <RangePicker
              allowClear={false}
              onChange={handleDateChange}
              disabled={isCreateModal ? false : !reservation?.writeable}
              value={[formValues.starts_at, formValues.ends_at]}
            />
            <p className="uppercase text-gray-400/60 font-semibold text-sm">
              Description
            </p>
            <TextArea
              disabled={isCreateModal ? false : !reservation?.writeable}
              autoSize={{ minRows: 2, maxRows: 6 }}
              placeholder="Write description"
              value={formValues.description}
              onChange={(e) =>
                setFormValues((prev) => ({
                  ...prev,
                  description: e.target.value
                }))
              }
            />
            <Divider className="my-3" />
            <p className="uppercase text-gray-400/60 font-semibold text-sm">
              Effort
            </p>
            <DistributionEditFields
              distribution={formValues.distribution}
              disabled={isCreateModal ? false : !reservation?.writeable}
              onChange={(distribution) =>
                setFormValues((prev) => ({
                  ...prev,
                  distribution: { ...prev.distribution, ...distribution }
                }))
              }
            />
          </div>
          <Divider className="my-7" />
          <div className="px-28 pb-7 flex flex-row justify-between">
            {!isCreateModal && (
              <button
                disabled={isCreateModal ? false : !reservation.writeable}
                onClick={async () => {
                  if (
                    confirm('Are you sure you want to delete this booking?') ===
                    true
                  ) {
                    onClose();
                    await deleteReservationMutation.mutate(reservation.id);
                  }
                }}
                type="button"
                className={`${
                  reservation.writeable ? '' : 'cursor-not-allowed opacity-50'
                } uppercase flex flex-row gap-2 items-center`}
              >
                <DeleteFilled className="text-red-400" />{' '}
                <span className="font-bold text-gray-500 text-sm">Delete</span>
              </button>
            )}
            <div className="flex flex-row gap-2 text-sm">
              <button
                type="button"
                onClick={onClose}
                className="border border-primary text-primary px-8 py-2 rounded-lg font-bold text-gray-500"
              >
                Cancel
              </button>
              <button
                type="button"
                disabled={!isValid || isSubmitting}
                className={`
                ${isValid ? 'bg-primary' : 'bg-primary/40 cursor-not-allowed'} 
                px-8 py-2 rounded-lg font-bold text-white`}
                onClick={onSubmit}
              >
                {isSubmitting && (
                  <LoadingIndicator className="text-primary-700 mr-1 -mt-1 block" />
                )}
                Save
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
