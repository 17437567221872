import {
  FILTER_REMOVE_SELECTED_PROJECTS,
  FILTER_SET_CHECKBOX_VALUE,
  FILTER_SET_SELECTED_DIVISIONS,
  FILTER_SET_SELECTED_MANAGERS,
  FILTER_SET_SELECTED_PERIOD,
  FILTER_SET_SELECTED_PROJECTS,
  FILTER_SET_SELECTED_RESOURCES,
  FILTER_SET_WEEK_VIEW,
  PROJECT_HIERACRCHY_S,
  RESOURCE_GROUPS_S,
  RESOURCE_TREE_S,
  USER_LOGOUT_S
} from 'constants/actionTypes';
import { getEndOfPeriod } from 'utils';
import initialState from 'store/reducers/filter/initialState';

const createShallowCopy = (itemList, incomingItem) => {
  const copiedItemList = [];
  itemList.forEach((item) => {
    const copiedItem = {
      ...item,
      checked: item.key === incomingItem.key ? !item.checked : item.checked
    };
    copiedItemList.push(copiedItem);
  });
  return copiedItemList;
};

const isItAMatch = (item, incomingList) =>
  incomingList.some((incomingItem) => incomingItem.key === item.key);

const removeProjectCheck = (itemList, removableItem) => {
  const itemsToMatch = Array.isArray(removableItem)
    ? removableItem
    : [removableItem];

  return itemList.map((item) => {
    if (isItAMatch(item, itemsToMatch)) {
      return {
        ...item,
        checked: false,
        children: item.children
          ? removeProjectCheck(item.children, item.children)
          : item.children
      };
    }
    return { ...item };
  });
};

const filterReducer = (state = initialState, action) => {
  switch (action.type) {
    case FILTER_SET_CHECKBOX_VALUE: {
      const { type } = action.payload;
      return {
        ...state,
        [type]: !state[type]
      };
    }

    case FILTER_SET_SELECTED_DIVISIONS: {
      const { incomingDivision } = action.payload;

      return {
        ...state,
        divisions: createShallowCopy(state.divisions, incomingDivision)
      };
    }

    case FILTER_SET_SELECTED_RESOURCES: {
      const { incomingResource } = action.payload;
      return {
        ...state,
        resources: createShallowCopy(state.resources, incomingResource)
      };
    }

    case FILTER_SET_SELECTED_MANAGERS: {
      const { incomingManager } = action.payload;
      return {
        ...state,
        projectManagers: createShallowCopy(
          state.projectManagers,
          incomingManager
        )
      };
    }

    case FILTER_SET_SELECTED_PROJECTS: {
      const { changedProject } = action.payload;
      const updateProjectChecks = (items, changedItem, checkedOverride) => {
        const calculateItemCheckedFromChildren = (item, children) => {
          if (Array.isArray(children) && children.length > 0) {
            return children.every((child) => child.checked);
          }
          return item.checked;
        };

        return items.map((item) => {
          const isCurrentItemChanged = item.key === changedItem.key;
          const childrenCheckedOverride = isCurrentItemChanged
            ? changedItem.checked
            : checkedOverride;
          const children = updateProjectChecks(
            item.children,
            changedItem,
            childrenCheckedOverride
          );
          let checked;
          if (checkedOverride === true || checkedOverride === false) {
            checked = checkedOverride;
          } else if (isCurrentItemChanged) {
            checked = changedItem.checked;
          } else {
            checked = calculateItemCheckedFromChildren(item, children);
          }
          return {
            ...item,
            children,
            checked
          };
        });
      };

      return {
        ...state,
        projects: updateProjectChecks(state.projects, changedProject)
      };
    }

    case FILTER_REMOVE_SELECTED_PROJECTS: {
      const { removableProject } = action.payload;
      return {
        ...state,
        projects: removeProjectCheck(state.projects, removableProject)
      };
    }

    case RESOURCE_TREE_S: {
      const { data } = action.payload;

      const getItems = (itemList) =>
        itemList
          .map(({ items }) => items)
          .flat()
          .map((item) => {
            const foundObject = state.resources.find(
              (obj) => obj.label === item.name
            );
            if (foundObject) {
              return foundObject;
            }
            return {
              ...item,
              label: item.name,
              key: item.id,
              value: item.id,
              expanded: true,
              checked: false
            };
          });

      return {
        ...state,
        resources: getItems(data),
        projectManagers: getItems(
          data.filter(({ group_name: groupName }) => groupName === 'PM')
        )
      };
    }

    case RESOURCE_GROUPS_S: {
      const { data } = action.payload;

      const divisions = data.map(({ name }) => {
        const foundObject = state.divisions.find((obj) => obj.label === name);
        if (foundObject) {
          return foundObject;
        }
        return {
          label: name,
          key: name,
          value: name,
          expanded: true,
          checked: false
        };
      });

      return {
        ...state,
        divisions
      };
    }

    case PROJECT_HIERACRCHY_S: {
      const { data } = action.payload;

      const mapProjects = (projects) =>
        projects.map((project) => {
          const foundObject = state.projects.find(
            (p) => p.value === project.id
          );

          if (foundObject) {
            return foundObject;
          }
          return {
            key: project.id,
            value: project.id,
            label: project.name,
            status: project.status,
            checked: false,
            expanded: true,
            children: mapProjects(project.items)
          };
        });

      return {
        ...state,
        projects: mapProjects(data)
      };
    }

    case FILTER_SET_SELECTED_PERIOD: {
      const { newFirstDay, newLastDay } = action.payload.period;

      return {
        ...state,
        timePeriod: {
          ...state.timePeriod,
          firstDay: newFirstDay,
          lastDay: newLastDay
        }
      };
    }

    case FILTER_SET_WEEK_VIEW: {
      const { weekNumber } = action.payload;

      return {
        ...state,
        timePeriod: {
          ...state.timePeriod,
          lastDay: getEndOfPeriod(weekNumber),
          weekNumber
        }
      };
    }

    case USER_LOGOUT_S:
      return initialState;

    default:
      return state;
  }
};

export default filterReducer;
