import { Avatar } from 'antd';
import React, { useRef } from 'react';
import moment from 'moment';
import { EditFilled } from '@ant-design/icons';
import { formatTime, getAcronym, getDivisionClass } from 'utils';
import { useOutsideClick } from 'hooks/useOutsideClick';

export const InfoPopup = ({ reservation, position, onClose, onEditClick }) => {
  const menuRef = useRef(null);

  useOutsideClick(menuRef, onClose);

  return (
    <div
      ref={menuRef}
      className={`z-10 flex flex-col gap-1 text-left absolute py-4 pl-4 pr-16 shadow-2xl w-[270px]
    rounded bg-white overflow-hidden before:h-full before:w-2
    before:absolute before:left-0 before:top-0 ${
      getDivisionClass(reservation?.resource.group).beforeBg
    }`}
      style={{
        top: position.y + 5,
        ...(position.x + 270 > window.innerWidth
          ? { right: 0 }
          : { left: position.x })
      }}
    >
      <p className="whitespace-nowrap">
        Employee:{' '}
        <Avatar
          className="mr-1 -mt-1"
          src={reservation?.resource.avatar_url || ''}
          size={20}
        >
          {getAcronym(reservation?.resource.name)}
        </Avatar>
        <strong>{reservation?.resource.name}</strong>
      </p>
      <p>
        Department: <strong>{reservation?.resource.group}</strong>
      </p>
      <p>
        Ticket:{' '}
        <a
          target="_blank"
          className="font-semibold"
          href={`${process.env.REACT_APP_JIRA_URL}/browse/${reservation?.issue_id}`}
          rel="noreferrer"
        >
          {reservation?.issue_id} {reservation?.issue_summary}
        </a>
      </p>
      <p>
        Daily hours: <strong>{formatTime(reservation?.time_per_day)}</strong>
      </p>
      <p>
        Total time: <strong>{formatTime(reservation?.total_time)}</strong>
      </p>
      <p className="text-[10px] mt-4 text-gray-400">
        Last updated: {moment(reservation?.updated_at).format('YYYY. MMM. DD.')}
      </p>
      <button
        type="button"
        onClick={onEditClick}
        className="absolute right-4 top-4 font-semibold text-primary flex flex-row"
      >
        <EditFilled className="mt-1 mr-1 inline-block" />
        Edit
      </button>
    </div>
  );
};
