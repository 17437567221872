import React, { useLayoutEffect } from 'react';
import { Routes, Route, Navigate } from 'react-router-dom';
import withLayout from 'hoc/withLayout';
import LoginPage from 'routes/LoginPage/LoginPage';
import ResourcePage from 'routes/ResourcePage/ResourcePage';
import Page404 from 'routes/Page404/Page404';
import { ROUTER_PATHS } from 'routes/routerConstants';
import { useSetRecoilState } from 'recoil';
import { ProjectPage } from '../../routes/ProjectPage/ProjectPage';
import { codeState } from '../../store/atoms';

const App = () => {
  const setCode = useSetRecoilState(codeState);

  useLayoutEffect(() => {
    const urlParams = new URLSearchParams(window.location.search);
    const code = urlParams.get('code');
    if (code) {
      setCode(code);
    }
  }, []);
  return (
    <Routes>
      <Route
        path={ROUTER_PATHS.ROOT}
        element={<Navigate to={ROUTER_PATHS.RESOURCES} />}
        exact
      />
      <Route
        path={ROUTER_PATHS.LOGIN}
        element={withLayout(LoginPage)()}
        exact
      />
      <Route
        path={ROUTER_PATHS.RESOURCES}
        element={withLayout(ResourcePage, true)()}
        exact
      />
      <Route
        path={ROUTER_PATHS.PROJECTS}
        element={withLayout(ProjectPage, true)()}
        exact
      />
      <Route path={ROUTER_PATHS.NOT_FOUND} element={<Page404 />} exact />
      <Route path="*" element={<Navigate to={ROUTER_PATHS.NOT_FOUND} />} />
    </Routes>
  );
};

export default App;
