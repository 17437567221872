import { DATE_FORMATS } from 'constants/constants';
import moment from 'moment';

export const fillDateRangeWithDaysOf = (
  [startDate, endDate],
  daysToInclude
) => {
  if (!startDate.isBefore(endDate)) {
    return [];
  }

  const result = [];
  const currentDate = moment(startDate);

  while (currentDate.isSameOrBefore(endDate)) {
    const dayOfCurrentDate = currentDate
      .format(DATE_FORMATS.SHORT_DAY)
      .toLocaleLowerCase();
    if (daysToInclude.includes(dayOfCurrentDate)) {
      result.push(currentDate.clone());
    }
    currentDate.add(1, 'days');
  }

  return result;
};

const sortReservations = (reservationA, reservationB) =>
  moment(reservationA.starts_at).isSameOrAfter(moment(reservationB.starts_at))
    ? 1
    : -1;

const calculateCalendarEventLength = (startsAt, endsAt) => {
  const start = new Date(startsAt);
  const end = new Date(endsAt);
  const durationInMilliseconds = end - start;
  const durationInMinutes = Math.floor(durationInMilliseconds / (1000 * 60));
  return durationInMinutes;
};

const transformCalendarEventDateFormat = (dateString) => {
  const [date] = dateString.split(' ');
  return date;
};

export const createTimelines = (tableData) =>
  tableData.map((groupData) => ({
    ...groupData,
    items: [
      ...groupData.items.map((personData) => {
        const timeline = [];
        const transformedCalendarEvents = personData.calendar_events.map(
          (e) => ({
            ...e,
            type: 'calendar-event',
            ends_at: transformCalendarEventDateFormat(e.ends_at),
            starts_at: transformCalendarEventDateFormat(e.starts_at),
            total_time: calculateCalendarEventLength(e.starts_at, e.ends_at),
            days: [
              {
                day: transformCalendarEventDateFormat(e.starts_at),
                reservation_id: e.id,
                id: e.event_id,
                total_time: calculateCalendarEventLength(e.starts_at, e.ends_at)
              }
            ]
          })
        );

        const orderedReservations = [
          ...personData.reservations,
          ...transformedCalendarEvents
        ].sort(sortReservations);

        orderedReservations.forEach((reservation) => {
          for (let i = 0; timeline.length > i; i += 1) {
            if (
              moment(timeline[i][timeline[i].length - 1].ends_at).isBefore(
                moment(reservation.starts_at)
              )
            ) {
              timeline[i].push(reservation);
              return;
            }
          }
          timeline.push([reservation]);
        });
        return {
          ...personData,
          timelines: timeline.length === 0 ? [[]] : timeline
        };
      })
    ]
  }));

export const getNewDate = (date, initDays, amount) => {
  const isForward = amount > 0;
  const targetDate = moment(date);
  const steps = Math.abs(amount);

  for (let i = 0; i < steps; i += 1) {
    targetDate.add(isForward ? 1 : -1, 'd');
    while (
      !initDays.includes(
        targetDate.format(DATE_FORMATS.SHORT_DAY).toLocaleLowerCase()
      )
    ) {
      targetDate.add(isForward ? 1 : -1, 'd');
    }
  }
  return targetDate;
};

export const createNewTimelineDate = (tableData, item, amount, initDays) => {
  const new_starts_at = getNewDate(
    item.target.starts_at,
    initDays,
    amount
  ).format(DATE_FORMATS.DEFAULT);

  const new_ends_at = getNewDate(item.target.ends_at, initDays, amount).format(
    DATE_FORMATS.DEFAULT
  );

  const newDays = item.target.days.map((day) => ({
    ...day,
    day: getNewDate(day.day, initDays, amount).format(DATE_FORMATS.DEFAULT)
  }));

  const newReservation = {
    ...item.target,
    starts_at: new_starts_at,
    ends_at: new_ends_at,
    days: newDays
  };
  return {
    newTimeline: tableData.map((groups) => ({
      ...groups,
      items: groups.items.map((resource) =>
        +resource.id !== +newReservation.resource_id
          ? resource
          : {
              ...resource,
              reservations: [
                ...resource.reservations.filter(
                  (reservation) => +reservation.id !== +newReservation.id
                ),
                newReservation
              ]
            }
      )
    })),
    newReservation
  };
};

export const createNewTimelineDateExpand = (
  tableData,
  item,
  amount,
  isLeft,
  initDays
) => {
  const newReservation = isLeft
    ? {
        ...item.target,
        starts_at: getNewDate(item.target.starts_at, initDays, amount).format(
          DATE_FORMATS.DEFAULT
        ),
        ends_at: item.target.ends_at,
        days:
          amount > 0
            ? item.target.days.slice(1)
            : [
                {
                  ...item.target.days[0],
                  day: getNewDate(
                    item.target.days[0].day,
                    initDays,
                    amount
                  ).format(DATE_FORMATS.DEFAULT)
                },
                ...item.target.days
              ]
      }
    : {
        ...item.target,
        starts_at: item.target.starts_at,
        ends_at: getNewDate(item.target.ends_at, initDays, amount).format(
          DATE_FORMATS.DEFAULT
        ),
        days:
          amount > 0
            ? [
                ...item.target.days,
                {
                  ...item.target.days[item.target.days.length - 1],
                  day: getNewDate(
                    item.target.days[item.target.days.length - 1].day,
                    initDays,
                    amount
                  ).format(DATE_FORMATS.DEFAULT)
                }
              ]
            : item.target.days.slice(0, -1)
      };
  return {
    newTimeline: tableData.map((groups) => ({
      ...groups,
      items: groups.items.map((resource) =>
        +resource.id !== +newReservation.resource_id
          ? resource
          : {
              ...resource,
              reservations: [
                ...resource.reservations.filter(
                  (reservation) => +reservation.id !== +newReservation.id
                ),
                newReservation
              ]
            }
      )
    })),
    newReservation
  };
};

const handleResource = (newReservation, resource, oldId) => {
  if (+resource.id === +oldId)
    return {
      ...resource,
      reservations: resource.reservations.filter(
        (reservation) => +reservation.id !== +newReservation.id
      )
    };
  if (+resource.id === +newReservation.resource_id)
    return {
      ...resource,
      reservations: [...resource.reservations, newReservation]
    };
  return resource;
};

export const createNewTimelineResource = (tableData, item, newId) => {
  if (+item.target.resource_id === +newId)
    return { newTimeline: tableData, newResource: item.target };
  const newReservation = {
    ...item.target,
    resource_id: newId
  };
  return {
    newTimeline: tableData.map((groups) => ({
      ...groups,
      items: groups.items.map((resource) =>
        handleResource(newReservation, resource, item.target.resource_id)
      )
    })),
    newResource: newReservation
  };
};

export const getLeft = (dates, start, gap) =>
  dates[start] ? dates[start].left + gap : dates.left;

export const getRight = (dates, end, gap) =>
  dates[end] ? dates[end].right - gap : dates.right;
