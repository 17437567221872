import { Checkbox, Col, Row } from 'antd';
import cc from 'classcat';
import DropDownCheckBoxes from 'components/DropDownWithCheckBoxes/DropDown';
import { useEffect, useState } from 'react';
import { FilterSectionTags } from './FilterSectionTags';
import './filter-section.scss';
import { useFilterSection } from './useFilterSection';

const getSelectedProjects = (projectList) =>
  projectList.reduce((selected, project) => {
    if (!!project.children.length && project.checked) {
      return [...selected, project];
    }
    if (project.children.length) {
      return [...selected, ...getSelectedProjects(project.children)];
    }
    if (project.checked) {
      return [...selected, project];
    }
    return selected;
  }, []);

export const FilterSection = () => {
  const {
    filters,
    handleTagChange,
    dispatchSelectedDivisions,
    dispatchSelectedManagers,
    dispatchSelectedProjects,
    dispatchSelectedResources
  } = useFilterSection();

  const [selectedProjects, setSelectedProjects] = useState([]);

  const hasCheckedItem = (list) => list.some((listItem) => listItem.checked);

  const hasFilter =
    filters.freeCapacity ||
    filters.overBooked ||
    filters.holiday ||
    hasCheckedItem(filters.divisions) ||
    hasCheckedItem(filters.resources) ||
    hasCheckedItem(filters.projectManagers) ||
    selectedProjects.length;

  useEffect(() => {
    const selected = getSelectedProjects(filters.projects);
    setSelectedProjects(selected);
  }, [filters.projects]);

  return (
    <Row xs={24}>
      <Col span={24} className="filter-section">
        <Row className="filter-controller-container">
          <Col span={24} className="filter-title">
            filter
          </Col>
          <Col className="filter-controller-item">
            <Checkbox
              checked={filters.freeCapacity}
              onChange={() => handleTagChange('freeCapacity')}
            >
              Free capacity
            </Checkbox>
          </Col>
          <Col className="filter-controller-item">
            <Checkbox
              checked={filters.overBooked}
              onChange={() => handleTagChange('overBooked')}
            >
              Overbooked
            </Checkbox>
          </Col>
          <Col className="filter-controller-item">
            <Checkbox
              checked={filters.holiday}
              onChange={() => handleTagChange('holiday')}
            >
              holiday
            </Checkbox>
          </Col>
          <Col className="filter-controller-item">
            <DropDownCheckBoxes
              selectName="Division"
              onChange={(value) => dispatchSelectedDivisions(value)}
              selectOptions={filters.divisions}
            />
          </Col>
          <Col className="filter-controller-item">
            <DropDownCheckBoxes
              filterable
              selectName="Projects"
              onChange={dispatchSelectedProjects}
              selectOptions={filters.projects.filter((val) => val?.status)}
            />
          </Col>
          <Col className="filter-controller-item">
            <DropDownCheckBoxes
              filterable
              selectName="Resources"
              onChange={(value) => dispatchSelectedResources(value)}
              selectOptions={filters.resources}
            />
          </Col>
          <Col className="filter-controller-item">
            <DropDownCheckBoxes
              filterable
              selectName="PM"
              onChange={(value) => {
                dispatchSelectedManagers(value);
              }}
              selectOptions={filters.projectManagers}
            />
          </Col>
        </Row>
      </Col>
      <Col
        span={24}
        className={cc({
          'filter-section': true,
          'filter-tag-container': hasFilter,
          'no-filter-tag-container': !hasFilter
        })}
      >
        <Row className="filter-controller-container">
          <FilterSectionTags selectedProjects={selectedProjects} />
        </Row>
      </Col>
    </Row>
  );
};
