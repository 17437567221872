import { useDragLayer } from 'react-dnd';
import { DragTypes } from './ProjectDay/ProjectDay';
import { ReservationMoveDragLayer } from '../Reservation/ReservationMoveDragLayer';
import { ReservationLengthChangeDragLayer } from '../Reservation/ReservationLengthChangeDragLayer';

export const CustomDragLayer = () => {
  const { itemType, isDragging, item, initialOffset } = useDragLayer(
    (monitor) => ({
      item: monitor.getItem(),
      itemType: monitor.getItemType(),
      initialOffset: monitor.getInitialSourceClientOffset(),
      currentOffset: monitor.getSourceClientOffset(),
      isDragging: monitor.isDragging()
    })
  );

  if (!isDragging) return null;

  const renderDragLayer = () => {
    switch (itemType) {
      case DragTypes.MOVE:
        return (
          <ReservationMoveDragLayer item={item} initialOffset={initialOffset} />
        );
      case DragTypes.START_CHANGE:
      case DragTypes.END_CHANGE:
        return (
          <ReservationLengthChangeDragLayer item={item} itemType={itemType} />
        );
      default:
        return null;
    }
  };

  return (
    <div className="fixed z-[100] pointer-events-none left-0 top-0 width-full height-full">
      {renderDragLayer()}
    </div>
  );
};
