export const useTimetable = () => {
  const config = {
    days: ['mon', 'tue', 'wed', 'thu', 'fri'],
    profileColumnWidth: 254,
    timeScheduleHeight: 22,
    groupHeaderHeight: 35,
    capacityHeight: 40,
    spaceBetweenTimeSchedules: 4,
    requiredHours: 8
  };

  return { config };
};
