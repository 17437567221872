import {
  removeSelectedProjects,
  setCheckBoxValue,
  setSelectedDivisions,
  setSelectedManagers,
  setSelectedProjects,
  setSelectedResources
} from 'actions/filterActions';
import { useDispatch, useSelector } from 'react-redux';

export const useFilterSection = () => {
  const dispatch = useDispatch();

  const filters = useSelector((state) => state.filterReducer);

  const handleTagChange = (tagType) => {
    dispatch(setCheckBoxValue(tagType));
  };

  const dispatchSelectedDivisions = (division) => {
    dispatch(setSelectedDivisions(division));
  };

  const dispatchSelectedResources = (division) => {
    dispatch(setSelectedResources(division));
  };

  const dispatchRemoveSelectedProjects = (division) => {
    dispatch(removeSelectedProjects(division));
  };

  const dispatchSelectedManagers = (division) => {
    dispatch(setSelectedManagers(division));
  };

  const dispatchSelectedProjects = (division) => {
    dispatch(setSelectedProjects(division));
  };

  return {
    filters,
    handleTagChange,
    dispatchRemoveSelectedProjects,
    dispatchSelectedDivisions,
    dispatchSelectedManagers,
    dispatchSelectedProjects,
    dispatchSelectedResources
  };
};
