import React from 'react';
import { useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import moment from 'moment';
// import { findFirstDay, findLastDay } from 'services/time';
import { CaretLeftOutlined, CaretRightOutlined } from '@ant-design/icons';
import { DATE_FORMATS } from 'constants/constants';
import 'antd/dist/antd.min.css';
import './date-setter.scss';

const DateSetter = ({ onChange }) => {
  // const [weekNumber, setWeekNumber] = useState(0);
  // const [daysUntilLastDay, setDaysUntilLastDay] = useState(5 + (period - 1) * 7);
  // const [firstDay, setFirstDay] = useState(null);
  // const [lastDay, setLastDay] = useState(null);

  const { firstDay, lastDay, weekNumber } = useSelector(
    ({ filterReducer }) => filterReducer.timePeriod
  );

  const handleClick = (direction) => {
    const weekDirection = direction === 'decrement' ? -1 : 1;
    const newFirstDay = moment(firstDay)
      .add(weekDirection, 'week')
      .format(DATE_FORMATS.DEFAULT);
    const newLastDay = moment(newFirstDay)
      .add(weekNumber - 1, 'week')
      .add(4, 'days')
      .format(DATE_FORMATS.DEFAULT);
    // const newFirstDay = findFirstDay(newWeekNumber);
    // const newLastDay = findLastDay(newFirstDay, daysUntilLastDay);
    // console.log('dispatch me, my friend:', newFirstDay, newLastDay, newWeekNumber);
    // setFirstDay(newFirstDay);
    // setLastDay(newLastDay);
    // setWeekNumber(newWeekNumber);
    onChange({ newFirstDay, newLastDay });
  };

  // useEffect(() => {
  //   const currentFirstDay = findFirstDay(0);
  //   setFirstDay(currentFirstDay);
  //   setLastDay(findLastDay(currentFirstDay, daysUntilLastDay));
  // }, []);

  // useEffect(() => {
  //   setDaysUntilLastDay(4 + (period - 1) * 7);
  // }, [period]);

  return (
    <div className="date-setter">
      <button
        aria-label="Set date"
        className="date-setter-button left"
        type="button"
        onClick={() => handleClick('decrement')}
      >
        <CaretLeftOutlined />
      </button>
      <span className="date-setter-date">{`${firstDay} - ${lastDay}`}</span>
      <button
        aria-label="Set date"
        className="date-setter-button right"
        type="button"
        onClick={handleClick}
      >
        <CaretRightOutlined />
      </button>
    </div>
  );
};

DateSetter.propTypes = {
  onChange: PropTypes.func
};

DateSetter.defaultProps = {
  onChange: () => {}
};

export default DateSetter;
