import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { login, cognitoLogin, restoreUserByToken } from 'actions/userActions';
import { LOCAL_STORAGE_ITEMS } from 'constants/constants';
import { ROUTER_PATHS } from 'routes/routerConstants';
import 'routes/LoginPage/login-page.scss';
import { Button } from 'antd';
import { useRecoilValue, useResetRecoilState } from 'recoil';
import { getCompanyLogoComponent, getPageTitle } from '../../utils/branding';
import { codeState } from '../../store/atoms';

const LogoComponent = getCompanyLogoComponent();

const LoginPage = () => {
  const code = useRecoilValue(codeState);
  const resetCode = useResetRecoilState(codeState);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { isAuthenticating, isAuthenticated } = useSelector(({ user }) => user);

  useEffect(() => {
    document.title = getPageTitle('Login');

    if (isAuthenticated) {
      navigate(ROUTER_PATHS.RESOURCES);
    } else if (localStorage.getItem(LOCAL_STORAGE_ITEMS.ACCESS_TOKEN)) {
      dispatch(restoreUserByToken(ROUTER_PATHS.RESOURCES));
    }
  }, []);

  const responseCognitoSuccess = () => {
    dispatch(cognitoLogin());
    dispatch(login(code));
    resetCode();
  };

  useEffect(() => {
    if (code) {
      responseCognitoSuccess(code);
    }
  }, [code]);

  const loginLink = new URL(
    `${process.env.REACT_APP_COGNITO_LOGIN_URL}?scope=openid+profile`
  );
  loginLink.searchParams.append(
    'client_id',
    process.env.REACT_APP_COGNITO_CLIENT_ID
  );
  loginLink.searchParams.append(
    'redirect_uri',
    process.env.REACT_APP_COGNITO_CALLBACK_URL
  );
  loginLink.searchParams.append('response_type', 'code');

  return (
    <div className="login-container">
      <LogoComponent alt="Company Logo" className="login-logo" />
      <Button
        loading={isAuthenticating}
        onClick={() => {
          window.location.href = loginLink;
        }}
      >
        Login
      </Button>
    </div>
  );
};

export default LoginPage;
