import React from 'react';
import PropTypes from 'prop-types';
import { Provider } from 'react-redux';
import { BrowserRouter } from 'components/BrowserRouter/BrowserRouter';
import { App } from 'components/App';
import { RecoilRoot } from 'recoil';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { ReactQueryDevtools } from '@tanstack/react-query-devtools';

const queryClient = new QueryClient();

export const Root = ({ store, history }) => {
  return (
    <Provider store={store}>
      <BrowserRouter history={history}>
        <QueryClientProvider client={queryClient}>
          <ReactQueryDevtools initialIsOpen={false} />
          <RecoilRoot>
            <ToastContainer theme="colored" />
            <App />
          </RecoilRoot>
        </QueryClientProvider>
      </BrowserRouter>
    </Provider>
  );
};

Root.propTypes = {
  store: PropTypes.shape().isRequired,
  history: PropTypes.shape().isRequired
};

export default Root;
