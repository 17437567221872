import { LogoutOutlined } from '@ant-design/icons';
import { logout } from 'actions/userActions';
import { Col, Row } from 'antd';
import { useLocation, Link } from 'react-router-dom';
import cc from 'classcat';
import { useDispatch } from 'react-redux';
import './app-header.scss';
import { ROUTER_PATHS } from 'routes/routerConstants';
import { getCompanyLogoComponent } from 'utils/branding';

const Logo = getCompanyLogoComponent();

export const AppHeader = () => {
  const dispatch = useDispatch();
  const location = useLocation();

  return (
    <Row className="app-header-container">
      <Col className="app-header-left-container">
        <Row>
          <Col className="app-logo-container">
            <button
              aria-label="Clear session and return to homepage"
              type="button"
              style={{
                backgroundColor: 'transparent',
                border: 'none',
                cursor: 'pointer'
              }}
              onClick={() => {
                sessionStorage.clear();
                window.location.pathname = '/';
              }}
            >
              <Logo style={{ maxWidth: 200 }} />
            </button>
          </Col>
          <Col>
            <Link
              className={cc({
                'header-link': true,
                active: location.pathname === ROUTER_PATHS.RESOURCES
              })}
              to={ROUTER_PATHS.RESOURCES}
            >
              Resource view
            </Link>
          </Col>
          <Col>
            <Link
              className={cc({
                'header-link': true,
                active: location.pathname === ROUTER_PATHS.PROJECTS
              })}
              to={ROUTER_PATHS.PROJECTS}
            >
              Project view
            </Link>
          </Col>
        </Row>
      </Col>
      <Col className="app-header-right-container">
        <Row>
          <Col
            className="app-header-logout-container"
            role="button"
            tabIndex={0}
            onClick={() => dispatch(logout())}
          >
            Log out
            <LogoutOutlined className="ml-1" />
          </Col>
        </Row>
      </Col>
    </Row>
  );
};
