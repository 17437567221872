import { useQuery } from '@tanstack/react-query';
import { useApi } from './useApi';

export const useResourcesApi = () => {
  const { getResources, getResourceGroups } = useApi();

  const resourcesQuery = useQuery({
    queryKey: ['resources'],
    queryFn: getResources,
    keepPreviousData: true
  });

  const resourceGroupsQuery = useQuery({
    queryKey: ['resourceGroups'],
    queryFn: getResourceGroups,
    keepPreviousData: true
  });

  return {
    resourcesQuery,
    resourceGroupsQuery
  };
};
