import {
  DownOutlined,
  FundProjectionScreenOutlined,
  UpOutlined,
  HourglassOutlined,
  VerticalRightOutlined,
  VerticalLeftOutlined
} from '@ant-design/icons';
import { useState } from 'react';
import { formatTime } from 'utils';
import moment from 'moment';
import { TimerIcon } from '../icons/Timer';
import { JiraIcon } from '../icons/Jira';

export const ProjectDescription = ({ project }) => {
  const [isOpen, setIsOpen] = useState(false);

  return (
    <div className="w-[250px] p-2 cellBg">
      <div className="flex flex-row">
        <FundProjectionScreenOutlined className="h-fit text-primary text-2xl py-1 px-2 bg-primary/20 border-primary border-2 rounded-full mr-2" />
        <div className="mt-1 font-semibold text-sm">{project.name}</div>
      </div>
      <div className="bg-white m-2 p-4 rounded flex flex-col gap-2">
        <div className="p-2 border border-gray-200 rounded flex flex-row gap-2">
          <TimerIcon className="text-xl" />
          <div className="mt-1">
            <p className="text-gray-400 text-xs">Time spent on project</p>
            <p className="text-sm font-medium">
              {formatTime(project.time_spent_minutes)}
            </p>
          </div>
        </div>
        <div className="p-2 border border-gray-200 rounded flex flex-row gap-2">
          <HourglassOutlined className="text-xl" />
          <div className="mt-1">
            <p className="text-gray-400">Planned overall time</p>
            <p className="text-sm font-medium">
              {formatTime(project.total_planned_minutes)}
            </p>
          </div>
        </div>
        {isOpen && (
          <>
            <div className="p-2 border border-gray-200 rounded flex flex-col gap-2">
              <div className="flex flex-row gap-2">
                <VerticalRightOutlined className="text-xl" />
                <div className="mt-1">
                  <p className="text-gray-400">First day of resourcing</p>
                  <p className="text-sm font-medium">
                    {moment(project.first_day).format('MMMM D, YYYY')}
                  </p>
                </div>
              </div>
              <div className="flex flex-row gap-2">
                <VerticalLeftOutlined className="text-xl" />
                <div className="mt-1">
                  <p className="text-gray-400">Last day of resourcing</p>
                  <p className="text-sm font-medium">
                    {moment(project.last_day).format('MMMM D, YYYY')}
                  </p>
                </div>
              </div>
            </div>
            <hr className="mt-2 mb-1" />
            <div className="flex flex-col gap-2 justify-start">
              <p className="text-lg flex flex-row gap-1">
                <JiraIcon className="mt-1.5" /> Jira
              </p>
              <div className="p-3 border border-blue-400 bg-blue-100 rounded flex flex-col gap-4 font-medium text-sm">
                <p>
                  Open issues{' '}
                  <span className="bg-white text-blue-600 py-1 px-2 ml-2">
                    {project.open_count}
                  </span>
                </p>
                <p>
                  Closed issues{' '}
                  <span className="bg-white text-blue-600 py-1 px-2 ml-2">
                    {project.closed_count}
                  </span>
                </p>
                <p>
                  Number of issues{' '}
                  <span className="bg-white text-blue-600 py-1 px-2 ml-2">
                    {project.open_count + project.closed_count}
                  </span>
                </p>
              </div>
            </div>
          </>
        )}
        <button type="button" onClick={() => setIsOpen((prev) => !prev)}>
          {isOpen ? <UpOutlined /> : <DownOutlined />}
        </button>
      </div>
    </div>
  );
};
