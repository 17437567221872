import moment from 'moment/moment';
import cc from 'classcat';
import React from 'react';
import { useRecoilValue } from 'recoil';
import { DATE_FORMATS } from 'constants/constants';
import { isToday } from 'utils';
import { projectsTableFiltersState } from 'store/atoms';
import { useDrop } from 'react-dnd';
import cloneDeep from 'lodash/cloneDeep';
import classes from '../GroupRow/GroupRow.module.css';
import { DailyTimesOfReservations } from '../DailyTimesOfReservations/DailyTimesOfReservations';
import { DraggableReservation } from '../../Reservation/DraggableReservation';
import { useProjectsTableApi } from '../../../hooks/api/useProjectsTableApi';

export const DragTypes = {
  MOVE: 'move',
  START_CHANGE: 'starts_at',
  END_CHANGE: 'ends_at'
};

export const ProjectDay = ({
  workday,
  workdayDates,
  project,
  handleReservationClick,
  handleCellClick
}) => {
  const { nationalHolidaysQuery, updateReservationMutation } =
    useProjectsTableApi();
  const filters = useRecoilValue(projectsTableFiltersState);
  const { data: nationalHolidays } = nationalHolidaysQuery;

  const formattedWorkday = moment(workday).format(DATE_FORMATS.DEFAULT);

  const isWorkday = (day) =>
    moment(day.day).format(DATE_FORMATS.DEFAULT) === formattedWorkday;

  const nationalHolidayOnDate = nationalHolidays?.find(
    (holiday) => holiday.day === formattedWorkday
  );

  const reservationsForWorkday = project.reservations.filter((reservation) =>
    reservation.days.some(isWorkday)
  );

  const handleReservationMove = async (data) => {
    const dayCountBetweenDates = workday.businessDiff(
      moment(data.from),
      'days'
    );
    if (dayCountBetweenDates === 0) return;

    const reservationCopy = cloneDeep(data.reservation);

    const daysASC = reservationCopy.days.sort((a, b) => {
      const dayA = new Date(a.day);
      const dayB = new Date(b.day);
      return dayA - dayB;
    });

    const updatedDates = daysASC.map((obj) => {
      const newDay = moment(obj.day)
        .businessAdd(dayCountBetweenDates)
        .format(DATE_FORMATS.DEFAULT);
      return { ...obj, day: newDay };
    });

    await updateReservationMutation.mutate({
      reservationId: data.reservation.id,
      updatedFields: {
        starts_at: updatedDates[0].day,
        ends_at: updatedDates[updatedDates.length - 1].day
      }
    });
  };

  const handleReservationLengthChange = async ({ reservation, dateType }) => {
    const originalDates = {
      starts_at: reservation.startsAt,
      ends_at: reservation.endsAt
    };

    await updateReservationMutation.mutate({
      reservationId: reservation.id,
      updatedFields: {
        ...originalDates,
        [dateType]: formattedWorkday
      }
    });
  };

  const [, drop] = useDrop({
    accept: [DragTypes.MOVE, DragTypes.END_CHANGE, DragTypes.START_CHANGE],
    drop: (data, monitor) => {
      const itemType = monitor.getItemType();
      if (itemType === DragTypes.MOVE) {
        void handleReservationMove(data);
        return;
      }
      if (itemType === DragTypes.START_CHANGE) {
        if (data.reservation.starts_at === formattedWorkday) return;
        void handleReservationLengthChange({
          reservation: data.reservation,
          dateType: 'starts_at',
          workday: formattedWorkday
        });
        return;
      }
      if (itemType === DragTypes.END_CHANGE) {
        if (data.reservation.ends_at === formattedWorkday) return;
        void handleReservationLengthChange({
          reservation: data.reservation,
          dateType: 'ends_at',
          workday: formattedWorkday
        });
      }
    },
    hover: (item, monitor) => {
      // eslint-disable-next-line no-param-reassign
      monitor.getItem().hoveredDay = workday;
      // eslint-disable-next-line no-param-reassign
      monitor.getItem().dateRange = workdayDates;
    }
  });

  return (
    <div
      ref={drop}
      onClick={
        nationalHolidayOnDate
          ? null
          : () =>
              handleCellClick({
                date: moment(workday).format(DATE_FORMATS.DEFAULT),
                projectId: project.id
              })
      }
      className={cc({
        'relative flex flex-col cellBg py-2 overflow-hidden': true,
        'bg-black/10': isToday(workday),
        [classes.holiday]: !!nationalHolidayOnDate
      })}
      style={{
        width: `calc((100vw - 250px) / ${workdayDates.length})`
      }}
    >
      {nationalHolidayOnDate && nationalHolidayOnDate.title}
      <DailyTimesOfReservations
        workdays={workdayDates}
        day={workday}
        project={project}
      />
      {reservationsForWorkday.map((reservation) => (
        <DraggableReservation
          key={reservation.id}
          reservation={reservation}
          workday={workday}
          onClick={handleReservationClick}
          filters={filters}
        />
      ))}
    </div>
  );
};
