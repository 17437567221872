import React from 'react';
import PropTypes from 'prop-types';

const LoginLayout = ({ children }) => (
  <div className="layout layout-login">{children}</div>
);

LoginLayout.propTypes = {
  children: PropTypes.element
};

LoginLayout.defaultProps = {
  children: null
};

export default LoginLayout;
