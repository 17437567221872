import { CaretDownOutlined, CaretUpOutlined } from '@ant-design/icons';
import React, { Children, useState } from 'react';
import moment from 'moment';
import { isToday } from 'utils';
import { DATE_FORMATS, timetableConfig } from 'constants/constants';
import { useRecoilValue } from 'recoil';
import { projectsTableFiltersState } from 'store/atoms';
import { fillDateRangeWithDaysOf } from '../../Timetable/utils';
import { ProjectDescription } from '../ProjectDescription';
import 'moment-business-days';
import { ProjectDay } from '../ProjectDay/ProjectDay';

export const GroupRow = ({
  group,
  handleReservationClick,
  handleCellClick
}) => {
  const filters = useRecoilValue(projectsTableFiltersState);

  const [isOpen, setIsOpen] = useState(true);

  const datePeriod = [
    filters.from,
    moment(filters.from).add(filters.selectedWeekView * 6, 'd')
  ];
  const workdayDatesOfSelectedDatePeriod = fillDateRangeWithDaysOf(
    datePeriod,
    timetableConfig.days
  );

  return (
    <div
      key={group.type}
      className={`${isOpen ? '' : 'max-h-[40px]'} overflow-hidden`}
    >
      <div className="flex flex-row">
        <button
          type="button"
          className="bg-black/10 w-[250px] h-10 uppercase font-semibold text-gray-500 text-sm flex flex-row gap-2 items-center pl-2"
          onClick={() => setIsOpen((prev) => !prev)}
        >
          {group.type} ({group.projects.length}){' '}
          {isOpen ? <CaretUpOutlined /> : <CaretDownOutlined />}
        </button>
        {Children.toArray(
          workdayDatesOfSelectedDatePeriod.map((day) => (
            <div
              className={`${
                isToday(day)
                  ? 'bg-black/70 text-white'
                  : 'bg-black/10 text-gray-500'
              } h-10 uppercase text-center font-semibold flex flex-col justify-center`}
              style={{
                width: `calc((100vw - 250px) / ${workdayDatesOfSelectedDatePeriod.length})`
              }}
            >
              {day.format(DATE_FORMATS.SHORT_DAY)}{' '}
              {day.format(DATE_FORMATS.DAY_OF_MONTH)}
            </div>
          ))
        )}
      </div>
      {Children.toArray(
        group.projects.map((project) => (
          <div
            className="flex flex-row border-b border-gray-400"
            style={{
              minHeight: `calc(${project.reservations.length * 50}px + 3.5rem)`
            }}
          >
            <ProjectDescription project={project} />
            {Children.toArray(
              workdayDatesOfSelectedDatePeriod.map((workday) => (
                <ProjectDay
                  handleCellClick={handleCellClick}
                  project={project}
                  workday={workday}
                  workdayDates={workdayDatesOfSelectedDatePeriod}
                  handleReservationClick={handleReservationClick}
                />
              ))
            )}
          </div>
        ))
      )}
    </div>
  );
};
