import { ReactComponent as DevertixLogo } from 'assets/images/devertix-logo.svg';
import { ReactComponent as Tech2FlowLogo } from 'assets/images/tech2flow-logo.svg';

export const getCompanyLogoComponent = () => {
  const companyLogos = {
    devertix: DevertixLogo,
    tech2flow: Tech2FlowLogo
  };

  return companyLogos[process.env.REACT_APP_BRAND];
};

export const getPageTitle = (page) => {
  const brand = process.env.REACT_APP_BRAND;
  const capitalizedBrandName = brand.charAt(0).toUpperCase() + brand.slice(1);

  return `${page} | ${capitalizedBrandName} Resource Planner`;
};
