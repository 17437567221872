import { MODAL_CHANGE } from 'constants/actionTypes';
import initialState from 'store/reducers/modal/initialState';

const modalReducer = (state = initialState, action) => {
  switch (action.type) {
    case MODAL_CHANGE: {
      const { modalName, data } = action.payload;
      return {
        ...state,
        [modalName]: data
      };
    }

    default:
      return state;
  }
};

export default modalReducer;
