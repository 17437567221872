import React from 'react';
import PropTypes from 'prop-types';
import cc from 'classcat';
import './tag.scss';

const Tag = ({
  title,
  closable,
  onClose,
  id,
  color,
  backgroundColor,
  icon,
  visible
}) => (
  <div
    className={cc({
      'tag-wrapper': visible,
      invisible: !visible
    })}
    style={{ backgroundColor, color }}
  >
    <div
      style={{
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center'
      }}
    >
      {title}
      {closable && (
        <div
          className="cancel-tag"
          role="button"
          tabIndex={0}
          onClick={!closable ? () => {} : () => onClose(id)}
        >
          {icon}
        </div>
      )}
    </div>
  </div>
);

Tag.propTypes = {
  title: PropTypes.string.isRequired,
  onClose: PropTypes.func,
  id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  closable: PropTypes.bool,
  visible: PropTypes.bool,
  backgroundColor: PropTypes.string,
  color: PropTypes.string,
  icon: PropTypes.element
};

Tag.defaultProps = {
  onClose: () => {},
  backgroundColor: '#1E1ECE',
  color: '#FFFFFF',
  closable: false,
  visible: true,
  icon: null,
  id: 0
};

export default Tag;
