import React, { useRef } from 'react';
import PropTypes from 'prop-types';
import { useDispatch } from 'react-redux';
import './LeftClickMenu.scss';
import { formatTime } from 'utils';
import { EditFilled, ScissorOutlined } from '@ant-design/icons';
import { changeModal } from 'actions/userActions';
import { splitReservation } from 'actions/reservationActions';
import { useOutsideClick } from '../../../hooks/useOutsideClick';

const LeftClickMenu = ({
  reservation,
  theme,
  absences,
  onClose,
  actualDate
}) => {
  const dispatch = useDispatch();
  const ref = useRef(null);

  useOutsideClick(ref, onClose);

  return (
    <div
      ref={ref}
      className={`left-click-menu-container left-click-menu-container-theme-${theme}`}
    >
      <div className="row">
        <div />
        <div
          style={{
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'end',
            gap: '10px'
          }}
        >
          <button
            type="button"
            onClick={() => {
              dispatch(
                splitReservation(reservation.id, {
                  split_at: actualDate
                })
              );
              onClose();
            }}
            className="font-semibold text-primary flex flex-row"
          >
            <ScissorOutlined className="mt-1 mr-1 inline-block" />
            Split
          </button>
          <button
            type="button"
            onClick={() => {
              dispatch(
                changeModal('reservationFormModal', {
                  isVisible: true,
                  reservationId: reservation.id,
                  absences
                })
              );
              onClose();
            }}
            className="font-semibold text-primary flex flex-row"
          >
            <EditFilled className="mt-1 mr-1 inline-block" />
            Edit
          </button>
        </div>
      </div>
      <div className="row">
        <div>Project</div>
        <div>{reservation.project.name}</div>
      </div>
      <div className="row">
        <div>Tickets</div>
        <div>
          {reservation?.selected_tickets &&
            reservation?.selected_tickets.map((ticket) => (
              <>
                <a
                  target="_blank"
                  href={`${process.env.REACT_APP_JIRA_URL}/browse/${ticket.value}`}
                  rel="noreferrer"
                >
                  {ticket.value} - {ticket.label}
                </a>
                <br />
              </>
            ))}
          <a
            target="_blank"
            href={`${process.env.REACT_APP_JIRA_URL}/browse/${reservation.issue_id}`}
            rel="noreferrer"
          >
            {reservation.issue_id}
            <br />
            {reservation.issue_summary}
          </a>
        </div>
      </div>
      <div className="row">
        <div>Daily hours</div>
        <div>{formatTime(reservation.time_per_day)}</div>
      </div>
      <div className="row">
        <div>Total time</div>
        <div>{formatTime(reservation.total_time)}</div>
      </div>
      <div className="row">
        <div>Interval</div>
        <div>
          {reservation.starts_at} - {reservation.ends_at}
        </div>
      </div>
      <div className="row">
        <div>Last updated</div>
        <div>{reservation.updated_at}</div>
      </div>
      <div className="row">
        <div>Description</div>
        <div className="description">{reservation.description}</div>
      </div>
    </div>
  );
};

LeftClickMenu.propTypes = {
  reservation: PropTypes.shape({}).isRequired,
  theme: PropTypes.string.isRequired,
  absences: PropTypes.arrayOf().isRequired
};

LeftClickMenu.defaultProps = {};

export default LeftClickMenu;
