import { changeModal } from 'actions/userActions';
import { Avatar, Tooltip } from 'antd';
import cc from 'classcat';
import { DATE_FORMATS } from 'constants/constants';
import { useTimetable } from 'hooks/useTimetable';
import moment from 'moment';
import PropTypes from 'prop-types';
import React, { useEffect, useRef, useState } from 'react';
import { useDispatch } from 'react-redux';
import { isToday, minutesToFormattedDecimalHours, getAcronym } from 'utils';
import './divisiontable.scss';

const DivisionTable = ({
  workdayDatesOfSelectedDatePeriod,
  division,
  timelinesData,
  setDates,
  setResources,
  changeDivisionVisibility,
  leftClickModalObj
}) => {
  const dispatch = useDispatch();
  const { config } = useTimetable();

  const [dateRefs, setDateRefs] = useState(
    workdayDatesOfSelectedDatePeriod.map(() => React.createRef())
  );
  const resourceRefs = useRef(division.items.map(() => React.createRef()));

  useEffect(() => {
    setDateRefs(workdayDatesOfSelectedDatePeriod.map(() => React.createRef()));
  }, [workdayDatesOfSelectedDatePeriod]);

  useEffect(() => {
    setDates(
      dateRefs.map(({ current }, i) => {
        const { left, right } = current ? current.getBoundingClientRect() : {};
        return {
          left,
          right,
          date: workdayDatesOfSelectedDatePeriod[i].format(
            DATE_FORMATS.DEFAULT
          ),
          i
        };
      })
    );
  }, [dateRefs]);

  useEffect(() => {
    setDates(
      dateRefs
        .slice(0, workdayDatesOfSelectedDatePeriod.length)
        .map(({ current }, i) => {
          const { left, right } = current
            ? current.getBoundingClientRect()
            : {};
          return {
            left,
            right,
            date: workdayDatesOfSelectedDatePeriod[i].format(
              DATE_FORMATS.DEFAULT
            ),
            i
          };
        })
    );
    setResources(
      resourceRefs.current
        .filter(({ current }) => current)
        .map(({ current }, i) => {
          const { top, bottom } = current
            ? current.getBoundingClientRect()
            : {};
          return {
            top: top + window.pageYOffset,
            bottom: bottom + window.pageYOffset,
            id: division.items[i] ? division.items[i].id : null
          };
        })
    );
  }, [timelinesData]);

  const MAX_ALLOCATABLE_TIME = workdayDatesOfSelectedDatePeriod.length * 8 * 60;

  const isDayInInterval = (day, intervalParam) => {
    const intervalStart = intervalParam[0];
    const intervalEnd = intervalParam[intervalParam.length - 1];

    return moment(day.day).isBetween(intervalStart, intervalEnd, null, '[]');
  };

  const calculateAbsenceTime = (absences, intervalParam) => {
    const intervalStart = intervalParam[0];
    const intervalEnd = intervalParam[intervalParam.length - 1];

    return absences.reduce((total, absence) => {
      const start = moment(absence.starts_at).isBefore(intervalStart)
        ? intervalStart
        : moment(absence.starts_at);
      const end = moment(absence.ends_at).isAfter(intervalEnd)
        ? intervalEnd
        : moment(absence.ends_at);

      if (start.isAfter(end)) {
        return total;
      }

      const duration = moment.duration(end.diff(start)).asDays() + 1;
      return total + duration * config.requiredHours * 60;
    }, 0);
  };

  const renderFreeTimeValue = (intervalParam, timelines, absences) => {
    const totalCapacity = intervalParam.reduce(
      (prev) => prev + config.requiredHours * 60,
      0
    );

    const allocatedTime = timelines
      .flat(1)
      .map((timeline) => timeline.days)
      .flat(1)
      .filter((day) => isDayInInterval(day, intervalParam))
      .reduce((prev, intervalDay) => prev + intervalDay.total_time, 0);

    const freeTimeLeft =
      MAX_ALLOCATABLE_TIME -
      allocatedTime -
      calculateAbsenceTime(absences, intervalParam);

    const freeHours = Math.floor(freeTimeLeft / 60);
    const freeMinutes = freeTimeLeft % 60;

    const totalHours = Math.floor(
      (totalCapacity - calculateAbsenceTime(absences, intervalParam)) / 60
    );

    return `${freeHours}h ${
      freeMinutes > 0 ? `${freeMinutes}m` : ''
    } / ${totalHours}h`;
  };

  const renderBookedHours = (timelines, day) => {
    const bookedMinutes = timelines
      .flat(1)
      .map((timeline) => timeline.days)
      .flat(1)
      .reduce(
        (prev, intervalDay) =>
          intervalDay.day.slice(0, 10) === day.format(DATE_FORMATS.DEFAULT)
            ? prev + intervalDay.total_time
            : prev,
        0
      );

    const upperThresholdNormalMinutes = 8.8 * 60;
    const lowerThresholdNormalMinutes = 7.2 * 60;

    const isOverbooked = bookedMinutes > upperThresholdNormalMinutes;
    const isUnderbooked = bookedMinutes < lowerThresholdNormalMinutes;

    return (
      <span
        style={{ color: isOverbooked || isUnderbooked ? '#df3538' : '#16ceb9' }}
      >
        {minutesToFormattedDecimalHours(bookedMinutes)}
      </span>
    );
  };

  const isAbsence = (absences, day) =>
    absences.some((absence) =>
      day.isBetween(absence.starts_at, absence.ends_at, undefined, '[]')
    );

  const getAbsenceType = (absences, day) => {
    const absence = absences.find((_absence) =>
      day.isBetween(_absence.starts_at, _absence.ends_at, undefined, '[]')
    );
    return absence ? absence.title : '';
  };

  return (
    <div className="divisiontable">
      <div
        className="divisiontable-header"
        style={{ height: config.groupHeaderHeight }}
        onClick={changeDivisionVisibility}
        role="button"
        tabIndex={-1}
      >
        <div
          className="divisiontable-header-profile"
          style={{ width: config.profileColumnWidth }}
        >
          {`${division.group_name} (${division.resource_hit_count})`}
        </div>

        {workdayDatesOfSelectedDatePeriod &&
          workdayDatesOfSelectedDatePeriod.map((day, i) => (
            <div
              key={day}
              ref={dateRefs[i]}
              className={cc({
                'divisiontable-header-day': true,
                'divisiontable-header-day--today': isToday(day)
              })}
            >
              {`${day.format(DATE_FORMATS.SHORT_DAY)} ${day.format(
                DATE_FORMATS.DAY_OF_MONTH
              )}`}
            </div>
          ))}
      </div>

      {division.isVisible &&
        timelinesData &&
        division.items &&
        config.timeScheduleHeight !== 0 &&
        timelinesData.items.map(
          ({ id, avatar_url, name, timelines, absences }, i) => (
            <div
              key={id}
              className="divisiontable-row"
              ref={resourceRefs.current[i]}
              style={{
                height:
                  timelines.length *
                    (config.timeScheduleHeight +
                      config.spaceBetweenTimeSchedules) +
                  config.capacityHeight
              }}
            >
              <div
                className="divisiontable-profile"
                style={{ width: config.profileColumnWidth }}
              >
                <div className="divisiontable-profile-pic-container">
                  <Avatar src={avatar_url} size={45}>
                    {getAcronym(name)}
                  </Avatar>
                </div>
                <div className="divisiontable-profile-data">
                  <div className="divisiontable-profile-name">{name}</div>
                  <div className="divisiontable-profile-time-label">
                    Free time:
                  </div>
                  <div className="divisiontable-profile-time-value">
                    {renderFreeTimeValue(
                      workdayDatesOfSelectedDatePeriod,
                      timelines,
                      absences
                    )}
                  </div>
                </div>
              </div>

              {workdayDatesOfSelectedDatePeriod &&
                workdayDatesOfSelectedDatePeriod.map((day, index) => {
                  const isAbsenceDay = isAbsence(absences, day);
                  const isLastDayOfWeek =
                    (index + 1) % config.days.length === 0;
                  const isLastDayOfSelectedPeriod =
                    workdayDatesOfSelectedDatePeriod[index + 1] === undefined;
                  const isLastDayOfWeekBetweenWeeks =
                    isLastDayOfWeek && !isLastDayOfSelectedPeriod;

                  const filteredTimeline = timelines
                    .flat(1)
                    .map((timeline) =>
                      timeline.days.map((timelineDay) => ({
                        ...timelineDay,
                        project: timeline.project?.name ?? timeline.title,
                        description: timeline.description
                      }))
                    )
                    .flat(1)
                    .filter(
                      (timeline) =>
                        timeline.day.slice(0, 10) ===
                        day.format(DATE_FORMATS.DEFAULT)
                    );
                  return (
                    <div
                      key={day}
                      role="link"
                      tabIndex={0}
                      onClick={() =>
                        leftClickModalObj || isAbsenceDay
                          ? null
                          : dispatch(
                              changeModal('reservationFormModal', {
                                isVisible: true,
                                date: day.format('YYYY-MM-DD'),
                                resourceId: id,
                                absences
                              })
                            )
                      }
                      className={cc({
                        'divisiontable-row-time': true,
                        'divisiontable-row-time--today': isToday(day),
                        'divisiontable-row-time--last-day-of-week':
                          isLastDayOfWeekBetweenWeeks
                      })}
                      onDragOver={(e) => e.preventDefault()}
                    >
                      {isAbsenceDay && (
                        <div className="divisiontable-row-time--not-available">
                          {getAbsenceType(absences, day)}
                        </div>
                      )}

                      {!isAbsenceDay && (
                        <div className="divisiontable-total-time-per-day">
                          {filteredTimeline.length ? (
                            <Tooltip
                              placement="topLeft"
                              title={filteredTimeline.map((timeData) => (
                                <div key={timeData.id}>
                                  <div className="time">
                                    {minutesToFormattedDecimalHours(
                                      timeData.total_time
                                    )}
                                  </div>
                                  <div className="project">
                                    {timeData.project}
                                    <br />
                                    <span className="description">
                                      {timeData.description}
                                    </span>
                                  </div>
                                </div>
                              ))}
                            >
                              {renderBookedHours(timelines, day)}
                            </Tooltip>
                          ) : (
                            <span>{renderBookedHours(timelines, day)}</span>
                          )}
                        </div>
                      )}
                    </div>
                  );
                })}
              <div className="divisiontable-row-end" />
            </div>
          )
        )}
    </div>
  );
};

DivisionTable.propTypes = {
  division: PropTypes.shape({
    group_name: PropTypes.number.isRequired,
    resource_hit_count: PropTypes.number.isRequired,
    isVisible: PropTypes.bool.isRequired,
    items: PropTypes.arrayOf(
      PropTypes.shape({
        id: PropTypes.string
      })
    ).isRequired
  }).isRequired,
  workdayDatesOfSelectedDatePeriod: PropTypes.arrayOf(PropTypes.string)
    .isRequired,
  timelinesData: {
    items: PropTypes.arrayOf(
      PropTypes.shape({
        avatar_url: PropTypes.string,
        name: PropTypes.string,
        timelines: PropTypes.arrayOf(),
        absences: PropTypes.arrayOf()
      })
    )
  }.isRequired,
  setDates: PropTypes.func.isRequired,
  setResources: PropTypes.func.isRequired,
  changeDivisionVisibility: PropTypes.func.isRequired
};

export default DivisionTable;
