import moment from 'moment/moment';
import { useRecoilValue } from 'recoil';
import { DATE_FORMATS } from 'constants/constants';
import { projectsTableFiltersState } from 'store/atoms';
import { useMemo } from 'react';
import { Reservation } from './Reservation';

const getItemStyles = (initialOffset, xTranslation) => {
  const calendarGridLeft = 250;
  if (!initialOffset) {
    return { display: 'none' };
  }

  const x =
    xTranslation !== undefined
      ? calendarGridLeft + xTranslation
      : initialOffset.x;

  const transform = `translate(${x}px, ${initialOffset.y}px)`;
  return {
    transform,
    WebkitTransform: transform
  };
};

export const ReservationMoveDragLayer = ({ item, initialOffset }) => {
  const filters = useRecoilValue(projectsTableFiltersState);

  const { reservation, workday, hoveredDay, dateRange, width } = item;

  const thisPeriodsReservations = useMemo(
    () =>
      reservation.days.filter((day) => {
        const itemDay = moment(day.day, DATE_FORMATS.DEFAULT);
        return itemDay.isBetween(
          filters.from,
          moment(filters.from).add(filters.selectedWeekView * 6, 'd'),
          'day',
          '[]'
        );
      }),
    [reservation.days, filters.from, filters.selectedWeekView]
  );

  const draggedDayIndex = useMemo(
    () =>
      reservation.days
        .map((day) => day.day)
        .findIndex((day) => day === workday.format(DATE_FORMATS.DEFAULT)),
    [reservation.days, workday]
  );

  const hoveredDayIndex = useMemo(
    () =>
      dateRange
        ?.map((day) => day.format(DATE_FORMATS.DEFAULT))
        .findIndex((day) => day === hoveredDay.format(DATE_FORMATS.DEFAULT)),
    [dateRange, hoveredDay]
  );

  const widthOfSingleCell = useMemo(
    () => width / thisPeriodsReservations.length,
    [width, thisPeriodsReservations.length]
  );

  const xTranslation =
    hoveredDayIndex !== undefined
      ? (hoveredDayIndex - draggedDayIndex) * widthOfSingleCell
      : undefined;

  const itemStyles = getItemStyles(initialOffset, xTranslation);

  return (
    <div style={itemStyles}>
      <div style={{ width }}>
        <Reservation reservation={reservation} isAbsanceDay={false} />
      </div>
    </div>
  );
};
