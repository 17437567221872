import React, { Component } from 'react';
import { getPageTitle } from '../../utils/branding';

class Page404 extends Component {
  componentDidMount() {
    document.title = getPageTitle('Page not found');
  }

  render() {
    return <div className="page page-404">404: Page Not Found</div>;
  }
}

export default Page404;
