import { minutesToFormattedDecimalHours } from 'utils';
import { DATE_FORMATS } from 'constants/constants';
import { useProjectsTableApi } from '../../../hooks/api/useProjectsTableApi';

export const DailyTimesOfReservations = ({ day, workdays, project }) => {
  const { nationalHolidaysQuery } = useProjectsTableApi();
  const { data: nationalHolidays } = nationalHolidaysQuery;

  const holidayOnWorkday = nationalHolidays?.find(
    (holiday) => holiday.day === day.format(DATE_FORMATS.DEFAULT)
  );

  const reservationsWithMatchingDay = project.reservations
    .filter((res) =>
      res.days.some((d) => d.day === day.format(DATE_FORMATS.DEFAULT))
    )
    .map((res) => ({
      ...res,
      days: res.days.filter((d) => d.day === day.format(DATE_FORMATS.DEFAULT))
    }));

  const projectTime = reservationsWithMatchingDay
    .map((r) => r.days.map((d) => d))
    .flat()
    .reduce((acc, curr) => acc + curr.total_time, 0);

  return (
    <div
      className="text-gray-500 text-center py-3 font-semibold"
      style={{ width: `calc((100vw - 250px) / ${workdays.length})` }}
    >
      {!holidayOnWorkday && minutesToFormattedDecimalHours(projectTime)}
    </div>
  );
};
