import React, { useCallback } from 'react';
import { CaretLeftOutlined, CaretRightOutlined } from '@ant-design/icons';
import { DATE_FORMATS } from 'constants/constants';

export const WeekStepper = ({ firstDay, lastDay, onDateChange }) => {
  const handleDateChange = useCallback(
    (direction) => {
      if (direction === 'backward')
        return onDateChange(firstDay.subtract(7, 'd'));
      if (direction === 'forward') return onDateChange(firstDay.add(7, 'd'));
      return console.error('Unknown direction');
    },
    [firstDay, lastDay, onDateChange]
  );

  return (
    <div className="date-setter">
      <button
        aria-label="Set date"
        className="date-setter-button left"
        type="button"
        onClick={() => handleDateChange('backward')}
      >
        <CaretLeftOutlined />
      </button>
      <span className="date-setter-date">{`${firstDay.format(
        DATE_FORMATS.DEFAULT
      )} - ${lastDay.format(DATE_FORMATS.DEFAULT)}`}</span>
      <button
        aria-label="Set date"
        className="date-setter-button right"
        type="button"
        onClick={() => handleDateChange('forward')}
      >
        <CaretRightOutlined />
      </button>
    </div>
  );
};
