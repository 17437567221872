export const LOCAL_STORAGE_ITEMS = {
  ACCESS_TOKEN: 'accessToken'
};

export const DATE_FORMATS = {
  DEFAULT: 'YYYY-MM-DD',
  DAY: 'dddd',
  SHORT_DAY: 'ddd',
  MONTH_AND_DAY: 'MM.DD',
  DAY_OF_MONTH: 'D'
};

export const BOOKING_TYPES = {
  EQUAL_DAILY: 'equal_daily',
  TOTAL_OVER_PERIOD: 'total_over_period',
  VARIABLE_DAILY: 'variable_daily'
};

// @TODO These are meant to be a temporary mappings until the booking type names are updated on the backend.
export const TRANSLATE_TO_OLD_BOOKING_TYPES = {
  equal_daily: 'hours_per_day',
  total_over_period: 'total_time',
  variable_daily: 'custom'
};

export const TRANSLATE_FROM_OLD_BOOKING_TYPE = {
  hours_per_day: 'equal_daily',
  total_time: 'total_over_period',
  custom: 'variable_daily'
};

export const divisionColors = {
  cloud: {
    bg: 'bg-yellow-300',
    border: 'border-yellow-500',
    beforeBg: 'before:bg-yellow-300'
  },
  pm: {
    bg: 'bg-violet-300',
    border: 'border-violet-600',
    beforeBg: 'before:bg-violet-300'
  },
  'app development': {
    bg: 'bg-red-300',
    border: 'border-red-600',
    beforeBg: 'before:bg-red-300'
  },
  'ux/ui': {
    bg: 'bg-blue-300',
    border: 'border-blue-600',
    beforeBg: 'before:bg-blue-300'
  },
  webDevelopment: {
    bg: 'bg-green-300',
    border: 'border-green-600',
    beforeBg: 'before:bg-green-300'
  },
  testing: {
    bg: 'bg-orange-300',
    border: 'border-orange-600',
    beforeBg: 'before:bg-orange-300'
  },
  default: {
    bg: 'bg-teal-300',
    border: 'border-teal-600',
    beforeBg: 'before:bg-teal-300'
  }
};

export const timetableConfig = {
  days: ['mon', 'tue', 'wed', 'thu', 'fri'],
  profileColumnWidth: 254,
  timeScheduleHeight: 22,
  groupHeaderHeight: 35,
  capacityHeight: 40,
  spaceBetweenTimeSchedules: 4,
  requiredHours: 8
};

export const WEEK_SELECTOR_OPTIONS = [
  { label: '1 week view', value: 1 },
  { label: '2 weeks view', value: 2 },
  { label: '3 weeks view', value: 3 },
  { label: '4 weeks view', value: 4 }
];
