import React, { useState } from 'react';
import { CloseOutlined, ExportOutlined } from '@ant-design/icons';
import { Tag } from 'antd';
import { DebounceSelect } from '../DebounceSelect/DebounceSelect';
import { useApi } from '../../hooks/api/useApi';

export const TicketSelect = ({
  projectId,
  onSelect,
  currentValues,
  removeValue,
  disabled
}) => {
  const { getTickets } = useApi();
  const [value, setValue] = useState();

  const fetchTickets = async (searchKey) => {
    if (!projectId || !searchKey) return [];
    const tickets = await getTickets({ projectId, searchKey });
    if (!tickets.data.data.length) {
      return [];
    }
    return tickets.data.data.map((ticket) => ({
      label: ticket.summary,
      value: ticket.id
    }));
  };

  // const removeValue = (value) => {
  // onSelect({ value: null, label: null });
  // setValue(undefined);
  // };

  return (
    <>
      <DebounceSelect
        labelInValue
        placeholder="Select a ticket"
        className="w-full"
        showSearch
        allowClear
        value={value}
        disabled={disabled}
        fetchOptions={fetchTickets}
        onChange={setValue}
        projectId={projectId}
        onSelect={(ticket) => {
          onSelect(ticket);
          setValue(null);
        }}
      />
      {currentValues &&
        currentValues.map((currentValue) => (
          <div className="border-[#d9d9d9] border p-2 relative">
            <p className="font-bold text-black">{currentValue.value}</p>
            <p className="">{currentValue.label}</p>
            <div className="absolute top-0 right-0">
              {/* eslint-disable-next-line jsx-a11y/control-has-associated-label */}
              <a
                href={`${process.env.REACT_APP_JIRA_URL}/browse/${currentValue?.value}`}
                target="_blank"
                rel="noreferrer"
              >
                <Tag
                  className="bg-transparent border-none cursor-pointer mx-0 px-0 hover:text-primary"
                  icon={<ExportOutlined />}
                />
              </a>
              <Tag
                className="bg-transparent border-none cursor-pointer mx-0 px-0 hover:text-black"
                onClick={() => removeValue(currentValue.value)}
                icon={<CloseOutlined />}
              />
            </div>
          </div>
        ))}
    </>
  );
};
