import { formatTime } from 'utils';
import { useTimetable } from 'hooks/useTimetable';
import { getLeft, getRight } from '../utils';
import './BookingDraggable.scss';

const Side = ({ color, onDrag }) => (
  <div className={`side side-theme-${color}`} draggable onDrag={onDrag}>
    <span className={`side-divider side-divider-theme-${color}`} />
    <span className={`side-divider side-divider-theme-${color}`} />
  </div>
);

const getPositionStyles = (config, dates, reservation, resources, index) => {
  const top =
    resources[reservation.resource_id].top +
    config.capacityHeight +
    config.spaceBetweenTimeSchedules / 3 +
    index * (config.spaceBetweenTimeSchedules + config.timeScheduleHeight);

  const left = getLeft(
    dates,
    reservation.starts_at.slice(0, 10),
    config.spaceBetweenTimeSchedules / 2
  );

  const width =
    getRight(
      dates,
      reservation.ends_at.slice(0, 10),
      config.spaceBetweenTimeSchedules / 2
    ) - left;

  return {
    position: 'absolute',
    height: config.timeScheduleHeight,
    width,
    top,
    left
  };
};

export const BookingDraggable = ({
  index,
  reservation,
  onLeftSideDrag,
  onRightSideDrag,
  onDragStart,
  onDragEnd,
  onClick,
  dates,
  resources,
  draggable = true
}) => {
  const { config } = useTimetable();

  if (!resources[reservation.resource_id]) return null;

  const timeNote =
    reservation.distribution_base !== 'custom'
      ? `Daily: ${formatTime(reservation.time_per_day)}`
      : `Total time: ${formatTime(reservation.total_time)}`;

  const positionStyles = getPositionStyles(
    config,
    dates,
    reservation,
    resources,
    index
  );
  const color = reservation.project.color || 'default';

  return (
    <div
      className={`booking-draggable draggable-content draggable-content-theme-${color}`}
      draggable={draggable}
      onDragStart={onDragStart}
      onDragOver={(e) => e.preventDefault()}
      onDrag={(e) => e.preventDefault()}
      onDragEnd={onDragEnd}
      onClick={onClick}
      style={positionStyles}
    >
      <Side color={color} onDrag={onLeftSideDrag} />
      <div className="content">
        {reservation.project.name} | {timeNote}
      </div>
      <Side color={color} onDrag={onRightSideDrag} />
    </div>
  );
};
