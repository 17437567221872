export const getReservationById = (
  divisionsOfUsersOfReservations,
  reservationId
) => {
  const allUserReservations = Object.values(
    divisionsOfUsersOfReservations
  ).flatMap((division) => division.items);
  const allReservations = allUserReservations.flatMap(
    (userOfReservations) => userOfReservations.reservations
  );
  return (
    allReservations.find((reservation) => reservation.id === reservationId) ||
    null
  );
};
