import * as TYPES from 'constants/actionTypes';
import {
  BOOKING_TYPES,
  DATE_FORMATS,
  TRANSLATE_TO_OLD_BOOKING_TYPES
} from 'constants/constants';
import moment from 'moment';
import { all, call, put, select, takeEvery } from 'redux-saga/effects';
import * as API from 'services/api';
import * as config from 'services/config';
import { removeKeysWithValueOf } from 'services/data';
import { hoursAndMinutesToMinutes } from 'utils/time';

const getFilterStore = (state) => state.filterReducer;

const getSelectedProjects = (projects) =>
  projects
    .map((rootProject) => [rootProject, ...rootProject.children])
    .flat()
    .filter(({ checked }) => checked)
    .map(({ value }) => value);

const getSelectedDivisions = (divisions) =>
  divisions.filter(({ checked }) => checked).map(({ label }) => label);

const getSelectedResources = (resources) =>
  resources.filter(({ checked }) => checked).map(({ value }) => value);

const transformReservationToApiFormat = (data, resourceId) => {
  const baseData = removeKeysWithValueOf(
    {
      id: data.id,
      resource_id: resourceId || data.resourceId,
      project_id: data.selectedProject,
      starts_at: data.dateRange[0],
      ends_at: data.dateRange[1],
      distribution_base: TRANSLATE_TO_OLD_BOOKING_TYPES[data.bookingType],
      description: data.description,
      issue_id: data.selectedTicketId,
      issue_summary: data.selectedTicketSummary,
      selected_tickets: data.selectedTickets
    },
    undefined
  );
  switch (data.bookingType) {
    case BOOKING_TYPES.EQUAL_DAILY:
      return {
        data: {
          ...baseData,
          time_per_day: hoursAndMinutesToMinutes(data.durationEqualDaily)
        }
      };
    case BOOKING_TYPES.TOTAL_OVER_PERIOD:
      return {
        data: {
          ...baseData,
          total_time: hoursAndMinutesToMinutes(data.durationTotalOverPeriod)
        }
      };
    case BOOKING_TYPES.VARIABLE_DAILY:
      return {
        data: {
          ...baseData,
          days: data.datesAndDurationsVariableDaily.map((obj) => ({
            day: moment(obj.date).format(DATE_FORMATS.DEFAULT),
            total_time: hoursAndMinutesToMinutes(obj.duration)
          }))
        }
      };
    default:
      return { data: baseData };
  }
};

export function* fetchReservations(params = {}) {
  const {
    timePeriod,
    divisions,
    projects,
    resources,
    projectManagers,
    freeCapacity,
    overBooked,
    holiday
  } = yield select(getFilterStore);
  const queryData = {
    date_from: moment(params.dateFrom || timePeriod.firstDay).format(
      DATE_FORMATS.DEFAULT
    ),
    date_to: moment(params.dateTo || timePeriod.lastDay).format(
      DATE_FORMATS.DEFAULT
    ),
    groups: params.divisions || getSelectedDivisions(divisions),
    projects: params.projects || getSelectedProjects(projects),
    resources: params.resources || getSelectedResources(resources),
    pms: params.projectManagers || getSelectedResources(projectManagers),
    has_free_capacity: freeCapacity ? 1 : 0,
    overbooked: overBooked ? 1 : 0,
    has_absence: holiday ? 1 : 0
  };

  const qs = new URLSearchParams(queryData);
  const { data } = yield call(
    API.getData,
    `${config.host}/reservation-tree?${qs}`
  );
  return data;
}

export function* getReservations() {
  try {
    const { data } = yield call(API.getData, `${config.host}/reservation-tree`);
    yield put({
      type: TYPES.LIST_RESERVATIONS_S,
      payload: {
        data
      }
    });
  } catch (error) {
    console.error(error);
    yield put({
      type: TYPES.LIST_RESERVATIONS_F
    });
  }
}

export function* createReservation(action) {
  try {
    const { data, resourceIds } = action.payload;
    if (resourceIds) {
      yield all(
        resourceIds.map((resourceId) => {
          if (resourceId) {
            return call(
              API.postData,
              `${config.host}/reservation`,
              transformReservationToApiFormat(data, resourceId)
            );
          }
          return undefined;
        })
      );
    } else {
      yield call(
        API.postData,
        `${config.host}/reservation`,
        transformReservationToApiFormat(data)
      );
    }
    yield put({
      type: TYPES.CREATE_RESERVATION_S
    });

    yield call(getFilteredReservations); // eslint-disable-line no-use-before-define
  } catch (error) {
    console.error(error);
    yield put({
      type: TYPES.CREATE_RESERVATION_F
    });
  }
}

export function* updateReservation(action) {
  try {
    const { id, data } = action.payload;
    yield call(
      API.patchData,
      `${config.host}/reservation/${id}`,
      transformReservationToApiFormat(data)
    );
    yield put({
      type: TYPES.UPDATE_RESERVATION_S
    });
    yield call(getFilteredReservations); // eslint-disable-line no-use-before-define
  } catch (error) {
    console.error(error);
    yield put({
      type: TYPES.UPDATE_RESERVATION_F
    });
  }
}

export function* splitReservation(action) {
  try {
    const { id, data } = action.payload;
    yield call(API.patchData, `${config.host}/reservation/${id}/split`, data);
    yield put({
      type: TYPES.SPLIT_RESERVATION_S
    });
    yield call(getFilteredReservations); // eslint-disable-line no-use-before-define
  } catch (error) {
    console.error(error);
    yield put({
      type: TYPES.SPLIT_RESERVATION_F
    });
  }
}

export function* deleteReservation(action) {
  try {
    const { id } = action.payload;
    yield call(API.deleteData, `${config.host}/reservations/${id}`);
    yield put({
      type: TYPES.DELETE_RESERVATION_S
    });
    yield call(getFilteredReservations); // eslint-disable-line no-use-before-define
  } catch (error) {
    console.error(error);
    yield put({
      type: TYPES.DELETE_RESERVATION_F
    });
  }
}

export function* getFilteredReservations() {
  try {
    const data = yield call(fetchReservations);
    yield put({
      type: TYPES.LIST_RESERVATIONS_S,
      payload: {
        data
      }
    });
  } catch (error) {
    console.error(error);
    yield put({
      type: TYPES.LIST_RESERVATIONS_F
    });
  }
}

export function* getReservationsAfterDivisonChange() {
  try {
    const { divisions } = yield select(getFilterStore);
    const data = yield call(fetchReservations, {
      divisions: getSelectedDivisions(divisions)
    });
    yield put({
      type: TYPES.LIST_RESERVATIONS_S,
      payload: {
        data
      }
    });
  } catch (error) {
    console.error(error);
    yield put({
      type: TYPES.LIST_RESERVATIONS_F
    });
  }
}

export function* getReservationsOnProjectChange() {
  try {
    const { projects } = yield select(getFilterStore);
    const selectedProjects = getSelectedProjects(projects);
    const data = yield call(fetchReservations, {
      projects: selectedProjects
    });
    yield put({
      type: TYPES.LIST_RESERVATIONS_S,
      payload: {
        data
      }
    });
  } catch (error) {
    console.error(error);
    yield put({
      type: TYPES.LIST_RESERVATIONS_F
    });
  }
}

export function* getReservationsOnResourceChange() {
  try {
    const { resources } = yield select(getFilterStore);
    const selectedResources = getSelectedResources(resources);
    const data = yield call(fetchReservations, {
      resources: selectedResources
    });
    yield put({
      type: TYPES.LIST_RESERVATIONS_S,
      payload: {
        data
      }
    });
  } catch (error) {
    console.error(error);
    yield put({
      type: TYPES.LIST_RESERVATIONS_F
    });
  }
}

export function* getReservationsOnProjectManagerChange() {
  try {
    const { projectManagers } = yield select(getFilterStore);
    const selectedProjectManagers = getSelectedResources(projectManagers);
    const data = yield call(fetchReservations, {
      pms: selectedProjectManagers
    });
    yield put({
      type: TYPES.LIST_RESERVATIONS_S,
      payload: {
        data
      }
    });
  } catch (error) {
    console.error(error);
    yield put({
      type: TYPES.LIST_RESERVATIONS_F
    });
  }
}

export function* getReservationsOnCheckboxChange() {
  try {
    const data = yield call(fetchReservations);
    yield put({
      type: TYPES.LIST_RESERVATIONS_S,
      payload: {
        data
      }
    });
  } catch (error) {
    console.error(error);
    yield put({
      type: TYPES.LIST_RESERVATIONS_F
    });
  }
}

export const reservationSagas = [
  takeEvery(TYPES.LIST_RESERVATIONS_R, getReservations),
  takeEvery(TYPES.CREATE_RESERVATION_R, createReservation),
  takeEvery(TYPES.UPDATE_RESERVATION_R, updateReservation),
  takeEvery(TYPES.SPLIT_RESERVATION_R, splitReservation),
  takeEvery(TYPES.DELETE_RESERVATION_R, deleteReservation),
  takeEvery(TYPES.LIST_FILTERED_RESERVATIONS_R, getFilteredReservations),
  takeEvery(TYPES.FILTER_SET_SELECTED_PERIOD, getFilteredReservations),
  takeEvery(
    TYPES.FILTER_SET_SELECTED_DIVISIONS,
    getReservationsAfterDivisonChange
  ),
  takeEvery(TYPES.FILTER_SET_SELECTED_PROJECTS, getReservationsOnProjectChange),
  takeEvery(
    TYPES.FILTER_REMOVE_SELECTED_PROJECTS,
    getReservationsOnProjectChange
  ),
  takeEvery(
    TYPES.FILTER_SET_SELECTED_RESOURCES,
    getReservationsOnResourceChange
  ),
  takeEvery(
    TYPES.FILTER_SET_SELECTED_MANAGERS,
    getReservationsOnProjectManagerChange
  ),
  takeEvery(TYPES.FILTER_SET_CHECKBOX_VALUE, getReservationsOnCheckboxChange)
];
