import { takeEvery, call, put } from 'redux-saga/effects';
import {
  PROJECT_HIERACRCHY_R,
  PROJECT_HIERACRCHY_S,
  PROJECT_HIERACRCHY_F
} from 'constants/actionTypes';
import * as config from 'services/config';
import * as API from 'services/api';

const selectColor = (index) => {
  const projectColors = ['green', 'orange', 'blue', 'yellow', 'purple'];
  const colorIndex =
    index % projectColors.length !== 0
      ? (index - 1) % projectColors.length
      : projectColors.length - 1;

  return projectColors[colorIndex];
};

export const fakeProjects = (project, multiple) =>
  [...Array(multiple).keys()].map((item, index) => ({
    id: 100 + index,
    items: project.items,
    name: `${project.name} - ${index + 1}`,
    status: true,
    color: selectColor(index + 1)
  }));

export function* getProjectHierarchy() {
  try {
    const { data } = yield call(
      API.getData,
      `${config.host}/projects/hierarchy`
    );
    yield put({
      type: PROJECT_HIERACRCHY_S,
      payload: {
        data // : fakeProjects(data[0], 6), // TODO: remove fake
      }
    });
  } catch (error) {
    console.error(error);
    yield put({
      type: PROJECT_HIERACRCHY_F
    });
  }
}

export const projectSagas = [
  takeEvery(PROJECT_HIERACRCHY_R, getProjectHierarchy)
];
