/* eslint-disable */
const keysToPreserve = ['filterReducer'];

const getObjectsByKeys = (object) =>
  keysToPreserve.reduce((result, key) => {
    if (object.hasOwnProperty(key)) {
      result[key] = object[key];
    }
    return result;
  }, {});

export const loadState = () => {
  try {
    const serializedState = sessionStorage.getItem('state');

    if (serializedState === null) {
      return undefined;
    }

    return JSON.parse(serializedState);
  } catch (error) {
    return undefined;
  }
};

export const saveState = (state) => {
  const valuesToPreserve = getObjectsByKeys(state);
  try {
    const serializedState = JSON.stringify(valuesToPreserve);
    sessionStorage.setItem('state', serializedState);
  } catch (error) {
    // Ignore write errors.
  }
};
