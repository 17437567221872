import { changeDivisionVisibility } from 'actions/reservationActions';
import { DATE_FORMATS } from 'constants/constants';
import { useTimetable } from 'hooks/useTimetable';
import PropTypes from 'prop-types';
import { Children, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { CalendarEvent } from './CalendarEvent/CalendarEvent';
import DivisionTable from './DivisionTable/DivisionTable';
import { BookingDraggable } from './BookingDraggable/BookingDraggable';
import LeftClickMenu from './LeftClickMenu/LeftClickMenu';
import './timetable.scss';
import {
  createNewTimelineDate,
  createNewTimelineDateExpand,
  createNewTimelineResource,
  createTimelines,
  fillDateRangeWithDaysOf,
  getNewDate
} from './utils';

const Timetable = ({
  divisionsOfUsersOfReservations,
  datePeriod,
  handleDragEnd
}) => {
  const dispatch = useDispatch();
  const {
    reservation: { divisionVisibilities }
  } = useSelector((store) => store);
  const { config } = useTimetable();

  const [
    workdayDatesOfSelectedDatePeriod,
    setWorkdayDatesOfSelectedDatePeriod
  ] = useState(fillDateRangeWithDaysOf(datePeriod, config.days));
  const [timelines, setTimelines] = useState(
    createTimelines(divisionsOfUsersOfReservations)
  );
  const [dates, setDates] = useState(null);
  const [resources, setResources] = useState(null);
  const [dragged, setDragged] = useState(null);
  const [leftClickModalObj, setLeftClickModalObj] = useState(null);
  const [toDateIndex, setToDateIndex] = useState(null);

  useEffect(() => {
    setWorkdayDatesOfSelectedDatePeriod(
      fillDateRangeWithDaysOf(datePeriod, config.days)
    );
  }, [datePeriod]);

  useEffect(() => {
    setTimelines(createTimelines(divisionsOfUsersOfReservations));
  }, [divisionsOfUsersOfReservations]);

  return (
    <div className="timetable">
      {divisionsOfUsersOfReservations &&
        timelines &&
        divisionsOfUsersOfReservations.map((division) => (
          <DivisionTable
            key={division.group_name}
            division={division}
            initValues={config}
            workdayDatesOfSelectedDatePeriod={workdayDatesOfSelectedDatePeriod}
            timelinesData={timelines.find(
              (timeline) => timeline.group_name === division.group_name
            )}
            leftClickModalObj={leftClickModalObj}
            setDates={(datesArr) =>
              setDates(
                datesArr.reduce(
                  (prev, { left, right, date, i }) => ({
                    ...prev,
                    [date]: { left, right, i }
                  }),
                  {
                    left: datesArr[0].left,
                    right: datesArr[datesArr.length - 1].right
                  }
                )
              )
            }
            setResources={(resoursesArr) =>
              setResources((previous) =>
                resoursesArr.reduce(
                  (prev, { top, bottom, id }) => ({
                    ...prev,
                    [id]: { top, bottom }
                  }),
                  { ...previous }
                )
              )
            }
            changeDivisionVisibility={() =>
              dispatch(changeDivisionVisibility(division.group_name))
            }
          />
        ))}
      {resources !== null &&
        dates &&
        timelines &&
        Children.toArray(
          timelines.map(
            (groups) =>
              divisionVisibilities[groups.group_name] &&
              groups.items.map((resource) =>
                resource.timelines.map((timeline, i) =>
                  timeline
                    .map((reservation) => ({
                      ...reservation,
                      i
                    }))
                    .flat(5)
                    .sort((a, b) => +a.id - +b.id)
                    .map((reservation) => {
                      if (reservation.type === 'calendar-event') {
                        return (
                          resources[reservation.resource_id] && (
                            <CalendarEvent
                              dates={dates}
                              resources={resources}
                              index={i}
                              reservation={reservation}
                            />
                          )
                        );
                      }
                      return (
                        resources[reservation.resource_id] && (
                          <BookingDraggable
                            draggable={reservation.writeable}
                            key={reservation.id}
                            index={i}
                            reservation={reservation}
                            dates={dates}
                            resources={resources}
                            onDragStart={(e) => {
                              e.dataTransfer.effectAllowed = 'move';
                              setDragged({
                                target: reservation,
                                id: Object.entries(resources).reduce(
                                  (prev, [key, { top, bottom }]) =>
                                    e.pageY > top && e.pageY < bottom
                                      ? key
                                      : prev,
                                  0
                                ),
                                date: Object.keys(dates)
                                  .map((date) => {
                                    if (
                                      dates[date].left < e.pageX &&
                                      dates[date].right > e.pageX
                                    )
                                      return date;
                                    return undefined;
                                  })
                                  .filter((d) => d)[0]
                              });
                            }}
                            onDragEnd={(e) => {
                              e.preventDefault();
                              e.stopPropagation();

                              const toDate = Object.values(dates).find(
                                (date) =>
                                  date.left < e.pageX && date.right > e.pageX
                              );
                              const originalDatePosition = dates[dragged.date];
                              const amount = toDate.i - originalDatePosition.i;

                              if (dragged.isLeftSideDrag) {
                                const { newTimeline, newReservation } =
                                  createNewTimelineDateExpand(
                                    timelines,
                                    dragged,
                                    amount,
                                    true,
                                    config.days
                                  );
                                setTimelines(createTimelines(newTimeline));
                                setDragged((prev) => ({
                                  ...prev,
                                  date: getNewDate(
                                    prev.date,
                                    config.days,
                                    amount
                                  ).format(DATE_FORMATS.DEFAULT),
                                  target: newReservation
                                }));

                                handleDragEnd(newReservation);
                                setDragged(null);
                                return;
                              }

                              if (dragged.isRightSideDrag) {
                                const { newTimeline, newReservation } =
                                  createNewTimelineDateExpand(
                                    timelines,
                                    dragged,
                                    amount,
                                    false,
                                    config.days
                                  );
                                setTimelines(createTimelines(newTimeline));
                                setDragged((prev) => ({
                                  ...prev,
                                  date: getNewDate(
                                    prev.date,
                                    config.days,
                                    amount
                                  ).format(DATE_FORMATS.DEFAULT),
                                  target: newReservation
                                }));

                                handleDragEnd(newReservation);
                                setDragged(null);
                                return;
                              }

                              const hasBeenMovedVertically =
                                e.pageY < resources[dragged.id].top ||
                                e.pageY > resources[dragged.id].bottom;

                              const currentDate = dates[dragged.date];
                              const hasBeenMovedHorizontally =
                                e.pageX < currentDate?.left ||
                                e.pageX > currentDate?.right;

                              let target = null;

                              if (hasBeenMovedVertically) {
                                const { newTimeline, newResource } =
                                  createNewTimelineResource(
                                    timelines,
                                    dragged,
                                    Object.entries(resources).reduce(
                                      (prev, [key, { top, bottom }]) =>
                                        e.pageY < bottom && e.pageY > top
                                          ? key
                                          : prev,
                                      dragged.target.resource_id
                                    )
                                  );
                                setTimelines(createTimelines(newTimeline));
                                target = newResource;
                                setDragged({
                                  ...dragged,
                                  id: newResource.resource_id,
                                  target
                                });
                              }
                              if (hasBeenMovedHorizontally && toDate) {
                                const { newReservation, newTimeline } =
                                  createNewTimelineDate(
                                    timelines,
                                    dragged,
                                    amount,
                                    config.days
                                  );
                                setTimelines(createTimelines(newTimeline));
                                target = newReservation;
                                setDragged({
                                  ...dragged,
                                  date: getNewDate(
                                    dragged.date,
                                    config.days,
                                    amount
                                  ).format(DATE_FORMATS.DEFAULT),
                                  target
                                });
                              }
                              handleDragEnd(target);
                              setDragged(null);
                            }}
                            onClick={(e) => {
                              e.preventDefault();
                              e.stopPropagation();
                              if (
                                leftClickModalObj?.reservation.id ===
                                reservation.id
                              ) {
                                setLeftClickModalObj(null);
                                return;
                              }
                              const dateByClickPosition = Object.entries(
                                dates
                              ).find(
                                ([, { left, right }]) =>
                                  e.pageX > left && e.pageX < right
                              );

                              setLeftClickModalObj({
                                position: {
                                  y: e.pageY,
                                  x:
                                    e.pageX + 350 > window.innerWidth
                                      ? { right: 0 }
                                      : { left: e.pageX }
                                },
                                reservation,
                                absences: resource.absences || [],
                                actualDate: dateByClickPosition
                                  ? dateByClickPosition[0]
                                  : 0
                              });
                            }}
                            onLeftSideDrag={(e) => {
                              e.stopPropagation();
                              setDragged((prev) => ({
                                ...prev,
                                isLeftSideDrag: true
                              }));

                              const originalDatePosition = dates[dragged.date];
                              if (!e.pageX || !originalDatePosition) return;

                              const isDaysIncrease =
                                e.pageX < originalDatePosition.left;
                              const isDaysDecrease =
                                e.pageX > originalDatePosition.right;
                              const isMultipleDaysReservation =
                                dragged.target.days.length > 1;

                              if (
                                isDaysIncrease ||
                                (isDaysDecrease && isMultipleDaysReservation)
                              ) {
                                const toDate = Object.values(dates).find(
                                  (date) =>
                                    date.left < e.pageX && date.right > e.pageX
                                );

                                if (toDate && toDateIndex !== toDate.i) {
                                  setToDateIndex(toDate.i);
                                }
                              }
                            }}
                            onRightSideDrag={(e) => {
                              e.stopPropagation();
                              setDragged((prev) => ({
                                ...prev,
                                isRightSideDrag: true
                              }));

                              const originalDatePosition = dates[dragged.date];
                              if (!e.pageX || !originalDatePosition) return;

                              const isDaysIncrease =
                                e.pageX > originalDatePosition.right;
                              const isDaysDecrease =
                                e.pageX < originalDatePosition.left;
                              const isMultipleDaysReservation =
                                dragged.target.days.length > 1;

                              if (
                                (isDaysDecrease && isMultipleDaysReservation) ||
                                isDaysIncrease
                              ) {
                                const toDate = Object.values(dates).find(
                                  (date) =>
                                    date.left < e.pageX && date.right > e.pageX
                                );
                                if (toDate && toDateIndex !== toDate.i) {
                                  setToDateIndex(toDate.i);
                                }
                              }
                            }}
                          />
                        )
                      );
                    })
                )
              )
          )
        )}
      {leftClickModalObj && (
        <div
          style={{
            top: leftClickModalObj.position.y,
            ...leftClickModalObj.position.x,
            position: 'absolute',
            zIndex: 100
          }}
        >
          <LeftClickMenu
            reservation={leftClickModalObj.reservation}
            theme={leftClickModalObj.reservation.project.color || 'default'}
            absences={leftClickModalObj.absences}
            onClose={() => setLeftClickModalObj(null)}
            actualDate={leftClickModalObj.actualDate}
          />
        </div>
      )}
    </div>
  );
};

Timetable.propTypes = {
  datePeriod: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  divisionsOfUsersOfReservations: PropTypes.arrayOf(PropTypes.shape({})),
  handleDragEnd: PropTypes.func.isRequired
};

Timetable.defaultProps = {
  divisionsOfUsersOfReservations: []
};

export default Timetable;
