import {
  queryOptions,
  useMutation,
  useQuery,
  useQueryClient
} from '@tanstack/react-query';
import { useRecoilValue } from 'recoil';
import { toast } from 'react-toastify';
import { projectsTableFiltersState } from '../../store/atoms';
import { useApi } from './useApi';

export const useProjectsTableApi = () => {
  const queryClient = useQueryClient();

  const {
    createReservation,
    patchReservation,
    getProjectsTableData,
    getNationalHolidays,
    deleteReservation
  } = useApi();

  const filters = useRecoilValue(projectsTableFiltersState);

  const projectsTableOptions = queryOptions({
    queryKey: ['projectsTableData', filters],
    queryFn: () => getProjectsTableData(filters),
    keepPreviousData: true
  });

  const projectsTableDataQuery = useQuery(projectsTableOptions);

  const nationalHolidaysQuery = useQuery({
    queryKey: ['nationalHolidays', filters],
    queryFn: () =>
      getNationalHolidays({
        from: filters.from,
        selectedWeekView: filters.selectedWeekView
      })
  });

  const addReservationMutation = useMutation({
    mutationFn: ({ updatedFields }) => {
      return createReservation({
        data: updatedFields
      });
    },
    onError: (err) => {
      console.error(err);
      toast.error('Oops, something went wrong!');
    },
    onSettled: () => {
      queryClient.invalidateQueries({ queryKey: ['projectsTableData'] });
    }
  });

  const updateReservationMutation = useMutation({
    mutationFn: (data) => {
      return patchReservation(data);
    },
    onError: (err) => {
      console.error(err);
      toast.error('Oops, something went wrong!');
    },
    onSettled: () => {
      queryClient.invalidateQueries({ queryKey: ['projectsTableData'] });
    }
  });

  const deleteReservationMutation = useMutation({
    mutationFn: deleteReservation,
    onMutate: async (id) => {
      await queryClient.cancelQueries(projectsTableOptions);
      const previousTableData = queryClient.getQueryData(
        projectsTableOptions.queryKey
      );

      if (previousTableData) {
        const filteredTableData = previousTableData.map((group) => ({
          ...group,
          projects: group.projects.map((project) => ({
            ...project,
            reservations: project.reservations.filter(
              (reservation) => reservation.id !== id
            )
          }))
        }));

        queryClient.setQueryData(
          projectsTableOptions.queryKey,
          filteredTableData
        );
      }

      return previousTableData;
    },
    onError: (err, variables, context) => {
      console.error(err);
      toast.error('Oops, something went wrong!');
      if (context?.previousTableData) {
        queryClient.setQueryData(
          ['projectsTableData'],
          context.previousTableData
        );
      }
    },
    onSettled: () => {
      queryClient.invalidateQueries({ queryKey: ['projectsTableData'] });
    }
  });

  return {
    projectsTableDataQuery,
    nationalHolidaysQuery,
    addReservationMutation,
    updateReservationMutation,
    deleteReservationMutation
  };
};
