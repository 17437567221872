import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import moment from 'moment';
import { Divider, Radio, Input } from 'antd';
import { DeleteFilled, PlusCircleFilled } from '@ant-design/icons';
import {
  DATE_FORMATS,
  BOOKING_TYPES,
  TRANSLATE_TO_OLD_BOOKING_TYPES,
  TRANSLATE_FROM_OLD_BOOKING_TYPE
} from 'constants/constants';
import { changeModal } from 'actions/userActions';
import {
  createReservation,
  updateReservation,
  deleteReservation
} from 'actions/reservationActions';
import { getReservationById } from 'services/reservation';
import { minutesToHoursAndMinutes } from 'utils/time';
import Button from 'components/Button/Button';
import Modal from 'components/Modal/Modal';
import Select from 'components/Select/Select';
import Datepicker from 'components/Datepicker/Datepicker';
import Duration from './Duration';
import { getVariableDailyDurations } from './services';
import './ReservationFormModal.scss';
import { TicketSelect } from '../TicketSelect/TicketSelect';

const { TextArea } = Input;

const ReservationFormModal = () => {
  const dispatch = useDispatch();

  const { isVisible, reservationId, resourceId, date, absences } = useSelector(
    (store) => store.modals.reservationFormModal
  );
  const { resources, projects } = useSelector((store) => store.filterReducer);
  const { divisionsOfUsersOfReservations } = useSelector(
    (store) => store.reservation
  );
  const initialState = {
    selectedResourceIds: [undefined],
    selectedProject: undefined,
    // selectedTicketId: undefined,
    // selectedTicketSummary: undefined,
    selectedTickets: [],
    dateRange: null,
    description: undefined,
    bookingType: BOOKING_TYPES.EQUAL_DAILY,
    durationEqualDaily: {
      hours: 0,
      minutes: 0
    },
    durationTotalOverPeriod: {
      hours: 0,
      minutes: 0
    },
    datesAndDurationsVariableDaily: [],
    writeable: true
  };
  const [booking, setBooking] = useState(initialState);

  const setBookingLog = (data) => {
    setBooking(data);
  };

  const setBookingProp = (inputName, value) => {
    setBooking({
      ...booking,
      [inputName]: value
    });
  };

  const setResource = (newId, newIdIndex) => {
    setBooking({
      ...booking,
      selectedResourceIds: booking.selectedResourceIds.map((id, index) =>
        index === newIdIndex ? newId : id
      )
    });
  };

  const addEmptyResource = () => {
    setBooking({
      ...booking,
      selectedResourceIds: [...booking.selectedResourceIds, undefined]
    });
  };

  useEffect(() => {
    const editedReservation = getReservationById(
      divisionsOfUsersOfReservations,
      reservationId
    );
    if (editedReservation) {
      const durationEqualDaily =
        editedReservation.distribution_base ===
        TRANSLATE_TO_OLD_BOOKING_TYPES[BOOKING_TYPES.EQUAL_DAILY]
          ? minutesToHoursAndMinutes(editedReservation.time_per_day)
          : { hours: 0, minutes: 0 };

      const durationTotalOverPeriod =
        editedReservation.distribution_base ===
        TRANSLATE_TO_OLD_BOOKING_TYPES[BOOKING_TYPES.TOTAL_OVER_PERIOD]
          ? minutesToHoursAndMinutes(editedReservation.total_time)
          : { hours: 0, minutes: 0 };

      const datesAndDurationsVariableDaily =
        editedReservation.distribution_base ===
        TRANSLATE_TO_OLD_BOOKING_TYPES[BOOKING_TYPES.VARIABLE_DAILY]
          ? editedReservation.days.map((day) => ({
              date: day.day,
              duration: minutesToHoursAndMinutes(day.total_time)
            }))
          : [];

      setBooking({
        selectedResourceIds: [editedReservation.resource_id],
        selectedProject: editedReservation.project_id,
        selectedTicketId: editedReservation.issue_id,
        selectedTicketSummary: editedReservation.issue_summary,
        selectedTickets: editedReservation.selected_tickets,
        dateRange: [
          moment(editedReservation.starts_at),
          moment(editedReservation.ends_at)
        ],
        description: editedReservation.description,
        bookingType:
          TRANSLATE_FROM_OLD_BOOKING_TYPE[editedReservation.distribution_base],
        durationEqualDaily,
        durationTotalOverPeriod,
        datesAndDurationsVariableDaily,
        writeable: editedReservation.writeable
      });
    } else if (resourceId && date) {
      setBooking({
        ...initialState,
        selectedResourceIds: [resourceId],
        dateRange: [moment(date), moment(date)]
      });
    }
  }, [reservationId, resourceId, date]);

  useEffect(() => {
    setBookingProp(
      'datesAndDurationsVariableDaily',
      getVariableDailyDurations(
        booking.dateRange,
        booking.datesAndDurationsVariableDaily,
        booking.selectedResourceIds,
        absences
      )
    );
  }, [booking.dateRange, booking.selectedResourceIds]);

  useEffect(() => {
    if (!isVisible) {
      setBooking(initialState);
    }
  }, [isVisible]);

  const isEditingReservation = reservationId !== undefined;

  const isInvalidBooking = () => {
    const { selectedResourceIds, selectedProject, dateRange } = booking;

    const firstDayOfActualWeek = moment().weekday(1);

    const hasValidResource = () =>
      selectedResourceIds.some((value) => Number.isInteger(value));

    const hasInvalidProject = () => !Number.isInteger(selectedProject);

    const isFirstDayOfWeekSameOrAfterStartDate = () => {
      if (!dateRange?.length) return false;
      return moment(firstDayOfActualWeek.format('YYYY-MM-DD')).isSameOrBefore(
        moment(dateRange[0].format('YYYY-MM-DD'))
      );
    };

    const hasInvalidDateRange = () =>
      !Array.isArray(dateRange) ||
      dateRange.some((value) => !moment.isMoment(value));

    const hasInvalidDuration = () => {
      switch (booking.bookingType) {
        case BOOKING_TYPES.EQUAL_DAILY:
          return (
            booking.durationEqualDaily.hours === 0 &&
            booking.durationEqualDaily.minutes === 0
          );
        case BOOKING_TYPES.TOTAL_OVER_PERIOD:
          return (
            booking.durationTotalOverPeriod.hours === 0 &&
            booking.durationTotalOverPeriod.minutes === 0
          );
        case BOOKING_TYPES.VARIABLE_DAILY:
          return (
            booking.datesAndDurationsVariableDaily.length === 0 ||
            booking.datesAndDurationsVariableDaily.some(
              (obj) => obj.duration.hours === 0 && obj.duration.minutes === 0
            )
          );
        default:
          throw new Error(
            'Should have checked if duration is empty and retured a boolean.'
          );
      }
    };

    return (
      !booking.writeable ||
      !hasValidResource() ||
      hasInvalidProject() ||
      hasInvalidDateRange() ||
      hasInvalidDuration() ||
      !isFirstDayOfWeekSameOrAfterStartDate()
    );
  };

  const handleBookingTypeSelection = (e) => {
    setBookingLog({
      ...booking,
      bookingType: e.target.value,
      durationEqualDaily: {
        hours: 0,
        minutes: 0
      },
      durationTotalOverPeriod: {
        hours: 0,
        minutes: 0
      },
      datesAndDurationsVariableDaily: getVariableDailyDurations(
        booking.dateRange,
        booking.datesAndDurationsVariableDaily,
        booking.selectedResourceIds,
        absences
      )
    });
  };

  const renderResourceSelectFields = () =>
    booking.selectedResourceIds.map((value, i) => {
      const resourcesNotSelectedInOtherSelectFields = resources.filter(
        (resource) =>
          resource.value === booking.selectedResourceIds[i] ||
          !booking.selectedResourceIds.includes(resource.value)
      );

      return (
        <Select
          disabled={!booking.writeable}
          key={value ?? Math.random()} // If resources haven't been selected yet, let the key be random, instead of undefined, to prevent "no unique key" error.
          placeholder="Select a resource"
          dataSource={resourcesNotSelectedInOtherSelectFields}
          onChange={(newValue) => setResource(newValue, i)}
          value={value}
        />
      );
    });

  const renderInputsForVariableDaily = () => {
    const arr = getVariableDailyDurations(
      booking.dateRange,
      booking.datesAndDurationsVariableDaily,
      booking.selectedResourceIds,
      absences
    );

    if (
      arr.length === 0 ||
      arr.length !== booking.datesAndDurationsVariableDaily.length
    ) {
      return null;
    }

    return (
      <>
        {arr.map((obj, i) => (
          <div key={obj.date} className="date-and-duration">
            <div className="date">
              <span className="day">
                {moment(obj.date).format(DATE_FORMATS.DAY)}
              </span>
              <time dateTime={moment(obj.date).format()}>
                {moment(obj.date).format(DATE_FORMATS.MONTH_AND_DAY)}
              </time>
            </div>

            <Duration
              booking={booking}
              setBookingProp={setBookingProp}
              bookingType="variable_daily"
              index={i}
            />
          </div>
        ))}
      </>
    );
  };

  return (
    <Modal
      title={isEditingReservation ? 'Edit booking' : 'Add new booking'}
      className="booking-modal"
      visible={isVisible}
      close={() => {
        dispatch(changeModal('reservationFormModal', { isVisible: false }));
      }}
    >
      <div className="body">
        <div className="label">RESOURCE</div>

        {renderResourceSelectFields()}

        {!isEditingReservation && (
          <Button
            title="ADD MORE RESOURCES"
            className="text-primary"
            icon={<PlusCircleFilled style={{ fontSize: 24 }} />}
            minWidth={194}
            onClick={() => {
              addEmptyResource();
            }}
          />
        )}

        <div className="label">CHOOSE PROJECT</div>

        <Select
          disabled={!booking.writeable}
          placeholder="Select a project"
          dataSource={projects.filter((val) => val?.status)}
          onChange={(value) => setBookingProp('selectedProject', value)}
          value={booking.selectedProject}
        />

        <div className="label">CHOOSE TICKET</div>

        <TicketSelect
          disabled={!booking.selectedProject || !booking.writeable}
          projectId={booking.selectedProject}
          currentValues={booking.selectedTickets || []}
          onSelect={(ticket) => {
            if (
              booking.selectedTickets.filter(
                (item) => item.value === ticket.value
              ).length
            ) {
              return;
            }
            const selectedTickets = [
              ...booking.selectedTickets,
              { value: ticket.value, label: ticket.label }
            ];
            // console.log(selectedTickets);
            setBooking((prev) => ({
              ...prev,
              selectedTickets
            }));
          }}
          removeValue={(value) => {
            const selectedTickets = booking.selectedTickets.filter(
              (item) => item.value !== value
            );
            setBooking((prev) => ({
              ...prev,
              selectedTickets
            }));
          }}
        />
        <div className="label">START DATE & DUE DATE</div>

        <Datepicker
          disabled={!booking.writeable}
          onChange={(values) => setBookingProp('dateRange', values)}
          className="date-picker-class"
          dropdownClassName="date-picker-dropdown-class"
          allowClear
          value={booking.dateRange}
        />

        <div className="label">DESCRIPTION</div>

        <TextArea
          disabled={!booking.writeable}
          autoSize={{ minRows: 2, maxRows: 6 }}
          onChange={(e) => {
            setBookingProp('description', e.target.value);
          }}
          placeholder="Write description"
          value={booking.description}
        />

        <Divider />

        <div className="effort-container">
          <div className="label">EFFORT</div>

          <Radio.Group
            onChange={handleBookingTypeSelection}
            value={booking.bookingType}
            disabled={!booking.writeable}
          >
            <Radio value="equal_daily">
              <div className="booking-type">
                <p>SAME DAILY HOURS</p>

                <Duration
                  disabled={!booking.writeable}
                  booking={booking}
                  setBookingProp={setBookingProp}
                  bookingType="equal_daily"
                  min={0}
                  max={8}
                />
              </div>
            </Radio>

            <Radio value="total_over_period">
              <div className="booking-type">
                <p>TOTAL HOURS OVER PERIOD</p>

                <Duration
                  booking={booking}
                  setBookingProp={setBookingProp}
                  bookingType="total_over_period"
                  min={0}
                  max={1000}
                />
              </div>
            </Radio>

            <Radio value="variable_daily">
              <div className="booking-type booking-type-variable">
                <p>VARIABLE DAILY HOURS</p>

                {booking.bookingType === BOOKING_TYPES.VARIABLE_DAILY &&
                  renderInputsForVariableDaily()}
              </div>
            </Radio>
          </Radio.Group>
        </div>
      </div>

      <Divider />

      <div className="footer">
        {isEditingReservation && (
          <Button
            disabled={!booking.writeable}
            title="DELETE"
            color="#000000"
            customStyle={{ opacity: 0.6 }}
            icon={<DeleteFilled style={{ color: '#4a33d9' }} />}
            minWidth={68}
            onClick={() => {
              if (
                confirm('Are you sure you want to delete this booking?') ===
                true
              ) {
                // eslint-disable-line no-restricted-globals
                dispatch(deleteReservation(reservationId));
                dispatch(
                  changeModal('reservationFormModal', { isVisible: false })
                );
              }
            }}
          />
        )}

        <div className="action-buttons">
          <Button
            title="Cancel"
            className="border border-primary text-primary"
            minWidth={120}
            onClick={() => {
              dispatch(
                changeModal('reservationFormModal', { isVisible: false })
              );
              setBooking(initialState);
            }}
          />

          <Button
            title="Save"
            disabled={isInvalidBooking()}
            minWidth={106}
            className="bg-primary text-white"
            onClick={() => {
              if (isEditingReservation) {
                dispatch(
                  updateReservation(reservationId, {
                    ...booking,
                    resourceId: booking.selectedResourceIds[0],
                    dateRange: [
                      moment(booking.dateRange[0]).format(DATE_FORMATS.DEFAULT),
                      moment(booking.dateRange[1]).format(DATE_FORMATS.DEFAULT)
                    ]
                  })
                );
              } else {
                dispatch(
                  createReservation(
                    {
                      ...booking,
                      dateRange: [
                        moment(booking.dateRange[0]).format(
                          DATE_FORMATS.DEFAULT
                        ),
                        moment(booking.dateRange[1]).format(
                          DATE_FORMATS.DEFAULT
                        )
                      ]
                    },
                    booking.selectedResourceIds
                  )
                );
              }
              dispatch(
                changeModal('reservationFormModal', { isVisible: false })
              );
              setBooking(initialState);
            }}
          />
        </div>
      </div>
    </Modal>
  );
};

export default ReservationFormModal;
