import { Avatar } from 'antd';
import React, { useEffect, useRef } from 'react';
import { formatTime, getAcronym, getDivisionClass } from 'utils';
import { useDrag, useDragLayer } from 'react-dnd';
import cc from 'classcat';
import { getEmptyImage } from 'react-dnd-html5-backend';
import { DragTypes } from '../ProjectsTable/ProjectDay/ProjectDay';
import classes from './Reservation.module.css';

export const Reservation = ({ reservation, isAbsenceDay, isPreview }) => {
  const reservationRef = useRef(null);

  const [, startDrag, startDragPreview] = useDrag(() => ({
    type: DragTypes.START_CHANGE,
    item: () => ({
      reservation,
      elementRect: reservationRef.current?.getBoundingClientRect(),
      width: reservationRef.current?.offsetWidth
    })
  }));

  const [, endDrag, endDragPreview] = useDrag(() => ({
    type: DragTypes.END_CHANGE,
    item: () => ({
      reservation,
      elementRect: reservationRef.current?.getBoundingClientRect(),
      width: reservationRef.current?.offsetWidth
    })
  }));

  const { isDragging, item, itemType } = useDragLayer((monitor) => ({
    item: monitor.getItem(),
    isDragging: monitor.isDragging(),
    itemType: monitor.getItemType()
  }));

  const isVisuallyHidden =
    !isPreview &&
    isDragging &&
    (itemType === DragTypes.END_CHANGE ||
      itemType === DragTypes.START_CHANGE) &&
    item.reservation.id === reservation.id;

  useEffect(() => {
    startDragPreview(getEmptyImage(), { captureDraggingState: true });
    endDragPreview(getEmptyImage(), { captureDraggingState: true });
  }, []);

  return (
    <div
      ref={reservationRef}
      className={cc({
        [getDivisionClass(reservation.resource.group).bg]: true,
        [getDivisionClass(reservation.resource.group).border]: true,
        [classes.absence]: isAbsenceDay,
        'opacity-0': isVisuallyHidden,
        'border cursor-move select-none rounded-xl text-center flex flex-row justify-between h-[35px]': true
      })}
    >
      {reservation.distribution_base !== 'custom' && (
        <div
          ref={startDrag}
          className="font-black px-2 flex flex-col justify-center cursor-ew-resize"
        >
          <div className="flex flex-row gap-0.5">
            <div
              className={`h-4 border ${
                getDivisionClass(reservation.resource.group).border
              }`}
            />
            <div
              className={`h-4 border ${
                getDivisionClass(reservation.resource.group).border
              }`}
            />
          </div>
        </div>
      )}
      <div className="overflow-hidden px-2 uppercase flex flex-col justify-center mx-auto cursor-pointer">
        <div className="text-ellipsis whitespace-nowrap overflow-hidden font-semibold ">
          <Avatar
            className="mr-2"
            src={reservation.resource.avatar_url}
            size={25}
          >
            {getAcronym(reservation.resource.name)}
          </Avatar>
          {reservation.resource.name} |{' '}
          <span className="text-black/30">{reservation.resource.group}</span> |{' '}
          <span className="text-black/30">total time</span>{' '}
          <span className="lowercase">
            {formatTime(reservation.total_time)}
          </span>
        </div>
      </div>
      {reservation.distribution_base !== 'custom' && (
        <div
          ref={endDrag}
          className="font-black px-2 flex flex-col justify-center cursor-ew-resize"
        >
          <div className="flex flex-row gap-0.5">
            <div
              className={`h-4 border ${
                getDivisionClass(reservation.resource.group).border
              }`}
            />
            <div
              className={`h-4 border ${
                getDivisionClass(reservation.resource.group).border
              }`}
            />
          </div>
        </div>
      )}
    </div>
  );
};
