import { divisionColors } from '../constants/constants';

export const getAcronym = (text) => {
  const arr = text.split(' ');
  const arr2 = arr.map((item) => item.charAt(0).toUpperCase());
  return arr2.join('');
};

export const getDivisionClass = (division) =>
  divisionColors[division.toLowerCase()] || divisionColors.default;
