import React, { Children, useState } from 'react';
import './ProjectsTable.css';
import { DndProvider } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';
import { GroupRow } from './GroupRow/GroupRow';
import { InfoPopup } from '../InfoPopup/InfoPopup';
import { ReservationModal } from '../ReservationModal/ReservationModal';
import { CustomDragLayer } from './CustomDragLayer';

export const ProjectsTable = ({ groups }) => {
  const [popupDetails, setPopupDetails] = useState(null);
  const [editModalReservation, setEditModalReservation] = useState(null);
  const [createModalDetails, setCreateModalDetails] = useState(null);

  const assignUniqueIndexes = (reservations) => {
    let lastIndex = -1;
    const pairedIds = {};

    return reservations.map((e) => {
      if (!(e.id in pairedIds)) {
        lastIndex += 1;
        pairedIds[e.id] = lastIndex;
      }

      return { ...e, index: pairedIds[e.id] };
    });
  };

  const addReservationIndexes = (data) =>
    data.map((category) => {
      return {
        ...category,
        projects: category.projects.map((project) => {
          return {
            ...project,
            reservations: assignUniqueIndexes(project.reservations)
          };
        })
      };
    });

  const findReservationById = (reservationId) => {
    return groups
      .flatMap((group) => group.projects)
      .flatMap((project) => project.reservations)
      .find((reservation) => reservation.id === reservationId);
  };

  return (
    <DndProvider backend={HTML5Backend}>
      <div className="bg-gray-100">
        {groups && (
          <>
            {Children.toArray(
              addReservationIndexes(groups).map((e) => {
                return e.projects.length ? (
                  <GroupRow
                    group={e}
                    handleReservationClick={(details) =>
                      setPopupDetails((prev) =>
                        details.reservationId === prev?.reservationId
                          ? null
                          : details
                      )
                    }
                    handleCellClick={(data) => {
                      if (popupDetails) return;
                      setCreateModalDetails(data);
                    }}
                  />
                ) : null;
              })
            )}
            {popupDetails && (
              <InfoPopup
                reservation={findReservationById(popupDetails.reservationId)}
                position={popupDetails.position}
                onClose={() => setPopupDetails(null)}
                onEditClick={() => {
                  setEditModalReservation(
                    findReservationById(popupDetails.reservationId)
                  );
                  setPopupDetails(null);
                }}
              />
            )}
            {editModalReservation && (
              <ReservationModal
                reservation={editModalReservation}
                onClose={() => setEditModalReservation(null)}
              />
            )}
            {createModalDetails && (
              <ReservationModal
                onClose={() => setCreateModalDetails(null)}
                isCreateModal
                reservation={{
                  project_id: createModalDetails.projectId,
                  starts_at: createModalDetails.date,
                  ends_at: createModalDetails.date
                }}
              />
            )}
          </>
        )}
      </div>
      <CustomDragLayer />
    </DndProvider>
  );
};
