import { setSelectedPeriod, setWeekView } from 'actions/filterActions';
import { getProjectHierarchy } from 'actions/projectActions';
import {
  listFilteredReservation,
  updateReservation
} from 'actions/reservationActions';
import { getResourceGroups, getResourceTree } from 'actions/resourceActions';
import { changeModal } from 'actions/userActions';
import { Col, Row } from 'antd';
import Button from 'components/Button/Button';
import DateSetter from 'components/DateSetter/DateSetter';
import { FilterSection } from 'components/FilterSection/FilterSection';
import Select from 'components/Select/Select';
import Timetable from 'components/Timetable/Timetable';
import { DATE_FORMATS, WEEK_SELECTOR_OPTIONS } from 'constants/constants';
import { CSVLink } from 'react-csv';
import moment from 'moment';
import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getEndOfPeriod, getStartOfPeriod } from 'utils/time';
import './resource-page.scss';
// eslint-disable-next-line import/no-named-as-default-member
import ReservationFormModal from '../../components/ReservationFormModal/ReservationFormModal';
import { getPageTitle } from '../../utils/branding';

const ResourcePage = () => {
  const dispatch = useDispatch();
  const {
    filterReducer: { projects, timePeriod },
    reservation: {
      divisionsOfUsersOfReservations: divisionTreeResponse,
      divisionVisibilities
    }
  } = useSelector((store) => store);

  const divisionsOfUsersOfReservations = Object.values(
    divisionTreeResponse
  ).map((division) => ({
    ...division,
    isVisible: divisionVisibilities[division.group_name]
  }));

  const selectedTimePeriod = WEEK_SELECTOR_OPTIONS.find(
    ({ value }) => value === timePeriod.weekNumber
  );

  useEffect(() => {
    document.title = getPageTitle('Dashboard');

    dispatch(getResourceGroups());
    dispatch(getResourceTree());
    dispatch(getProjectHierarchy());
    dispatch(listFilteredReservation());
  }, []);

  const setPeriodToIncludeToday = () => {
    dispatch(
      setSelectedPeriod({
        newFirstDay: getStartOfPeriod(),
        newLastDay: getEndOfPeriod(timePeriod.weekNumber)
      })
    );
  };

  const exportCsvData = () => {
    const exportData = [];

    const pushData = (row) => {
      const foundIndex = exportData.findIndex(
        (item) =>
          item.user_id === row.user_id && item.project_id === row.project_id
      );
      if (foundIndex !== -1) {
        exportData[foundIndex].total_time += row.total_time;
        return;
      }
      exportData.push(row);
    };

    divisionsOfUsersOfReservations.forEach((groupItem) => {
      groupItem.items.forEach((userItem) => {
        userItem.reservations.forEach((reservationItem) => {
          reservationItem.days.forEach((reservationDay) => {
            pushData({
              user_id: userItem.id,
              user_name: userItem.name,
              total_time: reservationDay.total_time,
              project_id: reservationItem.project.id,
              project_name: reservationItem.project.name
            });
          });
        });
      });
    });

    return exportData;
  };

  return (
    <div className="resource-page-container">
      <ReservationFormModal />
      <Row>
        <Col xs={15}>
          <FilterSection />
        </Col>
        <Col xs={9} className="resource-page-selectors">
          <Row>
            <Col className="add-new-booking-button">
              <Button
                title="Add new booking"
                size="lg"
                className="bg-primary text-white"
                onClick={() => {
                  dispatch(
                    changeModal('reservationFormModal', { isVisible: true })
                  );
                }}
              />
            </Col>
          </Row>
          <Row>
            <Col>
              <Row>
                <Col span={24}>
                  <div className="resource-page-selector-label view">View</div>
                </Col>
                <Col span={24}>
                  <Select
                    dataSource={WEEK_SELECTOR_OPTIONS}
                    allowClear={false}
                    showSearch={false}
                    onChange={(weekNumber) => {
                      dispatch(setWeekView(weekNumber));
                      dispatch(
                        setSelectedPeriod({
                          newFirstDay: moment(timePeriod.firstDay).format(
                            DATE_FORMATS.DEFAULT
                          ),
                          newLastDay: moment(timePeriod.firstDay)
                            .add(weekNumber - 1, 'weeks')
                            .add(4, 'days')
                            .format(DATE_FORMATS.DEFAULT)
                        })
                      );
                    }}
                    width="126px"
                    isSmall
                    value={
                      selectedTimePeriod ? selectedTimePeriod.value : undefined
                    }
                  />
                </Col>
              </Row>
            </Col>
            <Col>
              <Row>
                <Col span={24}>
                  <div className="resource-page-selector-label period">
                    Period
                  </div>
                </Col>
                <Col>
                  <DateSetter
                    onChange={(period) => {
                      dispatch(setSelectedPeriod(period));
                    }}
                  />
                </Col>
              </Row>
            </Col>
            <Col className="resource-page-selector-today-button">
              <CSVLink
                className="export-link"
                filename="export.csv"
                data={exportCsvData()}
              >
                Export
              </CSVLink>

              <Button
                title="Today"
                className="text-white"
                backgroundColor="#3B3B3B"
                size="lg"
                onClick={setPeriodToIncludeToday}
              />
            </Col>
          </Row>
        </Col>
      </Row>
      <Row>
        {projects.length > 0 && (
          <Timetable
            divisionsOfUsersOfReservations={divisionsOfUsersOfReservations}
            datePeriod={[
              moment(timePeriod.firstDay),
              moment(timePeriod.lastDay)
            ]}
            handleDragEnd={(reservation) => {
              dispatch(
                updateReservation(reservation.id, {
                  id: reservation.id,
                  resourceId: reservation.resource_id,
                  dateRange: [reservation.starts_at, reservation.ends_at]
                })
              );
            }}
          />
        )}
      </Row>
    </div>
  );
};

export default ResourcePage;
