import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { useSelector, useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { restoreUserByToken } from 'actions/userActions';
import withRouter from 'hoc/withRouter';
import { LOCAL_STORAGE_ITEMS } from 'constants/constants';
import { ROUTER_PATHS } from 'routes/routerConstants';

const withAuth = (Component) => {
  const AuthenticatedComponent = (props) => {
    const isAuthenticated = useSelector((state) => state.user.isAuthenticated);
    const dispatch = useDispatch();
    const navigate = useNavigate();

    useEffect(() => {
      if (!isAuthenticated) {
        if (localStorage.getItem(LOCAL_STORAGE_ITEMS.ACCESS_TOKEN)) {
          const { location } = props;
          const next = location.pathname + location.search;
          dispatch(restoreUserByToken(next));
        } else {
          navigate(ROUTER_PATHS.LOGIN);
        }
      }
    }, []);

    return isAuthenticated ? <Component {...props} /> : null;
  };

  AuthenticatedComponent.propTypes = {
    location: PropTypes.shape().isRequired
  };

  return withRouter(AuthenticatedComponent);
};

export default withAuth;
