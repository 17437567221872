import moment from 'moment/moment';
import { useRecoilValue } from 'recoil';
import { DATE_FORMATS } from 'constants/constants';
import { projectsTableFiltersState } from 'store/atoms';
import { useMemo } from 'react';
import { Reservation } from './Reservation';
import { DragTypes } from '../ProjectsTable/ProjectDay/ProjectDay';

export const ReservationLengthChangeDragLayer = ({ item, itemType }) => {
  const filters = useRecoilValue(projectsTableFiltersState);
  const { reservation, width, elementRect, hoveredDay } = item;
  const isStartDrag = itemType === DragTypes.START_CHANGE;

  const dayCountBetweenDates = useMemo(() => {
    return isStartDrag
      ? moment(reservation.starts_at).businessDiff(moment(hoveredDay), 'days')
      : moment(hoveredDay).businessDiff(moment(reservation.ends_at), 'days');
  }, [isStartDrag, reservation.starts_at, reservation.ends_at, hoveredDay]);

  const singleCellWidth = useMemo(() => {
    const reservationsInDatePeriod = reservation.days.filter((day) => {
      const itemDay = moment(day.day, DATE_FORMATS.DEFAULT);
      return itemDay.isBetween(
        filters.from,
        moment(filters.from).add(filters.selectedWeekView * 6, 'd'),
        'day',
        '[]'
      );
    });
    return width / reservationsInDatePeriod.length;
  }, [reservation.days, filters.from, filters.selectedWeekView, width]);

  const previewWidth = useMemo(() => {
    const newWidth = width + dayCountBetweenDates * singleCellWidth;
    return newWidth > 0 ? newWidth : width;
  }, [dayCountBetweenDates, singleCellWidth, width]);

  const previewLeft = useMemo(() => {
    return isStartDrag ? elementRect.right - previewWidth : elementRect.left;
  }, [isStartDrag, elementRect.right, previewWidth, elementRect.left]);

  const previewStyle = useMemo(() => {
    return {
      position: 'absolute',
      width: previewWidth,
      left: previewLeft,
      top: elementRect.top
    };
  }, [previewWidth, previewLeft, elementRect.top]);

  return (
    <div style={previewStyle}>
      <Reservation reservation={reservation} isAbsenceDay={false} isPreview />
    </div>
  );
};
