import {
  LIST_RESERVATIONS_R,
  CREATE_RESERVATION_R,
  DELETE_RESERVATION_R,
  UPDATE_RESERVATION_R,
  SPLIT_RESERVATION_R,
  LIST_FILTERED_RESERVATIONS_R,
  RESERVATION_DIVISION_VISIBILITY_CHANGE
} from 'constants/actionTypes';

export const listReservation = (data) => ({
  type: LIST_RESERVATIONS_R,
  payload: {
    data
  }
});

export const createReservation = (data, resourceIds) => ({
  type: CREATE_RESERVATION_R,
  payload: {
    data,
    resourceIds
  }
});

export const updateReservation = (id, data) => ({
  type: UPDATE_RESERVATION_R,
  payload: {
    id,
    data
  }
});

export const splitReservation = (id, data) => ({
  type: SPLIT_RESERVATION_R,
  payload: {
    id,
    data
  }
});

export const deleteReservation = (id) => ({
  type: DELETE_RESERVATION_R,
  payload: {
    id
  }
});

export const listFilteredReservation = (data) => ({
  type: LIST_FILTERED_RESERVATIONS_R,
  payload: {
    data
  }
});

export const changeDivisionVisibility = (divisionName) => ({
  type: RESERVATION_DIVISION_VISIBILITY_CHANGE,
  payload: {
    divisionName
  }
});
