/* eslint-disable max-len */
import { CalendarOutlined } from '@ant-design/icons';
import { useTimetable } from 'hooks/useTimetable';
import { formatTime } from 'utils';
import { getLeft, getRight } from '../utils';
import './CalendarEvent.scss';

export const CalendarEvent = ({ dates, resources, reservation, index }) => {
  const { config } = useTimetable();

  return (
    <div
      className="calendar-event"
      style={{
        position: 'absolute',
        height: config.timeScheduleHeight,
        width:
          getRight(
            dates,
            reservation.ends_at.slice(0, 10),
            config.spaceBetweenTimeSchedules / 2
          ) -
          getLeft(
            dates,
            reservation.starts_at.slice(0, 10),
            config.spaceBetweenTimeSchedules / 2
          ),
        top:
          resources[reservation.resource_id].top +
          config.capacityHeight +
          config.spaceBetweenTimeSchedules / 3 +
          index *
            (config.spaceBetweenTimeSchedules + config.timeScheduleHeight),
        left: getLeft(
          dates,
          reservation.starts_at.slice(0, 10),
          config.spaceBetweenTimeSchedules / 2
        )
      }}
    >
      <div className="content">
        <CalendarOutlined /> {reservation.title} | Total time:{' '}
        {formatTime(reservation.total_time)}
      </div>
    </div>
  );
};
