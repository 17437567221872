import { CloseOutlined } from '@ant-design/icons';
import cc from 'classcat';
import Tag from 'components/Tag/Tag';
import PropTypes from 'prop-types';
import { useFilterSection } from './useFilterSection';

export const FilterSectionTags = ({ selectedProjects }) => {
  const {
    filters,
    handleTagChange,
    dispatchRemoveSelectedProjects,
    dispatchSelectedDivisions,
    dispatchSelectedManagers,
    dispatchSelectedResources
  } = useFilterSection();

  return (
    <>
      <div
        className={cc({
          'filter-tag': filters.freeCapacity,
          'filter-tag-invisible': !filters.freeCapacity
        })}
      >
        <Tag
          title="Free capacity"
          closable
          visible={filters.freeCapacity}
          onClose={() => handleTagChange('freeCapacity')}
          icon={<CloseOutlined />}
        />
      </div>
      <div
        className={cc({
          'filter-tag': filters.overBooked,
          'filter-tag-invisible': !filters.overBooked
        })}
      >
        <Tag
          title="Overbooked"
          closable
          visible={filters.overBooked}
          onClose={() => handleTagChange('overBooked')}
          icon={<CloseOutlined />}
        />
      </div>
      <div
        className={cc({
          'filter-tag': filters.holiday,
          'filter-tag-invisible': !filters.holiday
        })}
      >
        <Tag
          title="Holiday"
          closable
          visible={filters.holiday}
          onClose={() => handleTagChange('holiday')}
          icon={<CloseOutlined />}
        />
      </div>
      {filters.divisions.map((d) => {
        if (!d.checked) return null;
        return (
          <div className="filter-tag" key={d.key}>
            <Tag
              title={d.label}
              closable
              onClose={() => dispatchSelectedDivisions(d)}
              icon={<CloseOutlined />}
            />
          </div>
        );
      })}
      {selectedProjects.map((project) => (
        <div className="filter-tag" key={project.key}>
          <Tag
            title={project.label}
            closable
            onClose={() => dispatchRemoveSelectedProjects(project)}
            icon={<CloseOutlined />}
          />
        </div>
      ))}
      {filters.resources.map((resource) => {
        if (!resource.checked) return null;

        return (
          <div className="filter-tag" key={resource.key}>
            <Tag
              title={resource.label}
              closable
              onClose={() => dispatchSelectedResources(resource)}
              icon={<CloseOutlined />}
            />
          </div>
        );
      })}
      {filters.projectManagers.map((pm) => {
        if (!pm.checked) return null;
        return (
          <div className="filter-tag" key={pm.key}>
            <Tag
              title={pm.label}
              closable
              onClose={() => dispatchSelectedManagers(pm)}
              icon={<CloseOutlined />}
            />
          </div>
        );
      })}
    </>
  );
};

FilterSectionTags.propTypes = {
  selectedProjects: PropTypes.arrayOf(PropTypes.shape({})).isRequired
};
