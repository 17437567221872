import { InputNumber } from 'antd';

export const ReservationDuration = ({ length, onChange, disabled }) => {
  const hours = Math.floor(length / 60);
  const minutes = length % 60;

  const handleHoursChange = (value) => {
    onChange(value * 60 + minutes);
  };

  const handleMinutesChange = (value) => {
    onChange(hours * 60 + value);
  };

  return (
    <div className="flex flex-row gap-2 h-fit">
      <InputNumber
        min={0}
        onChange={handleHoursChange}
        disabled={disabled}
        value={hours}
        formatter={(value) => `${value}h`}
        parser={(value) => value.replace('h', '')}
      />
      <InputNumber
        min={0}
        onChange={handleMinutesChange}
        max={59}
        disabled={disabled}
        value={minutes}
        formatter={(value) => `${value}m`}
        parser={(value) => value.replace('m', '')}
      />
    </div>
  );
};
