// Assuming the path is something like src/hoc/withLayout.jsx

import AppLayout from 'layouts/AppLayout/AppLayout';
import LoginLayout from 'layouts/LoginLayout/LoginLayout';
import withAuth from 'hoc/withAuth';

const withLayout = (Component, isAuthed = false) => {
  const componentName = Component.displayName || Component.name || 'Component';

  if (isAuthed) {
    const AuthedComponent = withAuth(Component);

    const AuthedLayoutComponent = () => (
      <AppLayout>
        <AuthedComponent />
      </AppLayout>
    );

    // Setting display name for the AuthedLayoutComponent
    AuthedLayoutComponent.displayName = `WithLayout(${componentName})`;

    return AuthedLayoutComponent;
  }

  const LayoutComponent = () => (
    <LoginLayout>
      <Component />
    </LoginLayout>
  );

  // Setting display name for the LayoutComponent
  LayoutComponent.displayName = `WithLayout(${componentName})`;

  return LayoutComponent;
};

export default withLayout;
