import { atom } from 'recoil';
import moment from 'moment/moment';

export const projectsTableFiltersState = atom({
  key: 'projectsTableFiltersState',
  default: {
    emptyProjects: false,
    from: moment(undefined).startOf('isoWeek'),
    selectedWeekView: 1,
    resourceGroups: [],
    projectTypes: [],
    projects: []
  }
});
