import { Radio } from 'antd';
import React, { Children } from 'react';
import moment from 'moment/moment';
import { DATE_FORMATS } from 'constants/constants';
import { deepClone } from 'craco-less/lib/utils';
import { ReservationDuration } from '../ReservationDuration/ReservationDuration';
import { useProjectsTableApi } from '../../hooks/api/useProjectsTableApi';

export const DistributionEditFields = ({
  disabled,
  onChange,
  distribution
}) => {
  const { nationalHolidaysQuery } = useProjectsTableApi();
  const { data: nationalHolidays } = nationalHolidaysQuery;

  return (
    <Radio.Group
      disabled={disabled}
      value={distribution.distribution_base}
      className="uppercase flex flex-col gap-2"
      onChange={(e) => onChange({ distribution_base: e.target.value })}
    >
      <div className="flex flex-row justify-between">
        <Radio value="hours_per_day">Same daily hours</Radio>
        <ReservationDuration
          onChange={(value) => onChange({ time_per_day: value })}
          length={
            distribution.distribution_base === 'hours_per_day' &&
            distribution.time_per_day
          }
          disabled={
            distribution.distribution_base !== 'hours_per_day' || disabled
          }
        />
      </div>
      <div className="flex flex-row justify-between">
        <Radio value="total_time">Total hours over period</Radio>
        <ReservationDuration
          length={
            distribution.distribution_base === 'total_time' &&
            distribution.total_time
          }
          disabled={distribution.distribution_base !== 'total_time' || disabled}
          onChange={(value) => onChange({ total_time: value })}
        />
      </div>
      <div className="flex flex-col gap-2">
        <Radio value="custom">Variable daily hours</Radio>
        {distribution.distribution_base === 'custom' && (
          <div className="flex flex-col gap-4 divide-y">
            {Children.toArray(
              distribution.days.map((d) => (
                <div className="flex flex-row justify-between text-sm items-center pt-4">
                  <div>
                    {moment(d.day).format(DATE_FORMATS.DAY)}
                    <br />
                    {moment(d.day).format(DATE_FORMATS.MONTH_AND_DAY)}
                  </div>
                  {d.absence_day ||
                  !!nationalHolidays?.find(
                    (holiday) =>
                      holiday.day === moment(d.day).format(DATE_FORMATS.DEFAULT)
                  ) ? (
                    <div>absence</div>
                  ) : (
                    <ReservationDuration
                      length={
                        distribution.days.find((day) => day.day === d.day)
                          .total_time
                      }
                      disabled={
                        distribution.distribution_base !== 'custom' || disabled
                      }
                      onChange={(value) => {
                        const newDays = deepClone(distribution.days);
                        const dayIndex = newDays.findIndex(
                          (day) => day.day === d.day
                        );
                        newDays[dayIndex].total_time = value;
                        onChange({ days: newDays });
                      }}
                    />
                  )}
                </div>
              ))
            )}
          </div>
        )}
      </div>
    </Radio.Group>
  );
};
