import { useDrag, useDragLayer } from 'react-dnd';
import moment from 'moment/moment';
import { useRef, useEffect } from 'react';
import { getEmptyImage } from 'react-dnd-html5-backend';
import { Reservation } from './Reservation';
import { DATE_FORMATS } from '../../constants/constants';
import { DragTypes } from '../ProjectsTable/ProjectDay/ProjectDay';

export const DraggableReservation = ({
  reservation,
  onLengthDragStart,
  workday,
  onClick,
  filters
}) => {
  const elementRef = useRef(null);
  const thisPeriodsReservations = reservation.days.filter((item) => {
    const itemDay = moment(item.day, DATE_FORMATS.DEFAULT);
    return itemDay.isBetween(
      filters.from,
      moment(filters.from).add(filters.selectedWeekView * 6, 'd'),
      'day',
      '[]'
    );
  });

  const { isDragging, item, itemType } = useDragLayer((monitor) => ({
    item: monitor.getItem(),
    isDragging: monitor.isDragging(),
    itemType: monitor.getItemType()
  }));

  const isVisuallyHidden =
    isDragging &&
    itemType === DragTypes.MOVE &&
    item.reservation.id === reservation.id;

  const workdayIndex = thisPeriodsReservations.findIndex(
    (day) => day.day === moment(workday).format(DATE_FORMATS.DEFAULT)
  );

  const isAbsenceDay = !!reservation.days[workdayIndex].absence_day;

  const [, drag, preview] = useDrag(() => ({
    type: DragTypes.MOVE,
    item: () => ({
      reservation,
      from: moment(workday).format(DATE_FORMATS.DEFAULT),
      reservationCount: thisPeriodsReservations.length,
      width: elementRef.current?.offsetWidth,
      workday
    })
  }));

  const handleClick = (e) => {
    e.stopPropagation();
    const rect = elementRef.current.getBoundingClientRect();
    onClick({
      reservationId: reservation.id,
      position: {
        x: e.clientX + window.scrollX,
        y: rect.bottom + window.scrollY
      }
    });
  };

  useEffect(() => {
    preview(getEmptyImage(), { captureDraggingState: true });
  }, []);

  return (
    <div
      ref={(r) => {
        drag(r);
        elementRef.current = r;
      }}
      onClick={handleClick}
      className={`${isVisuallyHidden ? 'opacity-0' : ''} absolute`}
      style={{
        left: `calc(-${workdayIndex} * 100%)`,
        width: `calc(100% * ${thisPeriodsReservations.length})`,
        top: `calc(${50 * reservation.index}px + 3.5rem)`
      }}
    >
      <Reservation
        reservation={reservation}
        onLengthDragStart={onLengthDragStart}
        isAbsenceDay={isAbsenceDay}
      />
    </div>
  );
};
