import React from 'react';
import { useLocation, useParams } from 'react-router-dom';
import { history } from 'services/config';

const withRouter = (Component) => {
  const ComponentWithRouterProp = (props) => {
    const location = useLocation();
    const params = useParams();

    return (
      <Component
        {...props}
        location={location}
        params={params}
        history={history}
      />
    );
  };

  return ComponentWithRouterProp;
};

export default withRouter;
