import axios from 'axios';
import { snakeCase, camelCase, mapKeys } from 'lodash';
import { requestInterceptor, responseInterceptor } from 'services/interceptor';
import { LOCAL_STORAGE_ITEMS } from 'constants/constants';

export const convertRequestAttributes = (data) => {
  try {
    if (data && data.data && data.data.attributes) {
      const transformedData = data;
      transformedData.data.attributes = mapKeys(
        transformedData.data.attributes,
        (value, key) => snakeCase(key)
      );
      return JSON.stringify(transformedData);
    }
    return JSON.stringify(data);
  } catch (e) {
    return data;
  }
};

export const convertResponseAttributes = (response) => {
  try {
    const data = JSON.parse(response);
    if (data.data && data.data.attributes) {
      const transformedData = data;
      transformedData.data.attributes = mapKeys(
        transformedData.data.attributes,
        (value, key) => camelCase(key)
      );
      return transformedData;
    }
    return data;
  } catch (e) {
    return response;
  }
};

const api = axios.create({
  transformRequest: [convertRequestAttributes],
  transformResponse: [convertResponseAttributes],
  headers: {
    'Content-Type': 'application/json; charset=UTF-8',
    Accept: 'application/json',
    Authorization: `Bearer ${localStorage.getItem(
      LOCAL_STORAGE_ITEMS.ACCESS_TOKEN
    )}`
  }
});

const setHeader = (token) => {
  api.defaults.headers.Authorization = `Bearer ${token}`;
};

export const postLoginData = (url, formData) =>
  axios({
    method: 'POST',
    url,
    headers: {
      'Content-Type': 'application/json',
      Accept: 'application/json'
    },
    data: formData
  }).then((response) => {
    setHeader(response.data.access_token);
    return response.data;
  });

export const uploadFile = (url, buffer, fileName) =>
  axios({
    method: 'POST',
    url,
    data: buffer,
    headers: {
      'Content-Type': 'application/octet-stream',
      'Content-Disposition': `file; filename="${fileName}"`,
      Authorization: `Bearer ${localStorage.getItem(
        LOCAL_STORAGE_ITEMS.ACCESS_TOKEN
      )}`
    }
  });

export const getData = (url) => api.get(url).then((response) => response.data);

export const deleteData = (url) =>
  api.delete(url).then((response) => response.data);

export const postData = (url, data, config) =>
  api.post(url, data).then((response) => response.data, config);

export const patchData = (url, data, config) =>
  api.patch(url, data).then((response) => response.data, config);

responseInterceptor.setupInterceptors(api, setHeader);
requestInterceptor.setupInterceptors(api, setHeader);
