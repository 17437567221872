import moment from 'moment';
import { getDatesOfTimeFrame } from 'utils/time';

export const getVariableDailyDurations = (
  reservationDate = null,
  currentDurations = [],
  resourceIds = [],
  absences = []
) => {
  if (reservationDate === null) {
    return [];
  }

  const [startDate, endDate] = reservationDate;

  let durationDates = getDatesOfTimeFrame(startDate, endDate);
  const absenceDates = [];
  if (absences && resourceIds.length === 1) {
    absences.forEach((absence) => {
      absenceDates.push(
        ...getDatesOfTimeFrame(
          moment(absence.starts_at),
          moment(absence.ends_at)
        )
      );
    });
  }

  durationDates = durationDates.filter((item) => !absenceDates.includes(item));

  return durationDates.map((date) => {
    const currentDuration = currentDurations.find((duration) =>
      moment(date).isSame(duration.date, 'day')
    );
    return (
      currentDuration || {
        date,
        duration: {
          hours: 0,
          minutes: 0
        }
      }
    );
  });
};
