import { getStartOfPeriod, getEndOfPeriod } from 'utils';

const DEFAULT_WEEK_NUMBER = 1;

const initialState = {
  errorMessage: '',
  isLoading: false,
  hasLoadingError: false,
  freeCapacity: false,
  overBooked: false,
  holiday: false,
  divisions: [],
  projects: [],
  resources: [],
  projectManagers: [],
  timePeriod: {
    weekNumber: DEFAULT_WEEK_NUMBER,
    firstDay: getStartOfPeriod(),
    lastDay: getEndOfPeriod(DEFAULT_WEEK_NUMBER)
  }
};

export default initialState;
