import { Select } from 'antd';
import 'antd/dist/antd.min.css';
import cc from 'classcat';
import PropTypes from 'prop-types';
import React from 'react';
import './select.scss';

const { Option } = Select;

const SelectComponent = ({
  dataSource,
  placeholder,
  onChange,
  width,
  isSmall,
  defaultValue,
  value,
  allowClear = true,
  showSearch = true,
  disabled = false
}) => (
  <div className="select-wrapper">
    <Select
      disabled={disabled}
      showSearch={showSearch}
      allowClear={allowClear}
      placeholder={placeholder}
      onChange={(data, option) => onChange(data, option)}
      style={{ width }}
      value={value}
      defaultValue={defaultValue}
      className={cc({
        'select-dropdown with-arrow-border': isSmall,
        'select-dropdown': !isSmall
      })}
      dropdownClassName={cc({
        curly: isSmall
      })}
      filterOption={(input, option) => {
        return option.children.props.children
          .toLowerCase()
          ?.includes(input.toLowerCase());
      }}
    >
      {dataSource &&
        React.Children.toArray(
          dataSource.map((data) => (
            <Option key={data.value} value={data.value}>
              <span className={data.value === value && 'font-medium'}>
                {data.label}
              </span>
            </Option>
          ))
        )}
    </Select>
  </div>
);

SelectComponent.propTypes = {
  placeholder: PropTypes.string,
  dataSource: PropTypes.arrayOf(PropTypes.shape({})),
  width: PropTypes.string,
  isSmall: PropTypes.bool,
  defaultValue: PropTypes.string,
  value: PropTypes.oneOfType([PropTypes.shape(), PropTypes.number]),
  onChange: PropTypes.func.isRequired
};

SelectComponent.defaultProps = {
  placeholder: null,
  dataSource: null,
  width: '126px',
  isSmall: false,
  defaultValue: undefined,
  value: undefined
};

export default SelectComponent;
